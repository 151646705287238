// @keyframes slideUpContent {
//   from {transform: translateY(-200px);}
//   to {transform: translateY(-300px);}
// }
@keyframes slideUpContent {
  from {transform: translateY(0);}
  to {transform: translateY(-100px);}
}
@keyframes slideDown {
  from {transform: translateY(-100%)}
  to {transform: translateY(0)}
}
@keyframes leftToRight {
  from {left: 20px;}
  to {left: calc(100% - 50px);}
}
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}
@keyframes textFadeIn {
  from {transform: scale(.6); opacity: 0;}
  to {transform: scale(1); opacity: 1;}
}
