.case-overview {
  margin-top: 80px;
}

.case-shortview {
  .expert-type {
    font-size: rem(15);
    color: $white;
    font-weight: $fw-normal;
  }
  .body p {
    font-size: #{map-get($header-size, 'h2')}px;
    line-height: $header-line-height;
    font-weight: $fw-bold;
    color: $white;
    padding: rem(30) 0 rem(20);
  }
  .author {
    color: $white;
    font-size: rem(18);
    font-weight: $fw-normal;

    &:before {
      content: "- "
    }
  }

  .caption {
    padding: rem(48) rem(40);
  }

  .flexslider {
    padding: 0 20px;

    .slides {
      background: $background-dark;
    }
  }

  .flex-control-nav {
    display: block;
    width: auto;
    bottom: auto;
    top: rem(20);
    left: rem(40);

    li a {
      background: $white;
      width: 20px;
      height: 20px;
      font-size: 0px;
      &.flex-active {
        background: $primary-color;
      }
    }
  }

  @include breakpoint(medium) {
    .expert-type {
      font-size: rem(14);
    }
    .body p {
      font-size: #{map-get($small-header-size, 'h2')}px;
      padding: rem(10) 0 rem(10);
    }
    .author {
      font-size: rem(16);
    }
    .caption {
      padding: rem(20) rem(26);
    }
    .flexslider {
      padding: 0;
    }
    .flex-control-nav {
      left: rem(20);
    }
  }

  //@include breakpoint(medium) {
  //  .case-item {
  //    padding: 0;
  //    a {
  //      span {
  //        text-align: left;
  //      }
  //      .body p {
  //        font-size: 20px;
  //        line-height: 24px;
  //      }
  //      .author {
  //        font-size: 16px;
  //        line-height: 24px;
  //      }
  //    }
  //  }
  //}
}

.flexslider .slides .case-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  img {
    flex: 1 0 (100 / 3 * 2%);
    width: 100 / 3 * 2%;
  }

  @include breakpoint(medium) {
    flex-direction: column;

    img {
      flex: 1 1 100%;
      width: 100%;
    }
  }
}

/* Blog overview met afbeelding*/
.case-detail {
  .expert-type {
    color: $primary-color;
    font-size: 13px;
    line-height: 16px;
    font-family: $alternative-font;
    padding-bottom: 10px;
  }
}
