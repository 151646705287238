$header-size: (
  h1: 32,
  h2: 32,
  h3: 24,
  h4: 22,
  h5: 22,
  h6: 18,
) !default;
$small-header-size: (
  h1: 26,
  h2: 26,
  h3: 20,
  h4: 19,
  h5: 18,
  h6: 18,
) !default;

$base-font-size: 16px !default;
$base-line-height: 1.5 !default;
$header-line-height: 1.125 !default;
$text-intro-weight: $fw-normal !default;
$text-intro-size: 18px !default;
$text-intro-line-height: 27px !default;

$ul-icon-size: 1.3em !default;

$icon-font: 'rijko' !default;
$default-font: 'Fira Sans', 'Open Sans', Arial, Helvetica, sans-serif !default;
$alternative-font: $default-font !default;

$header-font-family: $alternative-font !default;
$header-font-weight: $fw-light !default;
$header-font-style: normal !default;

$body-font-family: $default-font !default;
$body-font-weight: $fw-light !default;
$body-font-style: normal !default;

$link-text-decoration: none !default;
$link-text-weight: normal !default;

// Views
$overview-title-font-size: 22px !default;
$overview-title-font-weight: $fw-light !default;
$overview-title-line-height: 26px !default;
$overview-title-color: $primary-color !default;
$overview-date-font-size: 12px !default;
$overview-date-line-height: 25px !default;
$overview-date-color: $primary-color !default;

//responsive
$responsive-overview-title-font-size: $overview-title-font-size * 0.8 !default;
$responsive-overview-title-line-height: 26px * 0.8 !default;
