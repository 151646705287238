//================================================
//=== HEADER =====================================
//================================================
.content-top {
  @include transition(margin);
  margin-top: 0;
}

.header {
  background: $white;
  z-index: 101;
  box-shadow: 0 1px 0 0 $border-color;
  margin-bottom: rem(20);

  @include breakpoint(xlarge) {
    height: 153px;
    box-shadow: none;
    margin-bottom: rem(40);
  }

  @include breakpoint(medium) {
    height: 85px;
  }

  .admin-menu & {
    top: 59px;
  }

  .row {
    width: 100%;
    margin: 0;
  }

  .columns {
    padding: 0;

    @include breakpoint(xlarge) {
      width: 100%;
    }
  }

  .logo {
    margin: rem(10) rem(80) rem(10) 0;

    @include breakpoint(xlarge) {
      margin-right: rem(25);
    }

    a,
    img {
      display: block;
    }

    img {
      margin: 0 0 0 auto;

      @include breakpoint(medium) {
        max-width: 295px;
      }
    }
  }

  .block.phone {
    p {
      color: $primary-color;
      font-size: rem(18);
      margin-top: 87px;
      @include breakpoint(xlarge) {
        position: absolute;
        margin-top: 0;
        top: 153px;
        line-height: 40px;
        left: 80px;
        font-size: rem(16);
      }

      @include breakpoint(large) {
        left: 95px;
      }

      @include breakpoint(medium) {
        top: 85px;
        line-height: 37px;
        left: 95px;
        font-size: rem(14);
      }
    }
  }
}

// Zoekveld
.block.zoekveld {
  #search-api-page-search-form-search {
    position: relative;
  }

  input[type='text'] {
    border: 1px solid rgba(102,102,102,0.1);
    box-shadow: none;
    border-radius: 15px;
    padding: 0 10px;
    color: $search-color;
    font-family: $alternative-font;
    font-size: 13px;
    line-height: 23px;
    margin: 0;
    &::placeholder {
      font-weight: $fw-bold;
    }
  }

  #edit-keys-1,
  input.form-autocomplete {
    background: url('../images/ajax-loader.gif') right 50px no-repeat;
  }

  #edit-keys-1.throbbing,
  input.throbbing {
    background-position: 84% 10px;
  }

  .form-submit {
    font-family: $icon-font;
    border: 0;
    position: absolute;
    top: -1px;
    right: 1px;
    color: $primary-color;
    font-size: 14px;
    background: transparent;
    padding: 0 10px;
    margin: 0;

    &:after {
      @include extend-icon('search');
    }

    &:hover {
      cursor: pointer;
    }
  }

  .form-text {
    float: right;
    min-width: 200px;
  }
}

#autocomplete {
  border: 1px solid $text-color;
  border-top: 0;
  width: 300px!important;
}

#autocomplete ul li {
  padding: 5px 10px;
  border-bottom: 1px solid $text-color;
}

#autocomplete ul li:hover {
  background: $text-color;
  cursor: pointer;
}
@include breakpoint(medium) {
  .search-form {
    display: none;
  }
}

.pane-node-field-afbeelding + .pane-node-title-field {
  transform: translateY(-100%);
  position: absolute;
  width: 100%;
  background-color: $slider-caption-bg-color;
  padding: rem(50) rem(40) rem(45);

  h1 {
    margin: 0;
    color: $caption-text-color;
    text-shadow: $caption-text-shadow;
    font-weight: $fw-light;
  }

  @include breakpoint(medium) {
    position: static;
    transform: translateY(0);
    padding: rem(20) rem(25) rem(26);
    background: $primary-color;
  }
}
