//================================================
//=== PAGINA MET SECTIES =========================
//================================================
.sectie-item {
  float: left;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

  .row {
    width: $row-width;
    max-width: 100%;
    margin: 0 auto;

    //Override collapse padding
    .column,
    .columns {
      padding: 0 ($column-gutter/2) !important;
    }
  }

  img {
    height: auto!important;
  }

  &.maps-maps {
    div {
      line-height: $base-font-size;
    }
  }

  //Achtergrond afbeelding
  &.achtergrond-afbeelding {
    //background-size:cover!important;
    &:first-child {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }

    h1 {
      margin-top: 40px;
    }

  }

  //Marges
  &.geenmarge {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &.margetop {
    padding-bottom: 0 !important;
  }

  &.margebottom {
    padding-top: 0 !important;
  }

  //Borders
  &.border,
  &.border-bottom,
  &.border-top {
    border-color: #efefef;
    border-style: solid;
    border-width: 0;
  }

  &.border {
    border-top-width: 1px!important;
    border-bottom-width: 1px!important;
  }

  &.border-top {
    border-top-width: 1px!important;
  }

  &.border-bottom {
    border-bottom-width: 1px!important;
  }
}

// CUSTOM KLEUREN

.color-2ba6cb {
  a,
  blockquote,
  blockquote p:before,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  p,
  ul {
    color: $white;
  }

  blockquote {}

  .btn {
    background: $white;
    color: #000;
  }

  .btn.rand {
    background: transparent;
    border: 2px solid $white;
    color: $white;
  }
}

.color-f2f2f2 {
  background: #f2f2f2;
}

.color-e3e3e3 {
  background: #e3e3e3;
}

.color-2ba6cb {
  background: #2ba6cb;
}

// Pagina specifiek
.page-node-82 {
  .sectie-item.formulier {
    .block {
      float: left;
      margin: 20px 0;
      width: 100%;
      padding: 20px 30px;
      background: #2ba6cb;

      * {
        color: $white!important;
      }

      .btn {
        background: $white!important;
        color: #2ba6cb!important;
      }
    }
  }
}

//JK: ONDERSTAANDE MOET NOG WORDEN NAGEKEKEN

//================================================
//=== FOTOALBUM ==================================
//================================================
.fotoalbum-sectie {
  margin-top: 25px;
  @include breakpoint(medium) {
    width: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0 10px;
      float: left;
      margin-bottom: 15px;
      width: 25%;
      list-style-type: none;
      @include breakpoint(large) {
        width: 50%;
      }
      @include breakpoint(medium) {
        &:nth-child(odd) {
          padding-left: 0px !important;
        }

        &:nth-child(even) {
          padding-right: 0px !important;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .foto-titel {
    float: left;
    width: 100%;
    text-align: center;
  }

}

.sectie-item .fotoalbum .content {
  width: 50%!important;
}

.sectie-item .fotoalbum .content.col.nr1 {
  float: left;
}

.sectie-item .view-fotoalbum .col {
  padding: 0;
}

.sectie-item .fotoalbum .content {
  @include breakpoint(medium) {
    width: 100%!important;
  }
}
