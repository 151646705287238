// Algemeen
.overview,
.shortview,
.detail,
.widget {
  .item {
    @include transition(background);
    background: transparent;
    padding: $column-gutter / 2;

    @include breakpoint(medium, 'up') {
      &.three.columns:nth-child(2n+1) {
        clear: left;
      }
    }

    @include breakpoint(large, 'up') {
      &.three.columns:nth-child(2n+1) {
        clear: none;
      }

      &.three.columns:nth-child(4n+1) {
        clear: left;
      }
    }

    @include breakpoint(medium) {
      padding: 0 0 ($column-gutter);

      .title,
      .body,
      .type,
      .date,
      .expert-type,
      .author {
        padding: 0 ($column-gutter / 2);
      }

      .type {
        padding-right: 0;
      }

      .date {
        padding-left: 0;
      }
    }

    .thumb,
    .title,
    .body p,
    .body span,
    .type,
    .date,
    .expert-type,
    .author {
      @include transition(color);
    }

    .thumb,
    .title,
    .body {
      display: block;
    }

    .type,
    .date {
      display: inline-block;
    }

    .thumb {
      margin-bottom: 15px;
    }

    .thumb img {
      display: block;
      width: 100%;
    }

    .type {
      font-size: $overview-date-font-size;
      line-height: $overview-date-line-height;
      color: $overview-date-color;
    }

    .title {
      font-family: $alternative-font;
      font-size: $overview-title-font-size;
      font-weight: $overview-title-font-weight;
      line-height: $overview-title-line-height;
      color: $overview-title-color;
      padding-bottom: 10px;
    }

    .date {
      font-size: $overview-date-font-size;
      line-height: $overview-date-line-height;
      color: $overview-date-color;
      padding-bottom: 15px;
      font-style: normal;

      em {
        font-style: normal;
      }

      &:before {
        content: " - "
      }
    }

    a {
      text-decoration: none;
    }
  }

  @include breakpoint(large) {
    .item {
      .title {
        font-size: $responsive-overview-title-font-size;
        line-height: $responsive-overview-title-line-height;
      }
    }
  }
}

// Overview
.overview {
  .item {
    .thumb,
    .title,
    .date {
    }
    .thumb {

    }
    .title {

    }
    .date {

    }
  }
}

// Shortview
.shortview {
  .item {
    display: inline-block;
    float: left;
    .thumb,
    .title,
    .date {
    }
    .thumb {

    }
    .title {

    }
    .date {

    }
  }
}

//Widget
.widget {
  .item {
    .title,
    .date {
    }
    .title {

    }
    .date {

    }
  }
}

.blog-detail-content > .row {
  margin-top: rem(90);
  margin-bottom: rem(90);

  @include breakpoint(medium) {
    margin-top: rem(30);
    margin-bottom: rem(30);
  }
}

.detail {
  // background: $white;
  .item {
    padding: 0 rem(80);
    .thumb,
    .title,
    .date,
    .body,
    .author {
      display: block;
    }
    .title {
      font-size: 40px;
      line-height: 45px;
    }
    .date {
      &:before {
        content: '';
      }
    }
    .author {
      padding-top: 40px;
      color: $primary-color;
      font-family: $alternative-font;
      font-size: 16px;
      line-height: 25px;
      font-weight: $fw-bold;
    }
  }
  @include breakpoint(large) {
    .item {
      padding: 0;
    }
  }
  @include breakpoint(medium) {
    .item {
      .title {
        font-size: 30px;
        line-height: 34px;
      }
    }
  }
}
