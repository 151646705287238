$vr-green-primary: #076c2b;
$vr-green-secondary: #076c2b;
$vr-source-primary: #43b649;
$vr-source-secondary: #43b649;
$vr-ingredients-primary: #f6891f;
$vr-ingredients-secondary: #f6891f;
$vr-freshservice-primary: #96004f;
$vr-freshservice-secondary: #96004f;
$vr-carrotconcepts-primary: #f15f22;
$vr-carrotconcepts-secondary: #f15f22;

$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-normal: 400; // aka regular
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;
