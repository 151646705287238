/* Requires
   globals.css */

/* Standard Forms ---------------------- */

form {
  margin: 0 0 ($form-spacing);
}

.row form .row {
  margin: 0 ($form-spacing / -2);

  .column,
  .columns {
    padding: 0 ($form-spacing / 2);
  }

  &.collapse {
    margin: 0;

    .column,
    .columns {
      padding: 0;
    }
  }
}

label {
  font-size: ms(0);
  color: $label-font-color;
  cursor: pointer;
  display: block;
  font-weight: $label-font-weight;
  margin-bottom: $label-bottom-margin;

  &.right {
    float: none;
    text-align: right;
  }

  &.inline {
    line-height: (ms(0) + ($form-spacing * 1.5));
    margin: 0 0 $form-spacing;
  }
}

.postfix,
.prefix {
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  height: (ms(0) + ($form-spacing * 1.5));
  line-height: (ms(0) + ($form-spacing * 1.5)) - 1;
}

a.button.postfix,
a.button.prefix {
  padding-#{$default-float}: 0;
  padding-#{$default-opposite}: 0;
  text-align: center;
}

span.postfix,
span.prefix {
  background: darken($white, 5%);
  border: 1px solid darken($white, 20%);
}

.prefix {
  #{$default-float}: 2px;
  border-top-#{$default-float}-radius: 2px;
  border-bottom-#{$default-float}-radius: 2px;
  overflow: hidden;
}

.postfix {
  #{$default-opposite}: 2px;
  border-top-#{$default-opposite}-radius: 2px;
  border-bottom-#{$default-opposite}-radius: 2px;
}

input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
  background-color: $input-bg-color;
  font-family: inherit;
  border: $input-border-width $input-border-style $input-border-color;
  border-radius: $input-border-radius;
  color: $input-font-color;
  display: block;
  font-size: $input-font-size;
  margin: 0 0 $form-spacing;
  padding: 8px 15px;
  height: (ms(0) + ($form-spacing * 1.5));
  width: 100%;
  transition: all 0.15s linear;

  &.oversize {
    font-size: ms(1);
    padding: (($form-spacing - 4) / 2) ($form-spacing / 2);
  }

  &:focus {
    background: $input-focus-bg-color;
    border-color: $input-focus-border-color;
    box-shadow: 0 0 6px 0 #94B8D1;
  }

  &[disabled] {
    background-color: #ddd;
  }
}

textarea {
  height: auto;
}

select {
  width: 100%;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $background-dark;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $background-dark;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $background-dark;
}
:-moz-placeholder { /* Firefox 18- */
  color: $background-dark;
}

.description {
  // display: none;
  display: inline-block;
  @include transition(opacity, visibility);
  transition-duration: .5s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top:-25px;
  left: 0px;
  background: $background-dark;
  border-radius: 15px;
  color: $white;
  padding: 5px 20px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  font-family: $alternative-font;
  font-weight: $fw-bold;
  &.show {
    // display: inline-block;
    opacity: 1;
    visibility: visible;
  }
  &:after {
    // @include extend-icon('tooltip-down');
    content: ' ';
    position: absolute;
    left: 7px;
    bottom: -9px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: $background-dark transparent transparent transparent;
  }
}
.form-item input:focus{
  + .description {
    opacity: 1;
    visibility: visible;
  }
}


// Fieldsets
fieldset {
  border: solid 1px #ddd;
  border-radius: $fieldset-border-radius;
  padding: 12px;
  margin: 18px 0;

  legend {
    font-weight: bold;
    background: $white;
    padding: 0 3px;
    margin: 0;
    margin-#{$default-float}: -3px;
  }
}

/* Errors */
.error input,
.error input:focus,
.error textarea,
.error textarea:focus,
input.error,
input.error:focus,
textarea.error,
textarea.error:focus {
  border-color: $alert-color;
  background-color: rgba($alert-color, 0.1);
}

.error input:focus,
.error textarea:focus,
input.error:focus,
textarea.error:focus {
  outline-color: lighten($alert-color, 30%);
}

.error label,
label.error {
  color: $alert-color;
}

.error small,
small.error {
  display: block;
  padding: 6px 4px;
  margin-top: -($form-spacing) - 1;
  margin-bottom: $form-spacing;
  background: $alert-color;
  color: $white;
  font-size: ms(0) - 2;
  font-weight: bold;
  border-bottom-#{$default-float}-radius: $input-border-radius;
  border-bottom-#{$default-opposite}-radius: $input-border-radius;
}

/* Correct FF custom dropdown height */
@-moz-document url-prefix() {
  form.custom div.custom.dropdown a.selector {
    height: 28px;
  }
}

.lt-ie9 form.custom div.custom.dropdown a.selector {
  height: 28px;
}

#edit-submitted-upload-cv-ajax-wrapper {
  @extend %clearfix;
  position: relative;
  display: inline-block;
  width: 100%;
}

.webform-component-file {
  @extend %clearfix;
  position: relative;

  .form-submit {
    display: none;
  }

  html.js & input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    cursor: pointer;
  }

  .upload-label {
    background-color: $input-bg-color;
    border: $input-border-width $input-border-style $input-border-color;
    border-radius: $input-border-radius;
    padding: 8px 15px;
    color: $background-dark;
    font-size: $input-font-size;
    font-weight: $fw-normal;
    line-height: normal;
    width: calc(100% - #{rem(200)});
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    z-index: 1;
    float: left;

    @include breakpoint(medium){
      width: calc(100% - #{rem(135)});
    }

    &.dirty {
      color: $text-color;
    }
  }

  .btn {
    float: right;
    margin: 0 !important;
    line-height: normal;
    font-size: rem(16);
    padding: 8px 15px;
    border: 1px solid $blue;
    min-width: rem(190) !important;

    @include breakpoint(medium){
      min-width: rem(125) !important;
    }
  }
}

.form-item-url {
  display: none;
}
