/*================================================
=== Masonry ======================================
================================================*/
.masonry-item {
  // width: 33.3%;
  float: left;

  item-wrapper {
    padding: 10px;

    item-inner {
      padding: 0;
      position: relative;
      background: white;
      overflow: hidden;
      -webkit-transition: all 200ms ease-in;
      -moz-transition: all 200ms ease-in;
      -o-transition: all 200ms ease-in;
      transition: all 200ms ease-in;
    }
  }

  .item-link {
    display: block;
    width: 100%;
    height: 100%;

    &:hover,
    .item-inner {
      box-shadow: 0 0 1px #00a1e5;
      -webkit-box-shadow: 0 0 1px #d9e7ec;
      -moz-box-shadow: 0 0 1px #00a1e5;
    }
  }

  .datum {
    display: block;
    color: #999;
  }

  .titel {
    font-size: 32px;
    line-height: 36px;
    font-weight: 100;
    padding: 10px 0;
    display: block;
    padding: 20px 40px 10px;
  }

  .content {
    padding: 0 40px 20px;
    display: block;
  }

  .thumb {
    display: block;

    img {}

    .small img {
      float: left;
    }
  }

  .tags {
    li {
      list-style: none;
    }

    ul li {
      float: left;
      padding: 0;
      margin-right: 3px;
      border: 1px solid #80b8f1;
      color: #80b8f1;
      padding: 3px 6px;
      border-radius: 5px;
    }
  }
}

.masonry-item.extended {
  width: 66.6%;

  .item-inner {
    background: $white;
  }

  .datum {
    color: $white;
  }

  .tags li {
    color: $white;
    border: 1px solid $white;
  }
}

.masonry-item.quotes {
  .item-inner {
    background: #2ccefd;
  }

  .content {
    font-size: 22px;
    line-height: 25px;
    font-weight: 100;
    color: $white;
    padding: 20px 40px;
  }

  .quote-klant {
    float: right;
    clear: both;
    font-size: 18px;
    font-weight: 100;
    color: $white;
    padding: 0 40px;
  }

  .quote-functie {
    float: right;
    clear: both;
    font-weight: 100;
    font-size: 13px;
    padding-top: 3px;
    color: $white;
    padding: 5px 40px 20px;
  }
}

@include breakpoint(large) {
  .masonry-item {
    width: 50%;
  }

  .masonry-item.extended {
    width: 100%;
  }
}
@include breakpoint(medium) {
  .masonry-item {
    width: 100%;
  }

  .masonry-item.extended {
    width: 100%;
  }
}
