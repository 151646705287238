$form-spacing: 16px !default;

$label-font-weight: $fw-medium !default;
$label-font-color: lighten($black, 30%) !default;
$label-bottom-margin: 3px !default;

$input-font-color: #444444 !default;
$input-font-size: 16px !default;
$input-bg-color: #fff !default;
$input-focus-bg-color: $input-bg-color !default;
$input-border-color: #e7e7e7 !default;
$input-focus-border-color: #579bd8 !default;
$input-border-style: solid !default;
$input-border-width: 1px !default;
$input-border-radius: 20px !default;

$fieldset-border-radius: 3px !default;
