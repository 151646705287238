//================================================
//=== FRAMEWORK ==================================
//================================================
*,
*:after,
*:before {
  box-sizing: border-box;
}

// .panel-flexible .panels-flexible-row,
.row {
  width: $row-width;
  max-width: 100%;
  margin: 0 auto;

  // .panel-flexible .panels-flexible-row,
  .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 ($column-gutter / -2);
  }

  &.collapse {
    width: 100%;

    .column,
    .columns {
      padding: 0;
    }
  }

  .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 ($column-gutter / -2);

    &.collapse {
      margin: 0;
    }
  }
}

.column,
.columns,
.panel-flexible .panels-flexible-region.columns {
  float: $default-float;
  padding: 0 ($column-gutter / 2);
  position: relative;

  &.centered {
    float: none;
    margin: 0 auto;
  }
}

[class*='column'] + [class*='column'].end {
  float: $default-float;
}

// Creating column classes
@for $i from 1 through $total-columns {
  .#{convert-number-to-word($i)} {
    width: grid-calc($i, $total-columns);
  }
}

// Creating .row-# classes
@for $i from 1 through $total-columns {
  .row {
    .#{convert-number-to-word($i)} {
      @extend .#{convert-number-to-word($i)};
    }
  }
}

// Creating .row .offset-by-# classes
@for $i from 1 through $total-columns - 2 {
  .row {
    .offset-by-#{convert-number-to-word($i)} {
      margin-#{$default-float}: grid-calc($i, $total-columns);
    }
  }
}

.panels-flexible-row {
  padding-bottom: 0 !important;
}

.row {
  // @include clearfix();
  @extend %clearfix;
}

.column.centered {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

//================================================
//=== RESPONSIVE =================================
//================================================
@include breakpoint(medium) {
  .left,
  .right {
    float: none;
  }

  body {
    width: 100%;
    min-width: 0;
    margin-#{$default-float}: 0;
    margin-#{$default-opposite}: 0;
    padding-#{$default-float}: 0;
    padding-#{$default-opposite}: 0;
  }

  .row {
    width: auto;
    min-width: 0;
    margin-#{$default-float}: 0;
    margin-#{$default-opposite}: 0;
  }

  .column,
  .columns {
    width: 100% !important;
    // float: none;
  }

  .column:last-child,
  .columns:last-child {
    // float: none;
  }

  [class*='column'] + [class*='column']:last-child {
    // float: none;
  }

  .column:after,
  .column:before,
  .columns:after,
  .columns:before {
    content: '';
    display: table;
  }

  .column:after,
  .columns:after {
    clear: both;
  }

  // Placeholder classes for mobile grid overrides
  %no-default-margin {
    margin-#{$default-float}: 0 !important;
  }

  %default-auto {
    #{$default-float}: auto;
  }

  %opposite-auto {
    #{$default-opposite}: auto;
  }
  @for $i from 1 through $total-columns - 2 {
    .offset-by-#{convert-number-to-word($i)} {
      @extend %no-default-margin;
    }
  }
  @for $i from 2 through $total-columns - 2 {
    .push-#{convert-number-to-word($i)} {
      @extend %default-auto;
    }

    .pull-#{convert-number-to-word($i)} {
      @extend %opposite-auto;
    }
  }

  /* Mobile 4-column Grid */
  @for $i from 1 through $mobile-total-columns {
    .row {
      .mobile-#{convert-number-to-word($i)} {
        width: grid-calc($i, $mobile-total-columns) !important;
        float: $default-float;
        padding: 0 ($column-gutter/2);

        &:last-child {
          float: $default-opposite;
        }

        &.end {
          float: $default-float;
        }
      }

      &.collapse {
        .mobile-#{convert-number-to-word($i)} {
          padding: 0;
        }
      }
    }
  }
  @for $i from 1 through $mobile-total-columns - 1 {
    .push-#{convert-number-to-word($i)}-mobile {
      #{$default-float}: grid-calc($i, $mobile-total-columns);
    }

    .pull-#{convert-number-to-word($i)}-mobile {
      #{$default-opposite}: grid-calc($i, $mobile-total-columns);
    }
  }
}

.npl {
  padding-left: 0px !important;
}

.npr {
  padding-right: 0px !important;
}
@include breakpoint(medium) {
  .col {
    width: 100%!important;
  }
}

.vp {
  display: none !important;
}

.vt {
  display: none !important;
}

.hd {
  display: none !important;
}

.vd {
  display: inherit !important;
}

.np,
.npd {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.npl,
.npld {
  padding-left: 0px !important;
}

.npr,
.nprd {
  padding-right: 0px !important;
}

.npl {
  padding-left: 0px !important;
}

.npr {
  padding-right: 0px !important;
}

@include breakpoint(large) {
  .hd {
    display: inherit !important;
  }

  .vd {
    display: none !important;
  }

  .vt {
    display: inherit !important;
  }

  .ht {
    display: none !important;
  }

  .npld,
  .nprd {
    padding: 0 ($column-gutter/2) !important;
  }
}

@include breakpoint(medium) {
  .hd {
    display: inherit !important;
  }

  .vd {
    display: none !important;
  }

  .vp {
    display: inherit !important;
  }

  .hp {
    display: none !important;
  }

  .npd,
  .npld,
  .nprd {
    padding: 0 ($column-gutter/2) !important;
  }

  .nplm,
  .npm {
    padding-left: 0px !important;
  }

  .npm,
  .nprm {
    padding-right: 0px !important;
  }
}

.sidebar {
  @include breakpoint(medium) {
    padding-left: 0;
  }
}
