//================================================
//=== SEARCH RESULTS =============================
//================================================
.search-row {
  width: 100%;
  .columns {
    // padding: 0 100px;
    padding: 0;
    // @media screen and (max-width: 1200px) {
    //   padding: 0 ($column-gutter / 2);
    // }
  }
}

.no-padding {
  padding: 0;
  @media screen and (max-width: 1200px) {
    padding: 0 ($column-gutter / 2);
  }
}

.section-zoeken {
  .no-padding {
    padding-top: 100px;
  }
}

#search-api-page-search-form {
  label {
    display: none;
  }
  .form-item,
  .form-submit {
    display: inline-block;
  }
  .form-item {
    width: 80%;
  }
  .form-submit {
    width: calc(20% - 30px);
    margin-left: 30px;
    padding: 8px 25px;
    float: right;
    transform: translateY(14px);
  }
  @media screen and (max-width: 900px) {
    .form-item {
      width: 70%;
    }
    .form-submit {
      width: calc(30% - 30px);
      padding: 8px 15px;
    }
  }
  @media screen and (max-width: 500px) {
    .form-item {
      width: 60%;
    }
    .form-submit {
      width: calc(40% - 15px);
      margin-left: 15px;
    }
  }
}

.search-results {
  margin: 0 !important;
  list-style: none !important;
}

.search-info {
  display: none;
}

.search-result-title {
  .columns {
    padding: 0 !important;
    @media screen and (max-width: 1200px) {
      padding: 0 ($column-gutter / 2) !important;
    }
  }
}

.search-result {
  display: block;
  padding: 40px 0 !important;
  background: $background-light;
  transition: background .15s ease, color .15s ease;

  &:hover {
    background: $link-color-hover;

    &,
    * {
      color: $white !important;
    }
  }

  .search-inner {
    width: 960px;
    margin: 0 auto;
    max-width: 100%;
    @media screen and (max-width: 1200px) {
      padding: 0 ($column-gutter / 2) !important;
    }
    @media screen and (max-width: 1100px) {
      padding: 0 !important;
    }
    // max-width: calc(100% - 350px);
    // @include breakpoint(large) {
    //   max-width: calc(100% - 60px);
    // }
    // @include breakpoint(medium) {
    //   max-width: calc(100%);
    // }

    .title {
      a {
        color: $primary-color;
        font-size: 22px;
        line-height: 28px;
        font-weight: $fw-bold;
        font-family: $alternative-font;
        text-decoration: none;
      }
    }
    .search-snippet-info {
      .search-snippet {
        color: $input-font-color;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 40px ($column-gutter / 2) !important;
  }
}

@include breakpoint(large) {
  .section-zoeken {
    .no-padding {
      padding: 50px ($column-gutter / 2) 0;
    }
  }
  .search-result {
    padding: 40px ($column-gutter / 2) !important;
  }
}

.search-results .search-snippet-info {
  padding-left: 0;
}

.search-api-page-results {
  float: left;
  width: 100%;
}

.search-wrapper {
  hr {
    border: 2px solid #f0f0f0;
  }
}

.search-wrapper,
.search-no-result {
  width: 960px;
  margin: 0 auto;
  max-width: 100%;
  // @include breakpoint(large) {
  //   max-width: calc(100% - 60px);
  // }
  // @include breakpoint(medium) {
  //   max-width: calc(100%);
  // }
}
.search-no-result {
  @media screen and (max-width: 1200px) {
    padding: 0 20px !important;
  }
}
