//================================================
//=== FOTOALBUM ==================================
//================================================

/*OVERVIEW PAGINA*/
.fotoalbum-overview {
  // border-top: 1px solid #f1f1f1;
  padding: 0 80px;
  padding-top: 30px;
  float: left;
  width: 100%;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0 15px;
      float: left;
      margin-bottom: 30px;
      width: 25%;
      list-style: none;

      a {
        overflow: hidden;
      }

      img {
        width: 100%;
        transition: all 0.4s ease;
      }

      &:hover img {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
      }

    }
  }
}
@include breakpoint(large) {
  .fotoalbum-overview ul li,
  .fotoalbum-sectie ul li {
    width: 50%;
  }
}
@include breakpoint(medium) {
  .fotoalbum-sectie ul li:nth-child(odd) {
    padding-left: 0px !important;
  }

  .fotoalbum-sectie ul li:nth-child(even) {
    padding-right: 0px !important;
  }

  .sectie-item .fotoalbum .content {
    width: 100%!important;
  }

  .sectie-item .formulier .content {
    width: 100%!important;
  }

  .sectie-item .webform-component-fieldset {
    width: 100%!important;
  }

  .sectie-item .view-fotoalbum {
    width: 100%;
  }
}
