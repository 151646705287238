@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700");
@keyframes slideUpContent {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-100px); } }

@keyframes slideDown {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(0); } }

@keyframes leftToRight {
  from {
    left: 20px; }
  to {
    left: calc(100% - 50px); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes textFadeIn {
  from {
    transform: scale(0.6);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu panels CSS
*/
.mm-menu.mm-horizontal > .mm-panel {
  -webkit-transition: left 0.4s ease;
  -moz-transition: left 0.4s ease;
  -ms-transition: left 0.4s ease;
  -o-transition: left 0.4s ease;
  transition: left 0.4s ease; }

.mm-menu .mm-hidden {
  display: none; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu {
  background: inherit;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0; }
  .mm-menu > .mm-panel {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    background: inherit;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 0; }
    .mm-menu > .mm-panel.mm-opened {
      left: 0%; }
    .mm-menu > .mm-panel.mm-subopened {
      left: -40%; }
    .mm-menu > .mm-panel.mm-highest {
      z-index: 1; }
  .mm-menu .mm-list {
    padding: 20px 0; }
  .mm-menu > .mm-list {
    padding: 20px 0 40px 0; }

.mm-panel > .mm-list {
  margin-left: -20px;
  margin-right: -20px; }
  .mm-panel > .mm-list:first-child {
    padding-top: 0; }

.mm-list,
.mm-list > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-list {
  font: inherit;
  font-size: 14px; }
  .mm-list a,
  .mm-list a:hover {
    text-decoration: none; }
  .mm-list > li {
    position: relative; }
    .mm-list > li > a,
    .mm-list > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      line-height: 20px;
      display: block;
      padding: 10px 10px 10px 20px;
      margin: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    width: auto;
    margin-left: 20px;
    position: relative;
    left: auto; }
  .mm-list a.mm-subopen {
    width: 40px;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
    .mm-list a.mm-subopen:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    .mm-list a.mm-subopen.mm-fullsubopen {
      width: 100%; }
      .mm-list a.mm-subopen.mm-fullsubopen:before {
        border-left: none; }
    .mm-list a.mm-subopen + a,
    .mm-list a.mm-subopen + span {
      padding-right: 5px;
      margin-right: 40px; }
  .mm-list > li.mm-selected > a.mm-subopen {
    background: transparent; }
  .mm-list > li.mm-selected > a.mm-fullsubopen + a,
  .mm-list > li.mm-selected > a.mm-fullsubopen + span {
    padding-right: 45px;
    margin-right: 0; }
  .mm-list a.mm-subclose {
    text-indent: 20px;
    padding-top: 30px;
    margin-top: -20px; }
  .mm-list > li.mm-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-transform: uppercase;
    text-indent: 20px;
    line-height: 25px;
    padding-right: 5px; }
  .mm-list > li.mm-spacer {
    padding-top: 40px; }
    .mm-list > li.mm-spacer.mm-label {
      padding-top: 25px; }
  .mm-list a.mm-subopen:after,
  .mm-list a.mm-subclose:before {
    content: '';
    border: 2px solid transparent;
    display: block;
    width: 7px;
    height: 7px;
    margin-bottom: -5px;
    position: absolute;
    bottom: 50%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .mm-list a.mm-subopen:after {
    border-top: none;
    border-left: none;
    right: 18px; }
  .mm-list a.mm-subclose:before {
    border-right: none;
    border-bottom: none;
    margin-bottom: -15px;
    left: 22px; }

.mm-menu.mm-vertical .mm-list .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }
  .mm-menu.mm-vertical .mm-list .mm-panel li:last-child:after {
    border-color: transparent; }

.mm-menu.mm-vertical .mm-list li.mm-opened > .mm-panel {
  display: block; }

.mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen {
  height: 40px; }
  .mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 16px;
    right: 16px; }

html.mm-opened .mm-page {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-ismenu {
  background: #333;
  color: rgba(255, 255, 255, 0.6); }

.mm-menu .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.15); }

.mm-menu .mm-list > li > a.mm-subclose {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.3); }

.mm-menu .mm-list > li > a.mm-subopen:after, .mm-menu .mm-list > li > a.mm-subclose:before {
  border-color: rgba(255, 255, 255, 0.3); }

.mm-menu .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.15); }

.mm-menu .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu .mm-list > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu .mm-list > li.mm-label {
  background: rgba(255, 255, 255, 0.05); }

.mm-menu.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page,
.mm-fixed-top,
.mm-fixed-bottom {
  -webkit-transition: none 0.4s ease;
  -moz-transition: none 0.4s ease;
  -ms-transition: none 0.4s ease;
  -o-transition: none 0.4s ease;
  transition: none 0.4s ease;
  -webkit-transition-property: top, right, bottom, left, border;
  -moz-transition-property: top, right, bottom, left, border;
  -ms-transition-property: top, right, bottom, left, border;
  -o-transition-property: top, right, bottom, left, border;
  transition-property: top, right, bottom, left, border; }

html.mm-opened .mm-page,
html.mm-opened #mm-blocker {
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0px solid transparent; }

html.mm-opening .mm-page {
  border: 0px solid #010101; }

.mm-fixed-top,
.mm-fixed-bottom {
  position: fixed;
  left: 0; }

.mm-fixed-top {
  top: 0; }

.mm-fixed-bottom {
  bottom: 0; }

html.mm-opened {
  overflow-x: hidden;
  position: relative; }

html.mm-opened .mm-page {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  position: relative; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==) transparent;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999; }

html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }

.mm-menu.mm-current {
  display: block; }

html.mm-opening .mm-page,
html.mm-opening #mm-blocker,
html.mm-opening .mm-fixed-top,
html.mm-opening .mm-fixed-bottom {
  left: 80%; }

.mm-menu {
  width: 80%; }

@media all and (max-width: 175px) {
  .mm-menu {
    width: 140px; }
  html.mm-opening .mm-page,
  html.mm-opening #mm-blocker,
  html.mm-opening .mm-fixed-top,
  html.mm-opening .mm-fixed-bottom {
    left: 140px; } }

@media all and (min-width: 550px) {
  .mm-menu {
    width: 440px; }
  html.mm-opening .mm-page,
  html.mm-opening #mm-blocker,
  html.mm-opening .mm-fixed-top,
  html.mm-opening .mm-fixed-bottom {
    left: 440px; } }

/*
	jQuery.mmenu counters addon CSS
*/
em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 40px;
  top: 50%; }
  em.mm-counter + a.mm-subopen {
    padding-left: 40px; }
    em.mm-counter + a.mm-subopen + a,
    em.mm-counter + a.mm-subopen + span {
      margin-right: 80px; }
  em.mm-counter + a.mm-fullsubopen {
    padding-left: 0; }

.mm-vertical em.mm-counter {
  top: 12px;
  margin-top: 0; }

.mm-nosubresults > em.mm-counter {
  display: none; }

.mm-menu em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu dragOpen addon CSS
*/
html.mm-opened.mm-dragging .mm-menu,
html.mm-opened.mm-dragging .mm-page,
html.mm-opened.mm-dragging .mm-fixed-top,
html.mm-opened.mm-dragging .mm-fixed-bottom,
html.mm-opened.mm-dragging #mm-blocker {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s; }

/*
	jQuery.mmenu header addon CSS
*/
.mm-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  background: inherit;
  border-bottom: 1px solid transparent;
  text-align: center;
  line-height: 20px;
  width: 100%;
  height: 60px;
  padding: 30px 40px 0 40px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0; }
  .mm-header .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 1; }
  .mm-header .mm-prev,
  .mm-header .mm-next {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    bottom: 0; }
    .mm-header .mm-prev:before,
    .mm-header .mm-next:before {
      content: '';
      border: 2px solid transparent;
      display: block;
      width: 7px;
      height: 7px;
      margin-bottom: -5px;
      position: absolute;
      bottom: 50%;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-bottom: -15px; }
  .mm-header .mm-prev {
    left: 0; }
    .mm-header .mm-prev:before {
      border-right: none;
      border-bottom: none;
      left: 22px; }
  .mm-header .mm-next {
    right: 0; }
    .mm-header .mm-next:before {
      border-top: none;
      border-left: none;
      right: 18px; }

.mm-menu.mm-hassearch .mm-header {
  height: 50px;
  padding-top: 20px;
  top: 50px; }
  .mm-menu.mm-hassearch .mm-header .mm-prev:before,
  .mm-menu.mm-hassearch .mm-header .mm-mext:before {
    margin-bottom: -10px; }

.mm-menu.mm-hasheader li.mm-subtitle {
  display: none; }

.mm-menu.mm-hasheader > .mm-panel {
  padding-top: 80px; }
  .mm-menu.mm-hasheader > .mm-panel.mm-list {
    padding-top: 60px; }
  .mm-menu.mm-hasheader > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }

.mm-menu.mm-hasheader.mm-hassearch > .mm-panel {
  padding-top: 120px; }
  .mm-menu.mm-hasheader.mm-hassearch > .mm-panel.mm-list {
    padding-top: 100px; }

.mm-menu .mm-header {
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-header a:before {
    border-color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu labels addon CSS
*/
.mm-menu.mm-fixedlabels .mm-list {
  background: inherit; }
  .mm-menu.mm-fixedlabels .mm-list > li.mm-label {
    background: inherit !important;
    opacity: 0.97;
    height: 25px;
    overflow: visible;
    position: relative;
    z-index: 1; }
    .mm-menu.mm-fixedlabels .mm-list > li.mm-label > div {
      background: inherit;
      width: 100%;
      position: absolute;
      left: 0; }
      .mm-menu.mm-fixedlabels .mm-list > li.mm-label > div > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    .mm-menu.mm-fixedlabels .mm-list > li.mm-label.mm-spacer > div > div {
      padding-top: 25px; }

.mm-list > li.mm-label > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0; }

.mm-list > li.mm-label.mm-opened a.mm-subopen:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 17px; }

.mm-list > li.mm-collapsed {
  display: none; }

.mm-menu .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu searchfield addon CSS
*/
.mm-search,
.mm-search input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box; }

.mm-list > li.mm-search {
  padding: 10px;
  margin-top: -20px; }

.mm-list > li.mm-subtitle + li.mm-search {
  margin-top: 0; }

div.mm-panel > div.mm-search {
  padding: 0 0 10px 0; }

.mm-menu.mm-hasheader .mm-list > li.mm-search {
  margin-top: 0; }

.mm-search {
  background: inherit;
  width: 100%;
  padding: 10px;
  position: relative;
  top: 0;
  z-index: 2; }
  .mm-search input {
    border: none;
    border-radius: 30px;
    font: inherit;
    font-size: 14px;
    line-height: 30px;
    outline: none;
    display: block;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0 10px; }

.mm-menu .mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  display: none;
  padding: 60px 0; }
  .mm-menu .mm-noresultsmsg:after {
    border: none !important; }

.mm-noresults .mm-noresultsmsg {
  display: block; }

.mm-menu li.mm-nosubresults > a.mm-subopen {
  display: none; }
  .mm-menu li.mm-nosubresults > a.mm-subopen + a,
  .mm-menu li.mm-nosubresults > a.mm-subopen + span {
    padding-right: 10px; }

.mm-menu.mm-hassearch > .mm-panel {
  padding-top: 70px; }
  .mm-menu.mm-hassearch > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }

.mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child {
  margin-top: -10px; }
  .mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child + .mm-list {
    padding-top: 0; }

.mm-menu .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }

.mm-menu .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu toggles addon CSS
*/
label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin: -15px 0 0 0;
  position: absolute;
  top: 50%;
  z-index: 1; }
  label.mm-toggle div {
    border-radius: 30px;
    width: 28px;
    height: 28px;
    margin: 1px; }

input.mm-toggle {
  position: absolute;
  left: -10000px; }
  input.mm-toggle:checked ~ label.mm-toggle div {
    float: right; }

label.mm-toggle {
  right: 20px; }
  label.mm-toggle + a,
  label.mm-toggle + span {
    margin-right: 70px; }

a.mm-subopen + label.mm-toggle {
  right: 50px; }
  a.mm-subopen + label.mm-toggle + a,
  a.mm-subopen + label.mm-toggle + span {
    margin-right: 100px; }

em.mm-counter + a.mm-subopen + label.mm-toggle {
  right: 90px; }
  em.mm-counter + a.mm-subopen + label.mm-toggle + a,
  em.mm-counter + a.mm-subopen + label.mm-toggle + span {
    margin-right: 140px; }

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.15); }
  .mm-menu label.mm-toggle div {
    background: #333; }

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

/*
	jQuery.mmenu effects extension CSS
*/
html.mm-slide .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-slide.mm-opened .mm-menu {
  -webkit-transform: translateX(-40%);
  -moz-transform: translateX(-40%);
  -ms-transform: translateX(-40%);
  -o-transform: translateX(-40%);
  transform: translateX(-40%); }

html.mm-slide.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }

html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(40%);
  -moz-transform: translateX(40%);
  -ms-transform: translateX(40%);
  -o-transform: translateX(40%);
  transform: translateX(40%); }

html.mm-slide.mm-right.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }

html.mm-slide.mm-top.mm-opened .mm-menu {
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  -o-transform: translateY(-40%);
  transform: translateY(-40%); }

html.mm-slide.mm-top.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-slide.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: translateY(40%);
  -moz-transform: translateY(40%);
  -ms-transform: translateY(40%);
  -o-transform: translateY(40%);
  transform: translateY(40%); }

html.mm-slide.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-zoom-menu .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-zoom-menu.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(-40%);
  -moz-transform: scale(0.7, 0.7) translateX(-40%);
  -ms-transform: scale(0.7, 0.7) translateX(-40%);
  -o-transform: scale(0.7, 0.7) translateX(-40%);
  transform: scale(0.7, 0.7) translateX(-40%);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

html.mm-zoom-menu.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }

html.mm-zoom-menu.mm-right.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(40%);
  -moz-transform: scale(0.7, 0.7) translateX(40%);
  -ms-transform: scale(0.7, 0.7) translateX(40%);
  -o-transform: scale(0.7, 0.7) translateX(40%);
  transform: scale(0.7, 0.7) translateX(40%);
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }

html.mm-zoom-menu.mm-right.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }

html.mm-zoom-menu.mm-top.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(-40%);
  -moz-transform: scale(0.7, 0.7) translateY(-40%);
  -ms-transform: scale(0.7, 0.7) translateY(-40%);
  -o-transform: scale(0.7, 0.7) translateY(-40%);
  transform: scale(0.7, 0.7) translateY(-40%);
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }

html.mm-zoom-menu.mm-top.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-menu.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(40%);
  -moz-transform: scale(0.7, 0.7) translateY(40%);
  -ms-transform: scale(0.7, 0.7) translateY(40%);
  -o-transform: scale(0.7, 0.7) translateY(40%);
  transform: scale(0.7, 0.7) translateY(40%);
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }

html.mm-zoom-menu.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-page .mm-page {
  -webkit-transition-property: -webkit-transform, top, right, bottom, left, border;
  -moz-transition-property: -moz-transform, top, right, bottom, left, border;
  -ms-transition-property: -ms-transform, top, right, bottom, left, border;
  -o-transition-property: -o-transform, top, right, bottom, left, border;
  transition-property: transform, top, right, bottom, left, border; }

html.mm-zoom-page.mm-opened .mm-page {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

html.mm-zoom-page.mm-opening .mm-page {
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5); }

html.mm-zoom-page.mm-right.mm-opened .mm-page {
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }

html.mm-zoom-page.mm-top.mm-opened .mm-page {
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }

html.mm-zoom-page.mm-bottom.mm-opened .mm-page {
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }

html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel {
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition-property: -webkit-transform, left;
  -moz-transition-property: -moz-transform, left;
  -ms-transition-property: -ms-transform, left;
  -o-transition-property: -o-transform, left;
  transition-property: transform, left; }
  html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
    html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened.mm-subopened {
      -webkit-transform: scale(0.7, 0.7);
      -moz-transform: scale(0.7, 0.7);
      -ms-transform: scale(0.7, 0.7);
      -o-transform: scale(0.7, 0.7);
      transform: scale(0.7, 0.7); }

/*
	jQuery.mmenu fullscreen extension CSS
*/
html.mm-opening.mm-fullscreen .mm-page,
html.mm-opening.mm-fullscreen #mm-blocker,
html.mm-opening.mm-fullscreen .mm-fixed-top,
html.mm-opening.mm-fullscreen .mm-fixed-bottom {
  left: 100%; }

.mm-menu.mm-fullscreen {
  width: 100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-fullscreen {
    width: 140px; }
  html.mm-opening.mm-fullscreen .mm-page,
  html.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-opening.mm-fullscreen .mm-fixed-bottom {
    left: 140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-fullscreen {
    width: 10000px; }
  html.mm-opening.mm-fullscreen .mm-page,
  html.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-opening.mm-fullscreen .mm-fixed-bottom {
    left: 10000px; } }

.mm-menu.mm-top.mm-fullscreen {
  height: 100%; }

html.mm-top.mm-opening.mm-fullscreen .mm-page,
html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
  top: 100%; }

html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
  bottom: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-top.mm-fullscreen {
    height: 140px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-page,
  html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
    top: 140px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-top.mm-fullscreen {
    height: 10000px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-page,
  html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
    top: 10000px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: -10000px; } }

.mm-menu.mm-right.mm-fullscreen {
  width: 100%; }

html.mm-right.mm-opening.mm-fullscreen .mm-page,
html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
  right: 100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-right.mm-fullscreen {
    width: 140px; }
  html.mm-right.mm-opening.mm-fullscreen .mm-page,
  html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
    right: 140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-right.mm-fullscreen {
    width: 10000px; }
  html.mm-right.mm-opening.mm-fullscreen .mm-page,
  html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
    right: 10000px; } }

.mm-menu.mm-bottom.mm-fullscreen {
  height: 100%; }

html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
  bottom: 100%; }

html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
  top: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-bottom.mm-fullscreen {
    height: 140px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
  html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: 140px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
    top: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-bottom.mm-fullscreen {
    height: 10000px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
  html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: 10000px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
    top: -10000px; } }

.mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
  left: -100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
    left: -140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
    left: -10000px; } }

.mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
  top: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
    top: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
    top: -10000px; } }

.mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
  right: -100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
    right: -140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
    right: -10000px; } }

.mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
  bottom: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
    bottom: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
    bottom: -10000px; } }

html.mm-front .mm-fixed-top,
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-bottom {
  left: 0;
  right: auto; }

html.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-top {
  top: 0; }

html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-bottom {
  bottom: 0; }

html.mm-opened.mm-fullscreen .mm-page {
  box-shadow: none !important; }

/*
	jQuery.mmenu position extension CSS
*/
.mm-menu.mm-top {
  width: 100%; }

html.mm-top.mm-opened .mm-page,
html.mm-top.mm-opened #mm-blocker {
  top: 0%; }

html.mm-top.mm-opened.mm-opening .mm-page,
html.mm-top.mm-opened.mm-opening #mm-blocker,
html.mm-top.mm-opened.mm-opening .mm-fixed-top,
html.mm-top.mm-opened.mm-opening .mm-fixed-bottom {
  left: 0; }

.mm-menu.mm-right {
  left: auto;
  right: 0; }

html.mm-right.mm-opened .mm-page,
html.mm-right.mm-opened #mm-blocker,
html.mm-right.mm-opened .mm-fixed-top,
html.mm-right.mm-opened .mm-fixed-bottom {
  left: auto;
  right: 0%; }

html.mm-right.mm-opened.mm-opening .mm-page,
html.mm-right.mm-opened.mm-opening #mm-blocker,
html.mm-right.mm-opened.mm-opening .mm-fixed-top,
html.mm-right.mm-opened.mm-opening .mm-fixed-bottom {
  left: auto; }

.mm-menu.mm-bottom {
  width: 100%;
  top: auto;
  bottom: 0; }

html.mm-bottom.mm-opened .mm-page,
html.mm-bottom.mm-opened #mm-blocker {
  bottom: 0%;
  top: auto; }

html.mm-bottom.mm-opened.mm-opening .mm-page,
html.mm-bottom.mm-opened.mm-opening #mm-blocker,
html.mm-bottom.mm-opened.mm-opening .mm-fixed-top,
html.mm-bottom.mm-opened.mm-opening .mm-fixed-bottom {
  top: auto;
  left: 0; }

.mm-menu.mm-top {
  height: 80%; }

html.mm-top.mm-opening .mm-page,
html.mm-top.mm-opening #mm-blocker,
html.mm-top.mm-opening .mm-fixed-top {
  top: 80%; }

html.mm-top.mm-opening .mm-fixed-bottom {
  bottom: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-top {
    height: 140px; }
  html.mm-top.mm-opening .mm-page,
  html.mm-top.mm-opening #mm-blocker,
  html.mm-top.mm-opening .mm-fixed-top {
    top: 140px; }
  html.mm-top.mm-opening .mm-fixed-bottom {
    bottom: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-top {
    height: 880px; }
  html.mm-top.mm-opening .mm-page,
  html.mm-top.mm-opening #mm-blocker,
  html.mm-top.mm-opening .mm-fixed-top {
    top: 880px; }
  html.mm-top.mm-opening .mm-fixed-bottom {
    bottom: -880px; } }

.mm-menu.mm-right {
  width: 80%; }

html.mm-right.mm-opening .mm-page,
html.mm-right.mm-opening #mm-blocker,
html.mm-right.mm-opening .mm-fixed-top,
html.mm-right.mm-opening .mm-fixed-bottom {
  right: 80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-right {
    width: 140px; }
  html.mm-right.mm-opening .mm-page,
  html.mm-right.mm-opening #mm-blocker,
  html.mm-right.mm-opening .mm-fixed-top,
  html.mm-right.mm-opening .mm-fixed-bottom {
    right: 140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-right {
    width: 440px; }
  html.mm-right.mm-opening .mm-page,
  html.mm-right.mm-opening #mm-blocker,
  html.mm-right.mm-opening .mm-fixed-top,
  html.mm-right.mm-opening .mm-fixed-bottom {
    right: 440px; } }

.mm-menu.mm-bottom {
  height: 80%; }

html.mm-bottom.mm-opening .mm-page,
html.mm-bottom.mm-opening #mm-blocker,
html.mm-bottom.mm-opening .mm-fixed-bottom {
  bottom: 80%; }

html.mm-bottom.mm-opening .mm-fixed-top {
  top: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-bottom {
    height: 140px; }
  html.mm-bottom.mm-opening .mm-page,
  html.mm-bottom.mm-opening #mm-blocker,
  html.mm-bottom.mm-opening .mm-fixed-bottom {
    bottom: 140px; }
  html.mm-bottom.mm-opening .mm-fixed-top {
    top: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-bottom {
    height: 880px; }
  html.mm-bottom.mm-opening .mm-page,
  html.mm-bottom.mm-opening #mm-blocker,
  html.mm-bottom.mm-opening .mm-fixed-bottom {
    bottom: 880px; }
  html.mm-bottom.mm-opening .mm-fixed-top {
    top: -880px; } }

/*
	jQuery.mmenu z-position extension CSS
*/
html.mm-front.mm-opened .mm-page {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important; }

.mm-menu.mm-front,
.mm-menu.mm-next {
  -webkit-transition: none 0.4s ease;
  -moz-transition: none 0.4s ease;
  -ms-transition: none 0.4s ease;
  -o-transition: none 0.4s ease;
  transition: none 0.4s ease;
  -webkit-transition-property: top, right, bottom, left, -webkit-transform;
  -moz-transition-property: top, right, bottom, left, -moz-transform;
  -ms-transition-property: top, right, bottom, left, -o-transform;
  -o-transition-property: top, right, bottom, left, -o-transform;
  transition-property: top, right, bottom, left, transform; }

html.mm-front .mm-page,
html.mm-front #mm-blocker {
  z-index: 0; }

.mm-menu.mm-front {
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); }

html.mm-opened.mm-next .mm-page {
  box-shadow: none; }

html.mm-opening .mm-menu.mm-front, html.mm-opening .mm-menu.mm-next {
  left: 0%; }

.mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
  left: 0; }

html.mm-opening .mm-menu.mm-top.mm-front, html.mm-opening .mm-menu.mm-top.mm-next {
  left: 0;
  top: 0%; }

.mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
  left: auto; }

html.mm-opening .mm-menu.mm-right.mm-front, html.mm-opening .mm-menu.mm-right.mm-next {
  left: auto;
  right: 0%; }

.mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
  top: auto;
  left: 0; }

html.mm-opening .mm-menu.mm-bottom.mm-front, html.mm-opening .mm-menu.mm-bottom.mm-next {
  left: 0;
  bottom: 0%; }

.mm-menu.mm-front, .mm-menu.mm-next {
  left: -80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-front, .mm-menu.mm-next {
    left: -140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-front, .mm-menu.mm-next {
    left: -440px; } }

.mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
  top: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
    top: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
    top: -880px; } }

.mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
  right: -80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
    right: -140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
    right: -440px; } }

.mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
  bottom: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
    bottom: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
    bottom: -880px; } }

html.mm-front .mm-fixed-top,
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-bottom {
  left: 0;
  right: auto; }

html.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-top {
  top: 0; }

html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-bottom {
  bottom: 0; }

/*
	jQuery.mmenu themes extension CSS
*/
html.mm-opened.mm-light .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-ismenu.mm-light {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-light .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-light .mm-list > li > a.mm-subclose {
  background: rgba(255, 255, 255, 0.6);
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list > li > a.mm-subopen:after, .mm-menu.mm-light .mm-list > li > a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-light .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-light .mm-list > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-light .mm-list > li.mm-label {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-light .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-light .mm-header a:before {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-light label.mm-toggle div {
    background: #f3f3f3; }

.mm-menu.mm-light input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-white .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-ismenu.mm-white {
  background: #fff;
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-white .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-white .mm-list > li > a.mm-subclose {
  background: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list > li > a.mm-subopen:after, .mm-menu.mm-white .mm-list > li > a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-white .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-white .mm-list > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.06); }

.mm-menu.mm-white .mm-list > li.mm-label {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-white .mm-header a:before {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-white label.mm-toggle div {
    background: #fff; }

.mm-menu.mm-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-black .mm-page {
  box-shadow: none; }

.mm-ismenu.mm-black {
  background: #000;
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-black .mm-list > li:after {
  border-color: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-black .mm-list > li > a.mm-subclose {
  background: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list > li > a.mm-subopen:after, .mm-menu.mm-black .mm-list > li > a.mm-subclose:before {
  border-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list > li > a.mm-subopen:before {
  border-color: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-black .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-black .mm-list > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.25); }

.mm-menu.mm-black .mm-list > li.mm-label {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-header {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu.mm-black .mm-header a:before {
    border-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2); }
  .mm-menu.mm-black label.mm-toggle div {
    background: #000; }

.mm-menu.mm-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */
/* Browser Resets
*********************************/
.flex-container a:active,
.flex-container a:focus,
.flexslider a:active,
.flexslider a:focus {
  outline: none; }

.flex-control-nav,
.flex-direction-nav,
.slides {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Icon Fonts
*********************************/
/* Font-face Icons */
/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0; }
  .flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden; }
  .flexslider .slides img {
    width: 100%;
    display: block; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flex-pauseplay span {
  text-transform: capitalize; }

/* Clearfix for the .slides element */
.slides:after {
  content: '\0020';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block; }

/* FlexSlider Default Theme
*********************************/
.flexslider {
  margin: 0 0 60px;
  background: #fff;
  border: 4px solid #fff;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  zoom: 1; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.loading .flex-viewport {
  max-height: 300px; }

.flexslider .slides {
  zoom: 1; }

.carousel li {
  margin-right: 5px; }

/* Direction Nav */
.flex-direction-nav {
  *height: 0; }
  .flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .flex-direction-nav .flex-prev {
    left: -50px; }
  .flex-direction-nav .flex-next {
    right: -50px;
    text-align: right; }

.flexslider:hover .flex-prev {
  opacity: 0.7;
  left: 10px; }

.flexslider:hover .flex-next {
  opacity: 0.7;
  right: 10px; }
  .flexslider:hover .flex-next:hover {
    opacity: 1; }

.flexslider:hover .flex-prev:hover {
  opacity: 1; }

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default; }

.flex-direction-nav a:before {
  font-family: 'flexslider-icon';
  font-size: 40px;
  display: inline-block;
  content: '\f001'; }

.flex-direction-nav a.flex-next:before {
  content: '\f002'; }

/* Pause/Play */
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000; }
  .flex-pauseplay a:before {
    font-family: 'flexslider-icon';
    font-size: 20px;
    display: inline-block;
    content: '\f004'; }
  .flex-pauseplay a:hover {
    opacity: 1; }
  .flex-pauseplay a.flex-play:before {
    content: '\f003'; }

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center; }
  .flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline; }

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.7); }
  .flex-control-paging li a.flex-active {
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    cursor: default; }

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }
  .flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0; }
  .flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: 0.7;
    cursor: pointer; }
    .flex-control-thumbs img:hover {
      opacity: 1; }
  .flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default; }

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px; }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px; } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }
  .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #fff; }

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    border-right: 17px solid #fff;
    margin-left: 31px; }
  .mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
    border-left: 17px solid #fff;
    margin-left: 39px; }
  .mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */
/**
 * Wireframes.
 */
.with-wireframes #header, .with-wireframes #main, .with-wireframes #content, .with-wireframes #navigation, .with-wireframes .region-sidebar-first, .with-wireframes .region-sidebar-second, .with-wireframes #footer, .with-wireframes .region-bottom {
  outline: 1px solid #ccc; }

.lt-ie8 .with-wireframes #header, .lt-ie8 .with-wireframes #main, .lt-ie8 .with-wireframes #content, .lt-ie8 .with-wireframes #navigation, .lt-ie8 .with-wireframes .region-sidebar-first, .lt-ie8 .with-wireframes .region-sidebar-second, .lt-ie8 .with-wireframes #footer, .lt-ie8 .with-wireframes .region-bottom {
  /* IE6/7 do not support the outline property. */
  border: 1px solid #ccc; }

/**
 * Accessibility features.
 */
/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible, .element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px); }

#navigation .block-menu .block__title, #navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px); }

/* Turns off the element-invisible effect. */
.element-focusable:active, .element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto; }

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0; }
  #skip-link a {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff; }
    #skip-link a:visited {
      display: block;
      width: 100%;
      padding: 2px 0 3px 0;
      text-align: center;
      background-color: #666;
      color: #fff; }

/**
 * Branding header.
 */
/* Wrapping link for logo. */
.header__logo {
  float: left;
  /* LTR */
  margin: 0;
  padding: 0; }

/* Logo image. */
.header__logo-image {
  vertical-align: bottom; }

/* Wrapper for website name and slogan. */
.header__name-and-slogan {
  float: left; }

/* The name of the website. */
.header__site-name {
  margin: 0;
  font-size: 2em;
  line-height: 1.5em; }

/* The link around the name of the website. */
.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none; }

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline; }

/* The slogan (or tagline) of a website. */
.header__site-slogan {
  margin: 0; }

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: right;
  /* LTR */ }

/* Wrapper for any blocks placed in the header region. */
.header__region {
  /* Clear the logo. */
  clear: both; }

/**
 * Navigation bar.
 */
#navigation {
  /* Sometimes you want to prevent overlapping with main div. */
  /* overflow: hidden; */ }
  #navigation .block {
    margin-bottom: 0; }
  #navigation .links, #navigation .menu {
    margin: 0;
    padding: 0;
    text-align: left;
    /* LTR */ }
  #navigation .links li, #navigation .menu li {
    /* A simple method to get navigation links to appear in one line. */
    float: left;
    /* LTR */
    padding: 0 10px 0 0;
    /* LTR */
    list-style-type: none;
    list-style-image: none; }

/* Main menu and secondary menu links and menu block links. */
/**
 * Breadcrumb navigation.
 */
.breadcrumb ol {
  margin: 0;
  padding: 0; }

.breadcrumb li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0; }

/**
 * Titles.
 */
.page__title, .node__title, .block__title, .comments__title, .comments__form-title, .comment__title {
  /* Comment title. */
  margin: 0; }

/**
 * Messages.
 */
.messages, .messages--status, .messages--warning, .messages--error {
  margin: 1.5em 0;
  padding: 10px;
  background: #43AC6A;
  color: #fff;
  border: 0; }

.messages *, .messages--status *, .messages--warning *, .messages--error * {
  color: #fff;
  padding: 0;
  margin: 0; }

.messages a, .messages--status a, .messages--warning a, .messages--error a {
  color: #fff;
  text-decoration: underline; }

.messages__list {
  margin: 0; }

.messages__item {
  list-style-image: none; }

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333; }

/* System status report. */
.ok, .messages--status {
  background-color: #43AC6A; }

.warning, .messages--warning {
  background-color: #f08a24; }

.error, .messages--error {
  background-color: #f04124; }

/**
 * Tabs.
 */
/* Basic positioning styles shared by primary and secondary tabs. */
.tabs-primary, .tabs-secondary {
  overflow: hidden;
  *zoom: 1;
  background-color: #bbb;
  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  border-bottom: 1px solid #bbbbbb \0/ie;
  margin: 1.5em 0;
  padding: 0 2px;
  white-space: nowrap; }

.tabs-primary__tab {
  float: left;
  /* LTR */
  margin: 0 3px; }

.tabs-secondary__tab {
  float: left;
  /* LTR */
  margin: 0 3px; }
  .tabs-secondary__tab.is-active {
    float: left;
    /* LTR */
    margin: 0 3px; }

a.tabs-primary__tab-link, a.tabs-secondary__tab-link {
  border: 1px solid #e9e9e9;
  border-right: 0;
  border-bottom: 0;
  display: block;
  line-height: 1.5em;
  text-decoration: none; }

/* Primary tabs. */
.tabs-primary__tab {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 1px 1px 0 white;
  border: 1px solid #bbbbbb;
  border-bottom-color: transparent;
  /* IE 9 and earlier don't understand gradients. */
  border-bottom: 0 \0/ie; }
  .tabs-primary__tab.is-active {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    text-shadow: 1px 1px 0 white;
    border: 1px solid #bbbbbb;
    border-bottom-color: transparent;
    /* IE 9 and earlier don't understand gradients. */
    border-bottom: 0 \0/ie;
    border-bottom-color: white; }

a.tabs-primary__tab-link {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center; }
  a.tabs-primary__tab-link.is-active {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    color: #333;
    background-color: #dedede;
    letter-spacing: 1px;
    padding: 0 1em;
    text-align: center; }
  a.tabs-primary__tab-link:hover, a.tabs-primary__tab-link:focus {
    background-color: #e9e9e9;
    border-color: #f2f2f2; }
  a.tabs-primary__tab-link:active, a.tabs-primary__tab-link.is-active {
    background-color: transparent;
    *zoom: 1;
    background-color: #e9e9e9;
    border-color: #fff; }

/* Secondary tabs. */
.tabs-secondary {
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -1.5em; }

.tabs-secondary__tab {
  margin: 0.75em 3px; }
  .tabs-secondary__tab.is-active {
    margin: 0.75em 3px; }

a.tabs-secondary__tab-link {
  -webkit-border-radius: 0.75em;
  -moz-border-radius: 0.75em;
  -ms-border-radius: 0.75em;
  -o-border-radius: 0.75em;
  border-radius: 0.75em;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  text-shadow: 1px 1px 0 white;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em; }
  a.tabs-secondary__tab-link.is-active {
    -webkit-border-radius: 0.75em;
    -moz-border-radius: 0.75em;
    -ms-border-radius: 0.75em;
    -o-border-radius: 0.75em;
    border-radius: 0.75em;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    text-shadow: 1px 1px 0 white;
    background-color: #f2f2f2;
    color: #666;
    padding: 0 .5em; }
  a.tabs-secondary__tab-link:hover, a.tabs-secondary__tab-link:focus {
    background-color: #dedede;
    border-color: #999;
    color: #333; }
  a.tabs-secondary__tab-link:active, a.tabs-secondary__tab-link.is-active {
    text-shadow: 1px 1px 0 #333333;
    background-color: #666;
    border-color: #000;
    color: #fff; }

/**
 * Inline styles.
 */
/* List of links generated by theme_links(). */
.inline {
  display: inline;
  padding: 0; }
  .inline li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0;
    /* LTR */ }

/* The inline field label used by the Fences module. */
span.field-label {
  padding: 0 1em 0 0;
  /* LTR */ }

/**
 * 'More' links.
 */
.more-link {
  text-align: right;
  /* LTR */ }

.more-help-link {
  text-align: right;
  /* LTR */ }
  .more-help-link a {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC");
    *background-image: url("../../images/help.png");
    background-position: 0 50%;
    /* LTR */
    background-repeat: no-repeat;
    padding: 1px 0 1px 20px;
    /* LTR */ }

/**
 * Pager.
 */
/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  padding: 0;
  text-align: center; }

.pager-item, .pager-first, .pager-previous, .pager-next, .pager-last, .pager-ellipsis {
  /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none; }

.pager-current {
  /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
  font-weight: bold; }

/**
 * Blocks.
 */
/* Block wrapper. */
/**
 * Menus.
 */
.menu__item.is-leaf {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAD1BMVEX///+/v7+Li4sAAADAwMBFvsw8AAAAAXRSTlMAQObYZgAAAB1JREFUCFtjYAADYwMGBmYVZSDhKAwkFJWhYiAAAB2+Aa/9ugeaAAAAAElFTkSuQmCC");
  *list-style-image: url("../../images/menu-leaf.png");
  list-style-type: square; }

.menu__item.is-expanded {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  *list-style-image: url("../../images/menu-expanded.png");
  list-style-type: circle; }

.menu__item.is-collapsed {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  /* LTR */
  *list-style-image: url("../../images/menu-collapsed.png");
  /* LTR */
  list-style-type: disc; }

/* The active item in a Drupal menu. */
.menu a.active {
  color: #000; }

/**
 * Marker.
 */
/* The 'new' or 'updated' marker. */
.new, .update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent; }

/**
 * Unpublished note.
 */
/* The word 'Unpublished' displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, 'Arial Narrow', Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  /* A very nice CSS3 property. */
  word-wrap: break-word; }

.lt-ie8 .node-unpublished > *, .lt-ie8 .comment-unpublished > * {
  /* Otherwise these elements will appear below the 'Unpublished' text. */
  position: relative; }

/**
 * Comments.
 */
/* Wrapper for the list of comments and its title. */
.comments {
  margin: 1.5em 0; }

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea; }

/* Wrapper for a single comment. */
.comment {
  /* Comment's permalink wrapper. */ }
  .comment ul {
    margin: 0; }
  .comment .permalink {
    text-transform: uppercase;
    font-size: 75%; }

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: 30px;
  /* LTR */ }

/**
 * Forms.
 */
/* Wrapper for a form element (or group of form elements) and its label. */
form .form-item {
  margin: 1.5em 0; }

.block.zoekveld .form-item {
  margin: 0; }

/* Pack groups of checkboxes and radio buttons closer together. */
.form-checkboxes .form-item, .form-radios .form-item {
  /* Drupal core uses '0.4em 0'. */
  margin: 0; }

/* Form items in a table. */
tr.odd .form-item, tr.even .form-item {
  margin: 0; }

/* Highlight the form elements that caused a form submission error. */
.form-item input.error, .form-item textarea.error, .form-item select.error {
  border: 1px solid #c00; }

.form-item .description {
  font-size: 0.85em; }

/* The descriptive help text (separate from the label). */
.form-type-radio .description, .form-type-checkbox .description {
  margin-left: 2.4em; }

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00; }

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal; }

/* Buttons used by contrib modules like Media. */
a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button; }

/* Password confirmation. */
.password-parent, .confirm-parent {
  margin: 0; }

/* Drupal's default login form block. */
#user-login-form {
  text-align: left;
  /* LTR */ }

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */
/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0; }

/* The 'Log in using OpenID' and 'Cancel OpenID login' links. */
.openid-link, .user-link {
  margin-top: 1.5em; }

html.js #user-login-form li.openid-link, #user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px;
  /* LTR */ }

#user-login ul {
  margin: 1.5em 0; }

/**
 * Drupal admin tables.
 */
form th {
  text-align: left;
  /* LTR */
  padding-right: 1em;
  /* LTR */
  border-bottom: 3px solid #ccc; }

form tbody {
  border-top: 1px solid #ccc; }

form table ul {
  margin: 0; }

tr.even, tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em; }

tr.even {
  background-color: #fff; }

.lt-ie8 tr.even th, .lt-ie8 tr.even td {
  /* IE doesn't display borders on table rows. */
  border-bottom: 1px solid #ccc; }

.lt-ie8 tr.odd th, .lt-ie8 tr.odd td {
  /* IE doesn't display borders on table rows. */
  border-bottom: 1px solid #ccc; }

/* Markup generated by theme_tablesort_indicator(). */
td.active {
  background-color: #ddd; }

td.checkbox {
  text-align: center; }

/* Center checkboxes inside table cell. */
th.checkbox {
  text-align: center; }

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc; }

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff; }

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js .collapsible .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  *background-image: url("../../images/menu-expanded.png");
  background-position: 5px 65%;
  /* LTR */
  background-repeat: no-repeat;
  padding-left: 15px;
  /* LTR */ }

html.js .collapsed .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  /* LTR */
  *background-image: url("../../images/menu-collapsed.png");
  /* LTR */
  background-position: 5px 50%;
  /* LTR */ }

.fieldset-legend .summary {
  color: #999;
  font-size: 0.9em;
  margin-left: 0.5em; }

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0; }

tr.drag-previous {
  background-color: #ffd; }

.tabledrag-toggle-weight {
  font-size: 0.9em; }

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc; }

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold; }
  .progress .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px; }
  .progress .filled {
    background-color: #0072b9;
    background-image: url("../../images/progress.gif"); }

@keyframes slideUpContent {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-100px); } }

@keyframes slideDown {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(0); } }

@keyframes leftToRight {
  from {
    left: 20px; }
  to {
    left: calc(100% - 50px); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes textFadeIn {
  from {
    transform: scale(0.6);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1; } }

.row:after, #edit-submitted-upload-cv-ajax-wrapper:after, .webform-component-file:after, .footerblocks:after, .no-flexbox .bean-footer-payoff:after, .content:after, .home-views:after {
  content: '';
  display: block;
  clear: both; }

.content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) {
  list-style-type: none;
  padding-left: 1.3em; }
  .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li {
    margin: 0; }
    .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before {
      content: '\25AA';
      color: #96004f;
      display: inline-block;
      margin-left: -1.3em;
      width: 1.3em;
      font-family: monospace; }
      .messages .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content .messages ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before,
      .bg_color_dark .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content
      .bg_color_dark ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before,
      .bg_color_light .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content
      .bg_color_light ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before {
        color: #fff; }

*,
*:after,
*:before {
  box-sizing: border-box; }

.row {
  width: 1160px;
  max-width: 100%;
  margin: 0 auto; }
  .row .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 -20px; }
  .row.collapse {
    width: 100%; }
    .row.collapse .column,
    .row.collapse .columns {
      padding: 0; }
  .row .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 -20px; }
    .row .row.collapse {
      margin: 0; }

.column,
.columns,
.panel-flexible .panels-flexible-region.columns {
  float: left;
  padding: 0 20px;
  position: relative; }
  .column.centered,
  .columns.centered,
  .panel-flexible .panels-flexible-region.columns.centered {
    float: none;
    margin: 0 auto; }

[class*='column'] + [class*='column'].end {
  float: left; }

.one, .row .one {
  width: 8.33333%; }

.two, .row .two {
  width: 16.66667%; }

.three, .row .three {
  width: 25%; }

.four, .row .four {
  width: 33.33333%; }

.five, .row .five {
  width: 41.66667%; }

.six, .row .six {
  width: 50%; }

.seven, .row .seven {
  width: 58.33333%; }

.eight, .row .eight {
  width: 66.66667%; }

.nine, .row .nine {
  width: 75%; }

.ten, .row .ten {
  width: 83.33333%; }

.eleven, .row .eleven {
  width: 91.66667%; }

.twelve, .row .twelve {
  width: 100%; }

.row .offset-by-one {
  margin-left: 8.33333%; }

.row .offset-by-two {
  margin-left: 16.66667%; }

.row .offset-by-three {
  margin-left: 25%; }

.row .offset-by-four {
  margin-left: 33.33333%; }

.row .offset-by-five {
  margin-left: 41.66667%; }

.row .offset-by-six {
  margin-left: 50%; }

.row .offset-by-seven {
  margin-left: 58.33333%; }

.row .offset-by-eight {
  margin-left: 66.66667%; }

.row .offset-by-nine {
  margin-left: 75%; }

.row .offset-by-ten {
  margin-left: 83.33333%; }

.panels-flexible-row {
  padding-bottom: 0 !important; }

.column.centered {
  margin-left: auto;
  margin-right: auto;
  float: none; }

@media screen and (max-width: 767px) {
  .left,
  .right {
    float: none; }
  body {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; }
  .row {
    width: auto;
    min-width: 0;
    margin-left: 0;
    margin-right: 0; }
  .column,
  .columns {
    width: 100% !important; }
  .column:after,
  .column:before,
  .columns:after,
  .columns:before {
    content: '';
    display: table; }
  .column:after,
  .columns:after {
    clear: both; }
  .offset-by-one, .offset-by-two, .offset-by-three, .offset-by-four, .offset-by-five, .offset-by-six, .offset-by-seven, .offset-by-eight, .offset-by-nine, .offset-by-ten {
    margin-left: 0 !important; }
  .push-two, .push-three, .push-four, .push-five, .push-six, .push-seven, .push-eight, .push-nine, .push-ten {
    left: auto; }
  .pull-two, .pull-three, .pull-four, .pull-five, .pull-six, .pull-seven, .pull-eight, .pull-nine, .pull-ten {
    right: auto; }
  /* Mobile 4-column Grid */
  .row .mobile-one {
    width: 25% !important;
    float: left;
    padding: 0 20px; }
    .row .mobile-one:last-child {
      float: right; }
    .row .mobile-one.end {
      float: left; }
  .row.collapse .mobile-one {
    padding: 0; }
  .row .mobile-two {
    width: 50% !important;
    float: left;
    padding: 0 20px; }
    .row .mobile-two:last-child {
      float: right; }
    .row .mobile-two.end {
      float: left; }
  .row.collapse .mobile-two {
    padding: 0; }
  .row .mobile-three {
    width: 75% !important;
    float: left;
    padding: 0 20px; }
    .row .mobile-three:last-child {
      float: right; }
    .row .mobile-three.end {
      float: left; }
  .row.collapse .mobile-three {
    padding: 0; }
  .row .mobile-four {
    width: 100% !important;
    float: left;
    padding: 0 20px; }
    .row .mobile-four:last-child {
      float: right; }
    .row .mobile-four.end {
      float: left; }
  .row.collapse .mobile-four {
    padding: 0; }
  .push-one-mobile {
    left: 25%; }
  .pull-one-mobile {
    right: 25%; }
  .push-two-mobile {
    left: 50%; }
  .pull-two-mobile {
    right: 50%; }
  .push-three-mobile {
    left: 75%; }
  .pull-three-mobile {
    right: 75%; } }

.npl {
  padding-left: 0px !important; }

.npr {
  padding-right: 0px !important; }

@media screen and (max-width: 767px) {
  .col {
    width: 100% !important; } }

.vp {
  display: none !important; }

.vt {
  display: none !important; }

.hd {
  display: none !important; }

.vd {
  display: inherit !important; }

.np,
.npd {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.npl,
.npld {
  padding-left: 0px !important; }

.npr,
.nprd {
  padding-right: 0px !important; }

.npl {
  padding-left: 0px !important; }

.npr {
  padding-right: 0px !important; }

@media screen and (max-width: 1023px) {
  .hd {
    display: inherit !important; }
  .vd {
    display: none !important; }
  .vt {
    display: inherit !important; }
  .ht {
    display: none !important; }
  .npld,
  .nprd {
    padding: 0 20px !important; } }

@media screen and (max-width: 767px) {
  .hd {
    display: inherit !important; }
  .vd {
    display: none !important; }
  .vp {
    display: inherit !important; }
  .hp {
    display: none !important; }
  .npd,
  .npld,
  .nprd {
    padding: 0 20px !important; }
  .nplm,
  .npm {
    padding-left: 0px !important; }
  .npm,
  .nprm {
    padding-right: 0px !important; } }

@media screen and (max-width: 767px) {
  .sidebar {
    padding-left: 0; } }

/* Requires
   globals.css */
/* Standard Forms ---------------------- */
form {
  margin: 0 0 16px; }

.row form .row {
  margin: 0 -8px; }
  .row form .row .column,
  .row form .row .columns {
    padding: 0 8px; }
  .row form .row.collapse {
    margin: 0; }
    .row form .row.collapse .column,
    .row form .row.collapse .columns {
      padding: 0; }

label {
  font-size: ms(0);
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin-bottom: 3px; }
  label.right {
    float: none;
    text-align: right; }
  label.inline {
    line-height: ms(0)24px;
    margin: 0 0 16px; }

.postfix,
.prefix {
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  height: ms(0)24px;
  line-height: ms(0)24px-1; }

a.button.postfix,
a.button.prefix {
  padding-left: 0;
  padding-right: 0;
  text-align: center; }

span.postfix,
span.prefix {
  background: #f2f2f2;
  border: 1px solid #cccccc; }

.prefix {
  left: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  overflow: hidden; }

.postfix {
  right: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
  background-color: #fff;
  font-family: inherit;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  color: #444444;
  display: block;
  font-size: 16px;
  margin: 0 0 16px;
  padding: 8px 15px;
  height: ms(0)24px;
  width: 100%;
  transition: all 0.15s linear; }
  input[type='date'].oversize,
  input[type='datetime'].oversize,
  input[type='email'].oversize,
  input[type='number'].oversize,
  input[type='password'].oversize,
  input[type='search'].oversize,
  input[type='tel'].oversize,
  input[type='text'].oversize,
  input[type='time'].oversize,
  input[type='url'].oversize,
  textarea.oversize {
    font-size: ms(1);
    padding: 6px 8px; }
  input[type='date']:focus,
  input[type='datetime']:focus,
  input[type='email']:focus,
  input[type='number']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='text']:focus,
  input[type='time']:focus,
  input[type='url']:focus,
  textarea:focus {
    background: #fff;
    border-color: #579bd8;
    box-shadow: 0 0 6px 0 #94B8D1; }
  input[type='date'][disabled],
  input[type='datetime'][disabled],
  input[type='email'][disabled],
  input[type='number'][disabled],
  input[type='password'][disabled],
  input[type='search'][disabled],
  input[type='tel'][disabled],
  input[type='text'][disabled],
  input[type='time'][disabled],
  input[type='url'][disabled],
  textarea[disabled] {
    background-color: #ddd; }

textarea {
  height: auto; }

select {
  width: 100%; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #A99A8D; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #A99A8D; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #A99A8D; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #A99A8D; }

.description {
  display: inline-block;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-duration: .5s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: 0px;
  background: #A99A8D;
  border-radius: 15px;
  color: #fff;
  padding: 5px 20px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 700; }
  .description.show {
    opacity: 1;
    visibility: visible; }
  .description:after {
    content: ' ';
    position: absolute;
    left: 7px;
    bottom: -9px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #A99A8D transparent transparent transparent; }

.form-item input:focus + .description {
  opacity: 1;
  visibility: visible; }

fieldset {
  border: solid 1px #ddd;
  border-radius: 3px;
  padding: 12px;
  margin: 18px 0; }
  fieldset legend {
    font-weight: bold;
    background: #fff;
    padding: 0 3px;
    margin: 0;
    margin-left: -3px; }

/* Errors */
.error input,
.error input:focus,
.error textarea,
.error textarea:focus,
input.error,
input.error:focus,
textarea.error,
textarea.error:focus {
  border-color: #c60f13;
  background-color: rgba(198, 15, 19, 0.1); }

.error input:focus,
.error textarea:focus,
input.error:focus,
textarea.error:focus {
  outline-color: #f5797c; }

.error label,
label.error {
  color: #c60f13; }

.error small,
small.error {
  display: block;
  padding: 6px 4px;
  margin-top: -17px;
  margin-bottom: 16px;
  background: #c60f13;
  color: #fff;
  font-size: ms(0)-2;
  font-weight: bold;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; }

/* Correct FF custom dropdown height */
@-moz-document url-prefix() {
  form.custom div.custom.dropdown a.selector {
    height: 28px; } }

.lt-ie9 form.custom div.custom.dropdown a.selector {
  height: 28px; }

#edit-submitted-upload-cv-ajax-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; }

.webform-component-file {
  position: relative; }
  .webform-component-file .form-submit {
    display: none; }
  html.js .webform-component-file input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    cursor: pointer; }
  .webform-component-file .upload-label {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 8px 15px;
    color: #A99A8D;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    width: calc(100% - 12.5rem);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    z-index: 1;
    float: left; }
    @media screen and (max-width: 767px) {
      .webform-component-file .upload-label {
        width: calc(100% - 8.4375rem); } }
    .webform-component-file .upload-label.dirty {
      color: #231F20; }
  .webform-component-file .btn {
    float: right;
    margin: 0 !important;
    line-height: normal;
    font-size: 1rem;
    padding: 8px 15px;
    border: 1px solid #4692DC;
    min-width: 11.875rem !important; }
    @media screen and (max-width: 767px) {
      .webform-component-file .btn {
        min-width: 7.8125rem !important; } }

.form-item-url {
  display: none; }

.sidemenu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition-property: opacity, visibility, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease; }
  .no-transform .sidemenu {
    left: -26.25rem; }
  .sidemenu .main-menu,
  .sidemenu .site-menu,
  .sidemenu .language-menu {
    padding-bottom: 0.625rem;
    margin-bottom: 1.25rem; }
  .sidemenu .language-menu {
    min-height: 43px; }
  .sidemenu .site-menu {
    margin-bottom: 1.75rem; }

svg.colored-icon path {
  fill: #96004f; }

.sidemenu,
.sidemenu--float {
  background: #fff;
  z-index: 110; }
  .sidemenu svg,
  .sidemenu .icon-text,
  .sidemenu--float svg,
  .sidemenu--float .icon-text {
    display: inline-block;
    vertical-align: middle; }

.sidemenu--float {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 3.8125rem;
  padding: 1.625rem 1.875rem;
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition-property: opacity, visibility, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease; }
  @media screen and (max-width: 1200px) {
    .sidemenu--float {
      position: absolute;
      top: 153px;
      margin: 0;
      padding: 0 0 0 1.25rem; }
      .sidemenu--float .open-menu-text {
        display: none; }
      .sidemenu--float .open-menu-trigger,
      .sidemenu--float .open-search-trigger,
      .sidemenu--float .searchbox-wrapper {
        display: inline-block;
        margin: 0; } }
  @media screen and (max-width: 1023px) {
    .sidemenu--float {
      padding: 0 0 0 2.25rem; } }
  @media screen and (max-width: 767px) {
    .sidemenu--float {
      top: 85px;
      padding: 0 0 0 1.5625rem; } }

.sidemenu--menu {
  width: 26.25rem;
  max-width: 100vw;
  padding: 1.625rem 1.875rem; }

.open-menu-trigger,
.close-menu-trigger,
.open-search-trigger {
  color: #96004f;
  cursor: pointer;
  padding-left: 1.875rem; }
  .open-menu-trigger svg,
  .close-menu-trigger svg,
  .open-search-trigger svg {
    display: inline-block;
    margin-left: -1.875rem;
    width: 1.875rem; }
    @media screen and (max-width: 767px) {
      .open-menu-trigger svg,
      .close-menu-trigger svg,
      .open-search-trigger svg {
        width: 1rem; } }

.open-search-trigger {
  margin-top: 1.625rem; }

.close-menu-trigger {
  margin-bottom: 0.6875rem; }

.searchbox-wrapper {
  width: 0;
  opacity: 0;
  visibility: hidden;
  position: relative;
  margin: 1.625rem 0;
  transition-property: opacity, width, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease; }
  .searchbox-wrapper .form-item {
    margin: 0;
    padding-right: 2.8125rem; }
  .searchbox-wrapper form {
    margin: 0; }
  .searchbox-wrapper [type='text'] {
    height: 2.1875rem;
    margin: 0; }
  .searchbox-wrapper [type='submit'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 2.1875rem;
    height: 2.1875rem; }
    .searchbox-wrapper [type='submit']::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url("/sites/all/themes/front/images/svg/arrow.svg") center no-repeat; }

#page {
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease; }

@media screen and (min-width: 768px) {
  .search-open #page {
    transform: translateX(12.5rem); }
    .no-transform .search-open #page {
      margin-right: -12.5rem; } }

.search-open .searchbox-wrapper {
  width: 12.5rem;
  opacity: 1;
  visibility: visible; }

.menu-open #page {
  transform: translateX(26.25rem); }
  .no-transform .menu-open #page {
    margin-right: -26.25rem; }

.menu-open .sidemenu--float {
  opacity: 0;
  visibility: hidden; }
  @media screen and (max-width: 1200px) {
    .menu-open .sidemenu--float {
      transform: translateX(26.25rem);
      opacity: 1;
      visibility: visible; }
      .no-transform .menu-open .sidemenu--float {
        margin-right: -26.25rem; } }

.menu-open .sidemenu {
  opacity: 1;
  visibility: visible;
  transform: translateX(0); }
  .no-transform .menu-open .sidemenu {
    left: 0; }

.site-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .site-menu .site-wrapper {
    flex: 1 0 25%;
    width: 25%;
    transition: transform .15s ease; }
    .site-menu .site-wrapper:hover {
      transform: translateY(-0.625rem); }
  .site-menu .site-wrapper.vr-main {
    flex: 0 0 100%;
    margin-bottom: 6px;
    width: 100%; }
    .site-menu .site-wrapper.vr-main .site {
      width: 50%;
      margin: 0 auto; }
  .site-menu .site-wrapper.active {
    transform: translateY(-1.25rem) !important; }
    .site-menu .site-wrapper.active .site::after {
      content: '';
      display: block;
      margin: 0 auto;
      height: 2px;
      width: 66.66667%;
      background-color: #96004f;
      transform: translateY(0.625rem); }
  .site-menu .site,
  .site-menu .site svg,
  .site-menu .site img {
    display: block; }

.language-switcher .language {
  display: inline-block;
  margin-right: 1.375rem; }

.language-switcher .switch-language {
  color: #231F20;
  display: block;
  font-size: 1.25rem;
  line-height: 1.55;
  text-decoration: none; }
  .language-switcher .switch-language:hover {
    color: #4692DC; }
  .language-switcher .switch-language.active {
    color: #96004f; }

.main-menu .expanded svg {
  display: inline-block;
  margin-left: -1.875rem;
  width: 1.875rem;
  transition: transform .15s ease; }

.main-menu .expanded.submenu-open svg {
  transform: rotate(90deg); }

.main-menu .expanded .menu {
  display: none; }

.main-menu .menu,
.social-menu .menu {
  padding-left: 1.875rem; }

.main-menu .menu__item,
.social-menu .menu__item {
  list-style: none; }

.main-menu .menu__link,
.social-menu .menu__link {
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.55;
  text-decoration: none;
  margin-bottom: 0.9375rem;
  color: #231F20; }
  .main-menu .menu__link:hover,
  .social-menu .menu__link:hover {
    color: #4692DC; }

.social-menu {
  margin: 1.25rem 0 0.625rem 1.875rem; }
  .social-menu .social-menu__item {
    display: inline-block; }
  .social-menu .social-menu__link {
    width: 1.875rem;
    height: 1.875rem;
    display: inline-block;
    margin-right: 10px;
    background-color: #96004f;
    font-size: 0; }
    .social-menu .social-menu__link:hover {
      text-decoration: none;
      background-color: #4692DC; }
    .social-menu .social-menu__link[data-icon='facebook'] {
      mask: url("/sites/all/themes/front/images/svg/social-facebook.svg");
      mask-repeat: no-repeat;
      mask-size: contain; }
    .social-menu .social-menu__link[data-icon='linkedin'] {
      mask: url("/sites/all/themes/front/images/svg/social-linkedin.svg");
      mask-repeat: no-repeat;
      mask-size: contain; }

footer {
  margin: 0 -20px; }
  @media screen and (max-width: 767px) {
    footer {
      margin: 0; } }

.footerwrap {
  overflow: hidden; }
  .footerwrap .footerblock {
    padding: 40px 20px 65px;
    padding-top: 20px;
    position: relative;
    overflow: hidden; }
    .footerwrap .footerblock .image {
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      transition-duration: 5s;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat !important;
      background-size: cover !important; }
    .footerwrap .footerblock p {
      color: #fff;
      font-size: 1.375rem;
      line-height: 1.125;
      font-weight: 600;
      font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
      z-index: 2;
      position: relative; }
      @media screen and (max-width: 767px) {
        .footerwrap .footerblock p {
          font-size: 0.875rem; } }
    .footerwrap .footerblock .overlay {
      transition-property: opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      opacity: 0;
      background: rgba(70, 146, 220, 0.8);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: none; }
    .footerwrap .footerblock .more-link {
      transition-property: opacity, right;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      position: absolute;
      bottom: 25px;
      right: 90%;
      white-space: nowrap;
      z-index: 2;
      color: #fff;
      font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
      font-size: 16px;
      line-height: 19px;
      font-weight: 700; }
      .footerwrap .footerblock .more-link .icon-text {
        transition-property: opacity;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        opacity: 0; }
      .footerwrap .footerblock .more-link svg {
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transform: translateX(1rem); }
      .footerwrap .footerblock .more-link svg,
      .footerwrap .footerblock .more-link .icon-text {
        display: inline-block;
        vertical-align: middle; }
      @media screen and (max-width: 767px) {
        .footerwrap .footerblock .more-link {
          right: 84%; } }
    .footerwrap .footerblock:hover .image {
      transform: scale(1.1); }
    .footerwrap .footerblock:hover .overlay {
      opacity: 1; }
    .footerwrap .footerblock:hover .more-link {
      right: 5%; }
      .footerwrap .footerblock:hover .more-link .icon-text {
        opacity: 1; }
      .footerwrap .footerblock:hover .more-link svg {
        transform: translateX(0); }
  .footerwrap:hover {
    text-decoration: none; }

.footer-bottom {
  position: relative;
  margin-top: 1.5625rem;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 142px;
  padding: 83px 0 30px 0;
  text-align: center;
  z-index: 101; }
  .footer-bottom::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    border-top: 1px solid #f0f0f0; }
  .footer-bottom .block {
    display: inline-block;
    margin: 0; }
  .footer-bottom a {
    text-decoration: none; }
  .footer-bottom ul {
    list-style-position: inside; }
    .footer-bottom ul li {
      list-style-type: none;
      margin-left: 15px;
      padding-left: 15px;
      display: block;
      float: left;
      font-size: 14px;
      color: #231F20; }
      .footer-bottom ul li:first-child {
        border: 0;
        margin: 0;
        padding: 0; }
      .footer-bottom ul li strong {
        font-weight: 300; }
      .footer-bottom ul li a,
      .footer-bottom ul li a.active {
        color: #231F20; }
      .footer-bottom ul li a:hover {
        text-decoration: underline; }
  .footer-bottom .region-bottom-logo ul li {
    margin-left: 15px;
    padding-left: 15px; }
  @media screen and (max-width: 1200px) {
    .footer-bottom {
      padding: 5px 20px 10px; } }

@media screen and (max-width: 767px) {
  .footer-bottom {
    text-align: left;
    padding: 1.875rem 1.5625rem 2.5rem; }
    .footer-bottom .columns {
      padding: 0;
      float: none !important; }
    .footer-bottom .columns.left {
      float: left; }
    .footer-bottom .columns.right {
      float: right; }
    .footer-bottom .region-bottom-logo ul li,
    .footer-bottom ul li {
      float: none;
      display: inline-block;
      padding: 0;
      border: 0; }
      .footer-bottom .region-bottom-logo ul li a,
      .footer-bottom ul li a {
        line-height: 24px; }
    .footer-bottom .region-bottom-logo {
      margin-top: 1.25rem; } }

/*Standaard styling Drupal overwrite*/
.footer-bottom .menu__item.is-leaf,
.footer-bottom ul li a {
  list-style-image: none;
  list-style-type: none; }

.footerblocks {
  margin: 5.625rem 0; }
  @media screen and (max-width: 1023px) {
    .footerblocks {
      margin: 0 19px 0 20px; }
      .footerblocks .three.columns {
        width: 50% !important;
        padding: 0 1px 1px 0; } }
  @media screen and (max-width: 767px) {
    .footerblocks {
      margin: 0 -1px 0 0; } }

.bean-footer-payoff {
  margin: 1.5625rem 0;
  background: #DED9CC;
  display: flex;
  justify-content: space-between;
  align-items: stretch; }
  @media screen and (max-width: 767px) {
    .bean-footer-payoff {
      display: block; } }
  .bean-footer-payoff .columns {
    padding: 0; }
  .bean-footer-payoff .footer-payoff-image-wrapper {
    position: relative;
    flex: 0 0 25%;
    width: 25%; }
    .bean-footer-payoff .footer-payoff-image-wrapper:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 55.55556%; }
    .bean-footer-payoff .footer-payoff-image-wrapper > .footer-payoff-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @media screen and (max-width: 767px) {
      .bean-footer-payoff .footer-payoff-image-wrapper {
        flex-basis: 100%;
        width: 100%; } }
    .no-flexbox .bean-footer-payoff .footer-payoff-image-wrapper {
      float: left; }
    .bean-footer-payoff .footer-payoff-image-wrapper .footer-payoff-image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; }
  .bean-footer-payoff .field-name-field-inhoud {
    font-size: 1.125rem;
    padding: 2.875rem 3.125rem 2.25rem;
    vertical-align: middle;
    flex: 1 1 66.66667%;
    width: 66.66667%; }
    @media screen and (max-width: 767px) {
      .bean-footer-payoff .field-name-field-inhoud {
        flex-basis: 100%;
        width: 100%;
        padding: 1.875rem 1.5625rem 1.5625rem;
        font-size: 1rem; } }
    .no-flexbox .bean-footer-payoff .field-name-field-inhoud {
      float: right; }
    .bean-footer-payoff .field-name-field-inhoud h2, .bean-footer-payoff .field-name-field-inhoud h3, .bean-footer-payoff .field-name-field-inhoud h4, .bean-footer-payoff .field-name-field-inhoud h5, .bean-footer-payoff .field-name-field-inhoud h6 {
      margin-top: 0; }
  .bean-footer-payoff.color-inverted .field-name-field-inhoud * {
    color: #fff !important; }
  .bean-footer-payoff.color-inverted h2, .bean-footer-payoff.color-inverted h3, .bean-footer-payoff.color-inverted h4, .bean-footer-payoff.color-inverted h5, .bean-footer-payoff.color-inverted h6 {
    color: #fff; }

.footer-bottom .social-menu {
  margin: 0; }
  .footer-bottom .social-menu .social-menu__item {
    margin: 0;
    padding: 0;
    font-size: 0; }
  .footer-bottom .social-menu .social-menu__link {
    margin: 0 0 0 15px; }

.footer-bottom .columns,
.footer-bottom .region-bottom-menu,
.footer-bottom .region-bottom-logo {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  min-height: 1.875rem; }

@media screen and (max-width: 1023px) {
  .footer-bottom .columns,
  .footer-bottom .region-bottom-menu,
  .footer-bottom .region-bottom-logo {
    flex-direction: column; }
  .footer-bottom .social-menu {
    margin: 0 0 1.875rem; }
    .footer-bottom .social-menu .social-menu__link {
      margin: 0 5px 0 5px; } }

.content-top {
  transition-property: margin;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  margin-top: 0; }

.header {
  background: #fff;
  z-index: 101;
  box-shadow: 0 1px 0 0 #f0f0f0;
  margin-bottom: 1.25rem; }
  @media screen and (max-width: 1200px) {
    .header {
      height: 153px;
      box-shadow: none;
      margin-bottom: 2.5rem; } }
  @media screen and (max-width: 767px) {
    .header {
      height: 85px; } }
  .admin-menu .header {
    top: 59px; }
  .header .row {
    width: 100%;
    margin: 0; }
  .header .columns {
    padding: 0; }
    @media screen and (max-width: 1200px) {
      .header .columns {
        width: 100%; } }
  .header .logo {
    margin: 0.625rem 5rem 0.625rem 0; }
    @media screen and (max-width: 1200px) {
      .header .logo {
        margin-right: 1.5625rem; } }
    .header .logo a,
    .header .logo img {
      display: block; }
    .header .logo img {
      margin: 0 0 0 auto; }
      @media screen and (max-width: 767px) {
        .header .logo img {
          max-width: 295px; } }
  .header .block.phone p {
    color: #96004f;
    font-size: 1.125rem;
    margin-top: 87px; }
    @media screen and (max-width: 1200px) {
      .header .block.phone p {
        position: absolute;
        margin-top: 0;
        top: 153px;
        line-height: 40px;
        left: 80px;
        font-size: 1rem; } }
    @media screen and (max-width: 1023px) {
      .header .block.phone p {
        left: 95px; } }
    @media screen and (max-width: 767px) {
      .header .block.phone p {
        top: 85px;
        line-height: 37px;
        left: 95px;
        font-size: 0.875rem; } }

.block.zoekveld #search-api-page-search-form-search {
  position: relative; }

.block.zoekveld input[type='text'] {
  border: 1px solid rgba(102, 102, 102, 0.1);
  box-shadow: none;
  border-radius: 15px;
  padding: 0 10px;
  color: #666666;
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 23px;
  margin: 0; }
  .block.zoekveld input[type='text']::placeholder {
    font-weight: 700; }

.block.zoekveld #edit-keys-1,
.block.zoekveld input.form-autocomplete {
  background: url("../images/ajax-loader.gif") right 50px no-repeat; }

.block.zoekveld #edit-keys-1.throbbing,
.block.zoekveld input.throbbing {
  background-position: 84% 10px; }

.block.zoekveld .form-submit {
  font-family: "rijko";
  border: 0;
  position: absolute;
  top: -1px;
  right: 1px;
  color: #96004f;
  font-size: 14px;
  background: transparent;
  padding: 0 10px;
  margin: 0; }
  .block.zoekveld .form-submit:hover {
    cursor: pointer; }

.block.zoekveld .form-text {
  float: right;
  min-width: 200px; }

#autocomplete {
  border: 1px solid #231F20;
  border-top: 0;
  width: 300px !important; }

#autocomplete ul li {
  padding: 5px 10px;
  border-bottom: 1px solid #231F20; }

#autocomplete ul li:hover {
  background: #231F20;
  cursor: pointer; }

@media screen and (max-width: 767px) {
  .search-form {
    display: none; } }

.pane-node-field-afbeelding + .pane-node-title-field {
  transform: translateY(-100%);
  position: absolute;
  width: 100%;
  background-color: rgba(150, 0, 79, 0.7);
  padding: 3.125rem 2.5rem 2.8125rem; }
  .pane-node-field-afbeelding + .pane-node-title-field h1 {
    margin: 0;
    color: #fff;
    text-shadow: 0 1px 2px rgba(70, 146, 220, 0.3);
    font-weight: 300; }
  @media screen and (max-width: 767px) {
    .pane-node-field-afbeelding + .pane-node-title-field {
      position: static;
      transform: translateY(0);
      padding: 1.25rem 1.5625rem 1.625rem;
      background: #96004f; } }

.overzicht:hover a {
  opacity: .8; }

.overzicht a {
  display: block;
  margin-bottom: 30px; }
  .overzicht a img {
    transition: all 0.4s ease; }
  .overzicht a .views-field-field-afbeelding .field-content,
  .overzicht a .views-field-field-afbeeldingen .field-content {
    overflow: hidden;
    display: block;
    line-height: 0; }
  .overzicht a .views-field-title .field-content {
    color: #000;
    font-size: 18px;
    padding: 10px 0;
    display: block; }
  .overzicht a:hover {
    text-decoration: none;
    opacity: 1 !important; }
    .overzicht a:hover img {
      transform: scale(1.1); }

.infowrap {
  display: block;
  position: relative;
  float: left;
  width: 100%; }
  .infowrap .icoon {
    text-align: center;
    padding: 15px; }
    .infowrap .icoon .icon:before {
      display: inline-block;
      float: none;
      font-size: 34px;
      vertical-align: middle; }
      .large .infowrap .icoon .icon:before {
        margin: 0 0 10px;
        clear: both;
        line-height: initial;
        font-size: 86px; }
    .infowrap .icoon p {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 19px;
      line-height: 20px;
      vertical-align: middle; }
      .front .infowrap .icoon p {
        font-size: 22px;
        line-height: 30px; }
  .infowrap.border {
    border: 1px solid #231F20; }
  .infowrap.imageleft .image {
    width: 30%;
    float: left;
    min-height: 150px;
    background-size: cover !important;
    background-position: center !important; }
  .infowrap.imageleft .infoblok {
    width: 70%;
    float: left; }
  .infowrap.imagetop .image {
    width: 100%;
    float: left;
    min-height: 170px;
    background-size: cover !important;
    background-position: center !important; }
  .infowrap.imagetop .infoblok {
    width: 100%;
    float: left; }

div.infoblok {
  float: left;
  width: 100%;
  background-size: cover !important;
  background-position: center !important; }
  div.infoblok h2 {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 25px; }
  div.infoblok p {
    padding-bottom: 0;
    font-size: 14px;
    line-height: 20px; }
  div.infoblok a.btn {
    margin-bottom: 0; }
  div.infoblok.kleur-2ba6cb a,
  div.infoblok.kleur-2ba6cb blockquote,
  div.infoblok.kleur-2ba6cb blockquote p:before,
  div.infoblok.kleur-2ba6cb h1,
  div.infoblok.kleur-2ba6cb h2,
  div.infoblok.kleur-2ba6cb h3,
  div.infoblok.kleur-2ba6cb h4,
  div.infoblok.kleur-2ba6cb h5,
  div.infoblok.kleur-2ba6cb h6,
  div.infoblok.kleur-2ba6cb ol,
  div.infoblok.kleur-2ba6cb p,
  div.infoblok.kleur-2ba6cb ul, div.infoblok.kleur-e3e3e3 a,
  div.infoblok.kleur-e3e3e3 blockquote,
  div.infoblok.kleur-e3e3e3 blockquote p:before,
  div.infoblok.kleur-e3e3e3 h1,
  div.infoblok.kleur-e3e3e3 h2,
  div.infoblok.kleur-e3e3e3 h3,
  div.infoblok.kleur-e3e3e3 h4,
  div.infoblok.kleur-e3e3e3 h5,
  div.infoblok.kleur-e3e3e3 h6,
  div.infoblok.kleur-e3e3e3 ol,
  div.infoblok.kleur-e3e3e3 p,
  div.infoblok.kleur-e3e3e3 ul, div.infoblok.kleur-f2f2f2 a,
  div.infoblok.kleur-f2f2f2 blockquote,
  div.infoblok.kleur-f2f2f2 blockquote p:before,
  div.infoblok.kleur-f2f2f2 h1,
  div.infoblok.kleur-f2f2f2 h2,
  div.infoblok.kleur-f2f2f2 h3,
  div.infoblok.kleur-f2f2f2 h4,
  div.infoblok.kleur-f2f2f2 h5,
  div.infoblok.kleur-f2f2f2 h6,
  div.infoblok.kleur-f2f2f2 ol,
  div.infoblok.kleur-f2f2f2 p,
  div.infoblok.kleur-f2f2f2 ul {
    color: #fff; }
  div.infoblok.kleur-2ba6cb .btn, div.infoblok.kleur-e3e3e3 .btn, div.infoblok.kleur-f2f2f2 .btn {
    background: #fff;
    color: #c60f13; }
  div.infoblok.kleur-2ba6cb .btn.rand, div.infoblok.kleur-e3e3e3 .btn.rand, div.infoblok.kleur-f2f2f2 .btn.rand {
    background: transparent;
    border: 2px solid #fff;
    color: #fff; }
  div.infoblok .color-e3e3e3 {
    background: #e3e3e3; }
  div.infoblok .color-f2f2f2 {
    background: #f2f2f2; }
  div.infoblok .color-2ba6cb {
    background: #2ba6cb; }

.front .kleur-9FDCE7 {
  text-align: center; }
  .front .kleur-9FDCE7 p {
    font-size: 38px;
    line-height: initial; }

.icon:before {
  color: #fff;
  float: left;
  margin: 0 15px 0 0;
  line-height: 20px; }

.isotope-container,
.isotope-container .isotope-element {
  transition-duration: .8s; }

.isotope-container {
  transition-property: height, width; }

.isotope-container .isotope-element {
  transition-property: transform, opacity; }

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  transition-duration: 0s; }

.isotope-options {
  margin: 0 !important; }

.isotope {
  margin: 20px 0 0;
  display: inline-block;
  width: 100%; }
  .isotope > .view-content {
    margin: 0 -15px; }

.isotope-container {
  margin: 0 !important;
  padding: 0 !important; }
  .isotope-container .isotope-element {
    width: 25%;
    padding: 0 15px; }
    .drie .isotope-container .isotope-element {
      width: 33.333333333333333%; }
    @media screen and (max-width: 767px) {
      .isotope-container .isotope-element {
        float: left !important;
        width: 100% !important; } }

.taxonomy-widget {
  padding: 30px;
  background: #231F20;
  margin-bottom: 30px; }
  .taxonomy-widget h2 {
    margin-top: 0;
    color: #96004f;
    font-size: 20px; }
  .taxonomy-widget ul li {
    list-style-type: square; }
    .taxonomy-widget ul li a {
      color: #000;
      text-decoration: none; }
      .taxonomy-widget ul li a.active {
        font-weight: 600; }
      .taxonomy-widget ul li a:hover {
        opacity: .5; }

a.anchor {
  padding-top: 180px;
  margin-top: -180px;
  float: left;
  width: 100%;
  display: block !important;
  visibility: hidden !important; }

.messages {
  float: left;
  width: 100%;
  clear: both; }

.snelmailer-widget {
  float: left;
  width: 100%;
  margin: 15px 0; }
  .snelmailer-widget .btn {
    font-size: 12px; }
  .snelmailer-widget .container-error {
    display: none !important; }

.views-exposed-form label {
  text-align: center;
  font-size: 20px;
  font-weight: 400; }

.views-exposed-form .form-radios {
  text-align: center; }

.views-exposed-form .views-exposed-widget {
  width: 100%; }
  .views-exposed-form .views-exposed-widget input[type='radio'] {
    display: none; }
  .views-exposed-form .views-exposed-widget input[type=radio]:checked + label {
    background: #630034;
    color: #fff;
    border: 1px solid #630034; }
  .views-exposed-form .views-exposed-widget .form-item {
    float: none;
    display: inline-block; }
  .views-exposed-form .views-exposed-widget .option {
    float: left;
    padding: 8px 20px;
    min-width: 180px;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    margin: 10px 10px 0;
    color: #96004f;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    border: 1px solid #96004f; }
    .views-exposed-form .views-exposed-widget .option:hover {
      background: #96004f;
      color: #fff; }

.pager {
  background: #231F20;
  margin: 50px 0 0 !important;
  padding: 20px 0; }
  .pager li {
    background: none !important;
    padding-left: 0 !important;
    margin-left: 7px !important; }
  .pager .pager-current,
  .pager .pager-item a {
    background: #fff !important;
    padding: 0;
    text-align: center;
    border-radius: 2px;
    height: 18px;
    width: 18px;
    display: inline-block;
    line-height: 17px;
    color: #231F20;
    font-size: 13px; }
  .pager .pager-item a {
    color: #fff;
    background: #231F20 !important; }
  .pager .pager-first a,
  .pager .pager-last a,
  .pager .pager-next a,
  .pager .pager-previous a {
    color: #fff;
    font-weight: bold; }

.homeblok {
  background: #231F20;
  float: left;
  width: 100%;
  padding: 15px; }

.ajax-progress {
  position: absolute;
  left: 50%;
  bottom: 0; }

.ajax-progress .throbber {
  background: url("../images/ajax-loader.gif");
  position: absolute;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10; }

:focus {
  outline: none; }

.anchor {
  display: block !important;
  content: ' ' !important;
  margin-top: -200px !important;
  height: 200px !important;
  visibility: hidden !important; }

a {
  transition: all .4s ease; }

.media_embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden; }

.media_embed.widescreen {
  padding-bottom: 56.34%; }

.media_embed.vimeo {
  padding-top: 0; }

.media_embed embed,
.media_embed iframe,
.media_embed object,
.media_embed video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

img {
  height: auto; }

embed,
img,
object {
  max-width: 100%; }

img {
  -ms-interpolation-mode: bicubic; }

#map_canvas img,
.map_canvas img {
  max-width: none !important; }

.home-views {
  margin: -20px -20px 0;
  padding: 0; }
  @media screen and (max-width: 1023px) {
    .home-views > .six, .row .home-views > .six {
      width: 100% !important; } }
  @media screen and (max-width: 767px) {
    .home-views {
      margin-top: -0.3125rem;
      padding: 0 20px 0; } }

.pane-sharethis {
  margin: 60px 0;
  text-align: right; }
  @media screen and (min-width: 768px) {
    .sidebar .pane-sharethis {
      text-align: left; } }
  .pane-sharethis svg {
    display: block; }
    .pane-sharethis svg path {
      transition: fill .15s ease; }
  .pane-sharethis .pane-title,
  .pane-sharethis .sharethis-wrapper,
  .pane-sharethis .sharethis-wrapper span {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle; }
    @media screen and (min-width: 768px) {
      .sidebar .pane-sharethis .pane-title, .sidebar
      .pane-sharethis .sharethis-wrapper, .sidebar
      .pane-sharethis .sharethis-wrapper span {
        display: block; } }
  .pane-sharethis .pane-title {
    text-transform: lowercase;
    font-size: 0.875rem;
    color: #A99A8D;
    font-weight: 400; }
    @media screen and (min-width: 768px) {
      .sidebar .pane-sharethis .pane-title {
        margin-bottom: 1rem; } }
  .pane-sharethis .sharethis-wrapper span {
    margin-left: 1.25rem;
    opacity: .5;
    transition: opacity .15s ease;
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .pane-sharethis .sharethis-wrapper span {
        margin-left: 1rem; } }
    .pane-sharethis .sharethis-wrapper span:hover {
      opacity: 1; }
    @media screen and (min-width: 768px) {
      .sidebar .pane-sharethis .sharethis-wrapper span {
        margin-left: 0;
        margin-bottom: 0.625rem; } }

.geolocation-map {
  margin: 0; }
  .geolocation-map .gm-style a {
    display: none !important; }
  .geolocation-map .gmnoprint {
    display: none; }
  @media screen and (max-width: 1023px) {
    .geolocation-map {
      height: 500px !important; } }
  @media screen and (max-width: 767px) {
    .geolocation-map {
      height: 300px !important; } }

.svg-wrapper {
  height: 60px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 320px; }

.shape {
  fill: transparent;
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  stroke: #19f6e8; }

.text {
  color: #fff;
  font-family: 'Roboto Condensed';
  font-size: 22px;
  letter-spacing: 8px;
  line-height: 32px;
  position: relative;
  top: -48px; }

@keyframes draw {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 8px; }
  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
    stroke-width: 2px; } }

.svg-wrapper:hover .shape {
  -webkit-animation: 0.5s draw linear forwards;
  animation: 0.5s draw linear forwards; }

.facebook,
.facebook-alt {
  transition: color .15s ease; }
  .facebook svg path,
  .facebook-alt svg path {
    transition: fill .15s ease; }

.facebook {
  color: #3b5998; }
  .facebook svg path {
    fill: #3b5998; }
  .facebook:hover {
    color: #96004f; }
    .facebook:hover svg path {
      fill: #96004f; }

.facebook-alt {
  color: #96004f; }
  .facebook-alt svg path {
    fill: #96004f; }
  .facebook-alt:hover {
    color: #3b5998; }
    .facebook-alt:hover svg path {
      fill: #3b5998; }

.sharethis-wrapper .st_facebook_custom {
  color: #96004f; }
  .sharethis-wrapper .st_facebook_custom svg path {
    fill: #96004f; }
  .sharethis-wrapper .st_facebook_custom:hover {
    color: #3b5998; }
    .sharethis-wrapper .st_facebook_custom:hover svg path {
      fill: #3b5998; }

.twitter,
.twitter-alt {
  transition: color .15s ease; }
  .twitter svg path,
  .twitter-alt svg path {
    transition: fill .15s ease; }

.twitter {
  color: #55acee; }
  .twitter svg path {
    fill: #55acee; }
  .twitter:hover {
    color: #96004f; }
    .twitter:hover svg path {
      fill: #96004f; }

.twitter-alt {
  color: #96004f; }
  .twitter-alt svg path {
    fill: #96004f; }
  .twitter-alt:hover {
    color: #55acee; }
    .twitter-alt:hover svg path {
      fill: #55acee; }

.sharethis-wrapper .st_twitter_custom {
  color: #96004f; }
  .sharethis-wrapper .st_twitter_custom svg path {
    fill: #96004f; }
  .sharethis-wrapper .st_twitter_custom:hover {
    color: #55acee; }
    .sharethis-wrapper .st_twitter_custom:hover svg path {
      fill: #55acee; }

.linkedin,
.linkedin-alt {
  transition: color .15s ease; }
  .linkedin svg path,
  .linkedin-alt svg path {
    transition: fill .15s ease; }

.linkedin {
  color: #0077b5; }
  .linkedin svg path {
    fill: #0077b5; }
  .linkedin:hover {
    color: #96004f; }
    .linkedin:hover svg path {
      fill: #96004f; }

.linkedin-alt {
  color: #96004f; }
  .linkedin-alt svg path {
    fill: #96004f; }
  .linkedin-alt:hover {
    color: #0077b5; }
    .linkedin-alt:hover svg path {
      fill: #0077b5; }

.sharethis-wrapper .st_linkedin_custom {
  color: #96004f; }
  .sharethis-wrapper .st_linkedin_custom svg path {
    fill: #96004f; }
  .sharethis-wrapper .st_linkedin_custom:hover {
    color: #0077b5; }
    .sharethis-wrapper .st_linkedin_custom:hover svg path {
      fill: #0077b5; }

.googleplus,
.googleplus-alt {
  transition: color .15s ease; }
  .googleplus svg path,
  .googleplus-alt svg path {
    transition: fill .15s ease; }

.googleplus {
  color: #dd4b39; }
  .googleplus svg path {
    fill: #dd4b39; }
  .googleplus:hover {
    color: #96004f; }
    .googleplus:hover svg path {
      fill: #96004f; }

.googleplus-alt {
  color: #96004f; }
  .googleplus-alt svg path {
    fill: #96004f; }
  .googleplus-alt:hover {
    color: #dd4b39; }
    .googleplus-alt:hover svg path {
      fill: #dd4b39; }

.sharethis-wrapper .st_googleplus_custom {
  color: #96004f; }
  .sharethis-wrapper .st_googleplus_custom svg path {
    fill: #96004f; }
  .sharethis-wrapper .st_googleplus_custom:hover {
    color: #dd4b39; }
    .sharethis-wrapper .st_googleplus_custom:hover svg path {
      fill: #dd4b39; }

.pinterest,
.pinterest-alt {
  transition: color .15s ease; }
  .pinterest svg path,
  .pinterest-alt svg path {
    transition: fill .15s ease; }

.pinterest {
  color: #cc2127; }
  .pinterest svg path {
    fill: #cc2127; }
  .pinterest:hover {
    color: #96004f; }
    .pinterest:hover svg path {
      fill: #96004f; }

.pinterest-alt {
  color: #96004f; }
  .pinterest-alt svg path {
    fill: #96004f; }
  .pinterest-alt:hover {
    color: #cc2127; }
    .pinterest-alt:hover svg path {
      fill: #cc2127; }

.sharethis-wrapper .st_pinterest_custom {
  color: #96004f; }
  .sharethis-wrapper .st_pinterest_custom svg path {
    fill: #96004f; }
  .sharethis-wrapper .st_pinterest_custom:hover {
    color: #cc2127; }
    .sharethis-wrapper .st_pinterest_custom:hover svg path {
      fill: #cc2127; }

.youtube,
.youtube-alt {
  transition: color .15s ease; }
  .youtube svg path,
  .youtube-alt svg path {
    transition: fill .15s ease; }

.youtube {
  color: #cd201f; }
  .youtube svg path {
    fill: #cd201f; }
  .youtube:hover {
    color: #96004f; }
    .youtube:hover svg path {
      fill: #96004f; }

.youtube-alt {
  color: #96004f; }
  .youtube-alt svg path {
    fill: #96004f; }
  .youtube-alt:hover {
    color: #cd201f; }
    .youtube-alt:hover svg path {
      fill: #cd201f; }

.sharethis-wrapper .st_youtube_custom {
  color: #96004f; }
  .sharethis-wrapper .st_youtube_custom svg path {
    fill: #96004f; }
  .sharethis-wrapper .st_youtube_custom:hover {
    color: #cd201f; }
    .sharethis-wrapper .st_youtube_custom:hover svg path {
      fill: #cd201f; }

.email,
.email-alt {
  transition: color .15s ease; }
  .email svg path,
  .email-alt svg path {
    transition: fill .15s ease; }

.email {
  color: #9b9b9b; }
  .email svg path {
    fill: #9b9b9b; }
  .email:hover {
    color: #96004f; }
    .email:hover svg path {
      fill: #96004f; }

.email-alt {
  color: #96004f; }
  .email-alt svg path {
    fill: #96004f; }
  .email-alt:hover {
    color: #9b9b9b; }
    .email-alt:hover svg path {
      fill: #9b9b9b; }

.sharethis-wrapper .st_email_custom {
  color: #96004f; }
  .sharethis-wrapper .st_email_custom svg path {
    fill: #96004f; }
  .sharethis-wrapper .st_email_custom:hover {
    color: #9b9b9b; }
    .sharethis-wrapper .st_email_custom:hover svg path {
      fill: #9b9b9b; }

.phone,
.phone-alt {
  transition: color .15s ease; }
  .phone svg path,
  .phone-alt svg path {
    transition: fill .15s ease; }

.phone {
  color: #A99A8D; }
  .phone svg path {
    fill: #A99A8D; }
  .phone:hover {
    color: #96004f; }
    .phone:hover svg path {
      fill: #96004f; }

.phone-alt {
  color: #96004f; }
  .phone-alt svg path {
    fill: #96004f; }
  .phone-alt:hover {
    color: #A99A8D; }
    .phone-alt:hover svg path {
      fill: #A99A8D; }

.sharethis-wrapper .st_phone_custom {
  color: #96004f; }
  .sharethis-wrapper .st_phone_custom svg path {
    fill: #96004f; }
  .sharethis-wrapper .st_phone_custom:hover {
    color: #A99A8D; }
    .sharethis-wrapper .st_phone_custom:hover svg path {
      fill: #A99A8D; }

.banner-wrapper .banner-image {
  position: relative;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  background-position-y: 151px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .banner-wrapper .banner-image:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 62.5%; }
  .banner-wrapper .banner-image > .banner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  @media screen and (max-width: 1024px) {
    .banner-wrapper .banner-image {
      background-position: top; } }
  @media screen and (max-width: 1023px) {
    .banner-wrapper .banner-image {
      background-attachment: scroll; } }
  .scrolled .banner-wrapper .banner-image {
    background-position-y: 81px; }

.banner-wrapper .banner-empty {
  height: 300px; }

@media screen and (max-width: 1023px) {
  .banner-wrapper .banner-image-wrapper {
    transform: translateY(0) !important; }
  .banner-wrapper .banner-empty {
    height: 0px; } }

.section-nieuws:not(.blog-detail) .banner-empty,
.section-expert-aan-het-woord:not(.case-detail) .banner-empty {
  height: 0px; }

.view-maps .field-content {
  position: relative; }
  .view-maps .field-content:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 62.5%; }
  .view-maps .field-content > .geolocation-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .view-maps .field-content .geolocation-map {
    height: 100% !important; }

.block.breadcrumbs {
  width: 1160px;
  max-width: 100%;
  margin: 0 auto; }
  @media screen and (min-width: 1480px) {
    .block.breadcrumbs {
      padding-left: 20px;
      padding-right: 20px; } }

.breadcrumbs a,
.breadcrumbs span {
  font-size: 0.75rem;
  color: #9b9b9b;
  text-decoration: none;
  font-weight: 400;
  opacity: .6; }

.breadcrumbs a.easy-breadcrumb_segment-title {
  color: #9b9b9b;
  opacity: 1; }

.easy-breadcrumb_segment-separator {
  padding: 0 5px; }

.front .breadcrumbs {
  display: none; }

.breadcrumbs {
  min-width: 0;
  padding: 7px 0;
  max-width: 100%;
  position: relative;
  z-index: 10;
  font-size: 12px;
  line-height: 17px; }
  .breadcrumbs:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none; }
  .breadcrumbs .easy-breadcrumb {
    max-width: 100%;
    margin: 0 auto;
    padding: 0; }
  @media screen and (max-width: 1023px) {
    .breadcrumbs .easy-breadcrumb {
      padding: 0 20px; } }
  @media screen and (max-width: 767px) {
    .breadcrumbs {
      margin-bottom: 0; } }

#sliding-popup.sliding-popup-bottom {
  background: rgba(0, 0, 0, 0.55) !important; }

#sliding-popup .popup-content {
  max-width: 1040px !important; }

#sliding-popup .popup-content #popup-text {
  margin: 10px 0 0 !important; }

#sliding-popup .popup-content #popup-buttons button {
  margin: 5px 0 !important; }

#sliding-popup .popup-content #popup-text p {
  padding: 0 0 0 10px !important;
  margin: 0 !important; }

@media screen and (max-width: 767px) {
  #sliding-popup .popup-content #popup-text {
    float: left !important;
    width: 75% !important; }
  #sliding-popup .popup-content #popup-buttons {
    float: right !important;
    width: 25% !important; }
  #sliding-popup .popup-content #popup-buttons button {
    width: 80px !important; } }

.flexslider {
  margin: 0px;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  overflow: hidden;
  width: 100%; }
  .flexslider ol,
  .flexslider ul {
    margin: 0;
    padding: 0; }
  .flexslider li {
    padding-left: 0;
    -webkit-backface-visibility: visible !important;
    position: relative; }
  .flexslider .slides .slide {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    .flexslider .slides .slide:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 46.55172%; }
    .flexslider .slides .slide > .slide-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @media screen and (max-width: 767px) {
      .flexslider .slides .slide {
        position: relative; }
        .flexslider .slides .slide:before {
          display: block;
          content: '';
          width: 100%;
          padding-top: 66.66667%; }
        .flexslider .slides .slide > .slide-inner {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
        .flexslider .slides .slide .slide-inner {
          position: static; } }
  .flexslider .flex-caption {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.875rem 2.8125rem 2.125rem;
    background: rgba(150, 0, 79, 0.7);
    text-align: left; }
    .flexslider .flex-caption .caption-text,
    .flexslider .flex-caption .caption-text p {
      line-height: 1.125;
      color: #fff;
      margin: 0;
      font-weight: 300;
      text-shadow: 0 1px 2px rgba(70, 146, 220, 0.3);
      font-size: 32px; }
      @media screen and (max-width: 767px) {
        .flexslider .flex-caption .caption-text,
        .flexslider .flex-caption .caption-text p {
          font-size: 26px; } }
    @media screen and (max-width: 767px) {
      .flexslider .flex-caption {
        position: static;
        padding: 1.25rem 1.5625rem 1.625rem;
        background: #96004f; }
        .flexslider .flex-caption .caption-text {
          font-size: 26 / 16rem; } }
  .flexslider .flex-control-nav {
    display: none;
    z-index: 99;
    bottom: 0; }
    .flexslider .flex-control-nav li {
      margin: 0 8px 0 0;
      display: block;
      float: left; }
      .flexslider .flex-control-nav li a {
        width: 9px;
        height: 9px;
        background: #ccc; }
        .flexslider .flex-control-nav li a.flex-active {
          background: #999; }
  .flexslider .flex-direction-nav a {
    color: #fff;
    top: 0;
    height: 100%;
    margin: 0;
    width: 60px; }
    .flexslider .flex-direction-nav a:before {
      top: 50%;
      position: absolute;
      margin-top: -32px; }
  .flexslider .flex-direction-nav .flex-next {
    right: 0; }
    .flexslider .flex-direction-nav .flex-next:before {
      right: 0; }
  .flexslider .flex-direction-nav .flex-prev {
    left: 0; }
    .flexslider .flex-direction-nav .flex-prev:before {
      left: 0; }
  .flexslider .bg-caption,
  .flexslider .color-caption {
    display: none; }

.flexslider__video {
  width: 300px;
  height: 300px;
  display: block;
  position: absolute;
  left: calc(50% - (300px / 2));
  top: calc(50% - (300px / 2));
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 300px; }

.flexslider__video-button {
  width: 75px;
  height: 75px;
  border-top-right-radius: 30%;
  position: absolute;
  left: calc(50% - (75px / 4));
  top: calc(50% - (75px / 2));
  background-color: #96004f;
  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866); }
  .flexslider__video-button::after, .flexslider__video-button::before {
    content: '';
    width: 75px;
    height: 75px;
    border-top-right-radius: 30%;
    position: absolute;
    background-color: inherit; }
  .flexslider__video-button::before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%); }
  .flexslider__video-button::after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%); }

@media screen and (max-width: 767px) {
  .flexslider__video {
    width: 150px;
    height: 150px;
    left: calc(50% - (150px / 2));
    top: 25%; }
  .flexslider__video-button {
    width: 37.5px;
    height: 37.5px;
    left: calc(50% - (37.5px / 4));
    top: calc(50% - (37.5px / 2)); }
    .flexslider__video-button::after, .flexslider__video-button::before {
      width: 37.5px;
      height: 37.5px; } }

html body.admin-menu,
html body.admin-menu .sidemenu {
  margin-top: 59px !important; }

html body.admin-menu .sidemenu--float {
  top: 59px !important; }

#user-login {
  max-width: 270px;
  margin: 50px auto;
  border: 0;
  padding: 40px;
  background: #DED9CC; }

#user-login .description {
  display: none; }

#user-login .form-submit {
  float: none;
  margin-bottom: 0;
  width: 100%; }

#user-login .form-item {
  margin: 0; }

.panels-flexible-region-inside {
  padding: 0 !important; }

.admin-tabs ul {
  padding: 0;
  margin: 0;
  border: none !important;
  position: fixed;
  top: 29px;
  left: 0;
  height: 30px;
  background: #333;
  width: 100%;
  z-index: 999; }

.not-logged-in.section-user {
  padding-top: 30px; }

.not-logged-in .admin-tabs ul {
  top: 0; }

#admin-menu-icon a.active-trail {
  background: none !important;
  border-radius: 0 !important; }

#admin-menu-icon a:hover {
  background-color: #333 !important; }

.admin-tabs .tabs {
  border: none; }

.admin-tabs ul li a {
  height: 22px !important;
  line-height: 19px;
  color: #fff;
  padding: 2px 10px 3px;
  letter-spacing: 0;
  font-size: 13px;
  margin: 4px 6px;
  border-radius: 10px !important; }

.admin-tabs ul li a:hover {
  text-decoration: none;
  border-radius: 10px; }

.admin-tabs ul li a:active,
.admin-tabs ul li a:focus {
  color: #fff;
  font-weight: normal;
  border-radius: 10px; }

#admin-menu {
  background: #55a514;
  box-shadow: none; }

#admin-menu li.admin-menu-account > a {
  background: none; }

#admin-menu-wrapper {
  padding: 5px 10px 0; }

#admin-menu > div > .dropdown > li > a {
  border-radius: 0 !important;
  margin: 0;
  padding: 2px 10px 7px; }

#admin-menu .dropdown .admin-menu-toolbar-category > a.active-trail {
  background: #61bc17; }

#edit-body-format,
.ckeditor_links {
  display: none; }

#admin-menu .dropdown .admin-menu-icon > a {
  padding: 2px 10px 8px; }

.admin-tabs .tabs-primary,
.admin-tabs .tabs-secondary {
  background-image: none; }

.admin-tabs .tabs-primary__tab,
.admin-tabs .tabs-primary__tab.is-active {
  border-radius: 0;
  text-shadow: none;
  border: none; }

.admin-tabs a.tabs-primary__tab-link.is-active,
.admin-tabs a.tabs-primary__tab-link:active {
  border-radius: 0;
  background-image: none;
  border: none; }

.admin-tab a.tabs-primary__tab-link.is-active,
.admin-tabs a.tabs-primary__tab-link {
  background: none;
  border: none;
  color: #fafafa;
  letter-spacing: 0; }

.admin-tabs .tabs-primary__tab.is-active a,
.admin-tabs ul li a:hover {
  background: #999 !important;
  padding: 2px 10px 3px;
  border-radius: 10px; }

.admin-tabs h2 {
  display: none; }

#admin-menu .dropdown li li a[href='/admin/structure/block/list/seven_override'] {
  display: none; }

#admin-menu .dropdown .admin-menu-icon > a span,
#admin-menu .dropdown .admin-menu-icon > a span {
  background: url("/sites/all/themes/front/images/react-logo.png");
  height: 14px;
  width: 14px; }

#admin-menu li.admin-menu-account > a {
  background: none; }

#admin-menu * {
  font: 12px 'Lucida Grande', 'Lucida Sans Unicode', sans-serif; }

.imce-frame {
  margin-top: 50px; }

.overview .item,
.shortview .item,
.detail .item,
.widget .item {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  background: transparent;
  padding: 20px; }
  @media screen and (min-width: 768px) {
    .overview .item.three.columns:nth-child(2n+1),
    .shortview .item.three.columns:nth-child(2n+1),
    .detail .item.three.columns:nth-child(2n+1),
    .widget .item.three.columns:nth-child(2n+1) {
      clear: left; } }
  @media screen and (min-width: 1024px) {
    .overview .item.three.columns:nth-child(2n+1),
    .shortview .item.three.columns:nth-child(2n+1),
    .detail .item.three.columns:nth-child(2n+1),
    .widget .item.three.columns:nth-child(2n+1) {
      clear: none; }
    .overview .item.three.columns:nth-child(4n+1),
    .shortview .item.three.columns:nth-child(4n+1),
    .detail .item.three.columns:nth-child(4n+1),
    .widget .item.three.columns:nth-child(4n+1) {
      clear: left; } }
  @media screen and (max-width: 767px) {
    .overview .item,
    .shortview .item,
    .detail .item,
    .widget .item {
      padding: 0 0 40px; }
      .overview .item .title,
      .overview .item .body,
      .overview .item .type,
      .overview .item .date,
      .overview .item .expert-type,
      .overview .item .author,
      .shortview .item .title,
      .shortview .item .body,
      .shortview .item .type,
      .shortview .item .date,
      .shortview .item .expert-type,
      .shortview .item .author,
      .detail .item .title,
      .detail .item .body,
      .detail .item .type,
      .detail .item .date,
      .detail .item .expert-type,
      .detail .item .author,
      .widget .item .title,
      .widget .item .body,
      .widget .item .type,
      .widget .item .date,
      .widget .item .expert-type,
      .widget .item .author {
        padding: 0 20px; }
      .overview .item .type,
      .shortview .item .type,
      .detail .item .type,
      .widget .item .type {
        padding-right: 0; }
      .overview .item .date,
      .shortview .item .date,
      .detail .item .date,
      .widget .item .date {
        padding-left: 0; } }
  .overview .item .thumb,
  .overview .item .title,
  .overview .item .body p,
  .overview .item .body span,
  .overview .item .type,
  .overview .item .date,
  .overview .item .expert-type,
  .overview .item .author,
  .shortview .item .thumb,
  .shortview .item .title,
  .shortview .item .body p,
  .shortview .item .body span,
  .shortview .item .type,
  .shortview .item .date,
  .shortview .item .expert-type,
  .shortview .item .author,
  .detail .item .thumb,
  .detail .item .title,
  .detail .item .body p,
  .detail .item .body span,
  .detail .item .type,
  .detail .item .date,
  .detail .item .expert-type,
  .detail .item .author,
  .widget .item .thumb,
  .widget .item .title,
  .widget .item .body p,
  .widget .item .body span,
  .widget .item .type,
  .widget .item .date,
  .widget .item .expert-type,
  .widget .item .author {
    transition-property: color;
    transition-duration: 0.3s;
    transition-timing-function: ease; }
  .overview .item .thumb,
  .overview .item .title,
  .overview .item .body,
  .shortview .item .thumb,
  .shortview .item .title,
  .shortview .item .body,
  .detail .item .thumb,
  .detail .item .title,
  .detail .item .body,
  .widget .item .thumb,
  .widget .item .title,
  .widget .item .body {
    display: block; }
  .overview .item .type,
  .overview .item .date,
  .shortview .item .type,
  .shortview .item .date,
  .detail .item .type,
  .detail .item .date,
  .widget .item .type,
  .widget .item .date {
    display: inline-block; }
  .overview .item .thumb,
  .shortview .item .thumb,
  .detail .item .thumb,
  .widget .item .thumb {
    margin-bottom: 15px; }
  .overview .item .thumb img,
  .shortview .item .thumb img,
  .detail .item .thumb img,
  .widget .item .thumb img {
    display: block;
    width: 100%; }
  .overview .item .type,
  .shortview .item .type,
  .detail .item .type,
  .widget .item .type {
    font-size: 12px;
    line-height: 25px;
    color: #96004f; }
  .overview .item .title,
  .shortview .item .title,
  .detail .item .title,
  .widget .item .title {
    font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
    color: #96004f;
    padding-bottom: 10px; }
  .overview .item .date,
  .shortview .item .date,
  .detail .item .date,
  .widget .item .date {
    font-size: 12px;
    line-height: 25px;
    color: #96004f;
    padding-bottom: 15px;
    font-style: normal; }
    .overview .item .date em,
    .shortview .item .date em,
    .detail .item .date em,
    .widget .item .date em {
      font-style: normal; }
    .overview .item .date:before,
    .shortview .item .date:before,
    .detail .item .date:before,
    .widget .item .date:before {
      content: " - "; }
  .overview .item a,
  .shortview .item a,
  .detail .item a,
  .widget .item a {
    text-decoration: none; }

@media screen and (max-width: 1023px) {
  .overview .item .title,
  .shortview .item .title,
  .detail .item .title,
  .widget .item .title {
    font-size: 17.6px;
    line-height: 20.8px; } }

.shortview .item {
  display: inline-block;
  float: left; }

.blog-detail-content > .row {
  margin-top: 5.625rem;
  margin-bottom: 5.625rem; }
  @media screen and (max-width: 767px) {
    .blog-detail-content > .row {
      margin-top: 1.875rem;
      margin-bottom: 1.875rem; } }

.detail .item {
  padding: 0 5rem; }
  .detail .item .thumb,
  .detail .item .title,
  .detail .item .date,
  .detail .item .body,
  .detail .item .author {
    display: block; }
  .detail .item .title {
    font-size: 40px;
    line-height: 45px; }
  .detail .item .date:before {
    content: ''; }
  .detail .item .author {
    padding-top: 40px;
    color: #96004f;
    font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700; }

@media screen and (max-width: 1023px) {
  .detail .item {
    padding: 0; } }

@media screen and (max-width: 767px) {
  .detail .item .title {
    font-size: 30px;
    line-height: 34px; } }

.agenda-overzicht .item {
  padding: 20px 0 0;
  margin: 20px 0 0;
  border-top: 1px solid #EFEFEF; }
  .agenda-overzicht .item h2 {
    margin-top: 0; }

.agenda-detail .datum,
.agenda-overzicht .datum {
  margin: 0 0 10px; }

.agenda-detail .datum .datum-label,
.agenda-overzicht .datum .datum-label {
  font-weight: bold; }

.agenda-widget .widget-item {
  margin: 0 0 20px; }

.agenda-widget .agenda-title {
  font-weight: bold; }

/*================================================
=== BLOG --=======================================
================================================*/
.news-overview {
  margin-top: 80px; }
  .news-overview .view-content {
    margin: 0 -20px; }
  @media screen and (max-width: 1200px) {
    .news-overview {
      padding: 0 20px; } }
  @media screen and (max-width: 767px) {
    .news-overview {
      padding: 0; } }

@media screen and (max-width: 767px) {
  .front .news-shortview .news-item {
    display: none; }
    .front .news-shortview .news-item:first-child {
      display: block; } }

/* Blog overview */
.news-item:before {
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  color: #96004f;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  font-size: 30px;
  opacity: 0; }

.news-item .thumb img {
  mix-blend-mode: multiply;
  transition-property: filter;
  transition-duration: 0.3s;
  transition-timing-function: ease; }

.news-item .body {
  color: #231F20; }

@media screen and (max-width: 1023px) {
  .news-item.three.columns {
    width: 50% !important; } }

@media screen and (max-width: 767px) {
  .news-item.three.columns {
    width: 100% !important; } }

@media screen and (min-width: 1024px) {
  .news-item:hover {
    background: #4692DC; }
    .news-item:hover:before {
      opacity: 1; }
    .news-item:hover a {
      text-decoration: none; }
    .news-item:hover .type,
    .news-item:hover .date,
    .news-item:hover .title,
    .news-item:hover .body,
    .news-item:hover .body p,
    .news-item:hover .body span {
      color: #fff !important; }
    .news-item:hover .thumb img {
      filter: grayscale(100%) contrast(200%); }
      .is-ie .news-item:hover .thumb img {
        opacity: .5; } }

/* Blog Detail */
.blog-detail-content .news-shortview {
  margin: 0 -40px; }
  @media screen and (max-width: 1200px) {
    .blog-detail-content .news-shortview {
      margin: 0 -20px; } }

@media screen and (max-width: 1023px) {
  .news-overview .view-content {
    margin: 0; }
  .news-item {
    width: 33.3333% !important; }
    .front .news-item {
      width: 50% !important; }
  .blog-detail-content .news-shortview {
    margin: 0 -20px; } }

@media screen and (max-width: 767px) {
  .news-item {
    width: 100% !important; }
    .front .news-item {
      width: 100% !important; }
      .front .news-item:last-child {
        display: none; }
      .front .news-item .thumb img {
        width: 100%; } }

.case-overview {
  margin-top: 80px; }

.case-shortview .expert-type {
  font-size: 0.9375rem;
  color: #fff;
  font-weight: 400; }

.case-shortview .body p {
  font-size: 32px;
  line-height: 1.125;
  font-weight: 700;
  color: #fff;
  padding: 1.875rem 0 1.25rem; }

.case-shortview .author {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 400; }
  .case-shortview .author:before {
    content: "- "; }

.case-shortview .caption {
  padding: 3rem 2.5rem; }

.case-shortview .flexslider {
  padding: 0 20px; }
  .case-shortview .flexslider .slides {
    background: #A99A8D; }

.case-shortview .flex-control-nav {
  display: block;
  width: auto;
  bottom: auto;
  top: 1.25rem;
  left: 2.5rem; }
  .case-shortview .flex-control-nav li a {
    background: #fff;
    width: 20px;
    height: 20px;
    font-size: 0px; }
    .case-shortview .flex-control-nav li a.flex-active {
      background: #96004f; }

@media screen and (max-width: 767px) {
  .case-shortview .expert-type {
    font-size: 0.875rem; }
  .case-shortview .body p {
    font-size: 26px;
    padding: 0.625rem 0 0.625rem; }
  .case-shortview .author {
    font-size: 1rem; }
  .case-shortview .caption {
    padding: 1.25rem 1.625rem; }
  .case-shortview .flexslider {
    padding: 0; }
  .case-shortview .flex-control-nav {
    left: 1.25rem; } }

.flexslider .slides .case-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: stretch; }
  .flexslider .slides .case-item-inner img {
    flex: 1 0 66.66667%;
    width: 66.66667%; }
  @media screen and (max-width: 767px) {
    .flexslider .slides .case-item-inner {
      flex-direction: column; }
      .flexslider .slides .case-item-inner img {
        flex: 1 1 100%;
        width: 100%; } }

/* Blog overview met afbeelding*/
.case-detail .expert-type {
  color: #96004f;
  font-size: 13px;
  line-height: 16px;
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  padding-bottom: 10px; }

.vacature-overzicht > .row {
  margin-top: 5.625rem;
  margin-bottom: 5.625rem; }
  .vacature-overzicht > .row > .columns:first-child {
    padding-left: 0; }
  .vacature-overzicht > .row > .columns:last-child {
    padding-right: 0; }
  @media screen and (max-width: 767px) {
    .vacature-overzicht > .row {
      margin-top: 1.875rem;
      margin-bottom: 1.875rem; }
      .vacature-overzicht > .row > .columns {
        padding: 0 20px !important; } }
  .vacature-overzicht > .row .titel {
    color: #96004f;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0.625rem; }
    .vacature-overzicht > .row .titel .alt {
      color: #231F20; }
  .vacature-overzicht > .row .vanRijsingengreen .titel {
    color: #076c2b; }
  .vacature-overzicht > .row .vanRijsingensource .titel {
    color: #43b649; }
  .vacature-overzicht > .row .vanRijsingeningredients .titel {
    color: #f6891f; }
  .vacature-overzicht > .row .vanRijsingenfreshservice .titel {
    color: #96004f; }
  .vacature-overzicht > .row .vanRijsingencarrotconcepts .titel {
    color: #f15f22; }
  .vacature-overzicht > .row .ondertitel {
    margin-bottom: 0.625rem;
    color: #A99A8D;
    font-size: 0.875rem; }
  .vacature-overzicht > .row .content-wrap {
    padding: 40px 20px;
    position: relative;
    border-bottom: 1px solid #9b9b9b; }
    .vacature-overzicht > .row .content-wrap::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: -1px;
      right: -20px;
      left: -20px;
      transition: background-color .15s ease; }
    .vacature-overzicht > .row .content-wrap::after {
      content: '';
      position: absolute;
      top: 40px;
      right: 20px;
      background: url("/sites/all/themes/front/images/svg/arrow.svg") center/cover no-repeat;
      width: 1.875rem;
      height: 1.875rem; }
      @media screen and (max-width: 767px) {
        .vacature-overzicht > .row .content-wrap::after {
          top: 0.625rem;
          right: -0.625rem; } }
    .vacature-overzicht > .row .content-wrap .titel,
    .vacature-overzicht > .row .content-wrap .ondertitel,
    .vacature-overzicht > .row .content-wrap p,
    .vacature-overzicht > .row .content-wrap span {
      transition: color .15s ease; }
    .vacature-overzicht > .row .content-wrap .columns {
      padding: 0; }
    .vacature-overzicht > .row .content-wrap:hover::before {
      background-color: #4692DC; }
    .vacature-overzicht > .row .content-wrap:hover * {
      color: #fff; }
    .vacature-overzicht > .row .content-wrap:hover .company-logo {
      opacity: 0; }

.node-type-vacatures .pane-node-body,
.node-type-vacatures .pane-sharethis {
  width: 1160px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px; }

.node-type-vacatures .pane-node-body {
  /*columns: 2;
    column-gap: $column-gutter;*/
  margin-top: 5.625rem; }
  @media screen and (max-width: 767px) {
    .node-type-vacatures .pane-node-body {
      columns: 1;
      margin-top: 1.875rem; } }
  .node-type-vacatures .pane-node-body h2:first-child {
    margin-top: 0; }

.node-type-vacatures .pane-sharethis {
  margin: 2.1875rem 0; }

.company-logo,
.company-logo-large {
  position: relative;
  transition: opacity .15s ease;
  max-width: 10rem;
  margin: 0 auto;
  mix-blend-mode: multiply;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .company-logo:before,
  .company-logo-large:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%; }
  .company-logo > .content,
  .company-logo-large > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .vanRijsingengreen .company-logo, .vanRijsingengreen
  .company-logo-large {
    background-image: url("/sites/all/themes/front/images/logos/icon-vr-green@3x.png"); }
  .vanRijsingensource .company-logo, .vanRijsingensource
  .company-logo-large {
    background-image: url("/sites/all/themes/front/images/logos/icon-vr-source@3x.png"); }
  .vanRijsingeningredients .company-logo, .vanRijsingeningredients
  .company-logo-large {
    background-image: url("/sites/all/themes/front/images/logos/icon-vr-ingredients@3x.png"); }
  .vanRijsingenfreshservice .company-logo, .vanRijsingenfreshservice
  .company-logo-large {
    background-image: url("/sites/all/themes/front/images/logos/icon-vr-freshservice@3x.png"); }
  .vanRijsingencarrotconcepts .company-logo, .vanRijsingencarrotconcepts
  .company-logo-large {
    background-image: url("/sites/all/themes/front/images/logos/icon-vr-carrotconcepts@3x.png"); }

.layout--full-half .sidebar .pane-block {
  background-color: #A99A8D;
  padding: 3.125rem 3.75rem 3.75rem;
  margin-bottom: 3.75rem; }
  @media screen and (max-width: 767px) {
    .layout--full-half .sidebar .pane-block {
      padding: 1.5625rem 1.875rem 1.875rem; } }
  .layout--full-half .sidebar .pane-block h1, .layout--full-half .sidebar .pane-block h2, .layout--full-half .sidebar .pane-block h3, .layout--full-half .sidebar .pane-block h4, .layout--full-half .sidebar .pane-block h5, .layout--full-half .sidebar .pane-block h6 {
    color: #fff;
    margin-top: 0; }
  .layout--full-half .sidebar .pane-block p,
  .layout--full-half .sidebar .pane-block a:not(.btn),
  .layout--full-half .sidebar .pane-block a:not(.btn):hover {
    color: #fff; }
  .layout--full-half .sidebar .pane-block p {
    font-size: 1rem;
    line-height: 1.5; }
  .layout--full-half .sidebar .pane-block .bef-select-as-links > .form-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; }
    .layout--full-half .sidebar .pane-block .bef-select-as-links > .form-item > .form-item {
      font-size: 1.25rem;
      white-space: nowrap; }
      .layout--full-half .sidebar .pane-block .bef-select-as-links > .form-item > .form-item::before {
        content: '';
        background: url("/sites/all/themes/front/images/svg/arrow.svg") center/cover no-repeat;
        width: 1.875rem;
        height: 1.875rem;
        display: inline-block;
        vertical-align: top; }
      .layout--full-half .sidebar .pane-block .bef-select-as-links > .form-item > .form-item a {
        display: inline-block;
        white-space: normal;
        transition: transform .15s ease; }
        .layout--full-half .sidebar .pane-block .bef-select-as-links > .form-item > .form-item a.active {
          font-weight: 700; }
      .layout--full-half .sidebar .pane-block .bef-select-as-links > .form-item > .form-item:hover a {
        transform: translateX(0.625rem); }
    .layout--full-half .sidebar .pane-block .bef-select-as-links > .form-item [id$='-all'] {
      order: 100;
      opacity: .5; }
      .layout--full-half .sidebar .pane-block .bef-select-as-links > .form-item [id$='-all'] a.active {
        font-weight: 300; }
  .layout--full-half .sidebar .pane-block form {
    margin: 0;
    padding: 0; }
  .layout--full-half .sidebar .pane-block .views-exposed-widgets {
    margin: 0; }
  .layout--full-half .sidebar .pane-block .views-exposed-widget {
    float: none;
    padding: 0; }
  .layout--full-half .sidebar .pane-block .infowrap,
  .layout--full-half .sidebar .pane-block .infoblok {
    float: none; }
  .layout--full-half .sidebar .pane-block .infoblok p {
    margin-bottom: 0.9375rem; }
    .layout--full-half .sidebar .pane-block .infoblok p:last-child {
      margin-bottom: 0; }
  .layout--full-half .sidebar .pane-block .infoblok h2 {
    line-height: 1.125;
    margin-bottom: 0.9375rem; }

.layout--full-half .sidebar .pane-views-exp-vacatures-block {
  background-color: #076c2b;
  padding: 0; }
  .layout--full-half .sidebar .pane-views-exp-vacatures-block .infoblok {
    padding: 3.125rem 3.75rem 3.75rem; }
  .layout--full-half .sidebar .pane-views-exp-vacatures-block a.infowrap .infoblok:hover {
    background-color: #4692DC !important; }

.vacature-overzicht .layout--full-half .sidebar .pane-block {
  background: #96004f;
  padding: 0; }
  .vacature-overzicht .layout--full-half .sidebar .pane-block .entity.infoblok {
    padding: 3.125rem 3.75rem 3.75rem; }
  .vacature-overzicht .layout--full-half .sidebar .pane-block a.infowrap .infoblok:hover {
    background-color: #4692DC !important; }

.node-type-vacatures main.content {
  padding: 0 20px; }

.node-type-vacatures .panel-pane.pane-sharethis {
  margin: 2.2rem 8.2rem;
  width: auto; }
  .node-type-vacatures .panel-pane.pane-sharethis .pane-title {
    text-transform: none; }
  @media screen and (max-width: 767px) {
    .node-type-vacatures .panel-pane.pane-sharethis {
      margin: 2.2rem 1rem; } }

.paragraphs-item-paragraphs-form {
  margin: 2.2rem 7.2rem;
  padding: 2rem; }
  @media screen and (max-width: 767px) {
    .paragraphs-item-paragraphs-form {
      margin: 2.2rem 1rem; } }
  .paragraphs-item-paragraphs-form.color-inverted label {
    color: #fff; }
  .paragraphs-item-paragraphs-form.color-inverted .form-submit,
  .paragraphs-item-paragraphs-form.color-inverted .webform-component-file .btn {
    background-color: #fff;
    border: solid 1px transparent; }
  .paragraphs-item-paragraphs-form.color-inverted h2, .paragraphs-item-paragraphs-form.color-inverted h3, .paragraphs-item-paragraphs-form.color-inverted h4, .paragraphs-item-paragraphs-form.color-inverted h5, .paragraphs-item-paragraphs-form.color-inverted h6 {
    color: #fff; }
  .paragraphs-item-paragraphs-form .field-name-field-kleur {
    display: none; }
  .paragraphs-item-paragraphs-form.color-inverted .form-required {
    color: #fff; }
  .paragraphs-item-paragraphs-form .form-submit:hover {
    background-color: #ffffff !important;
    opacity: 0.8; }

/*
//================================================
//=== VACATURES ==================================
//================================================

body.section-plaats,
body.section-vacatures,
body.section-werkveld {
  .container.content {
    //background:#e9f7ff;
  }

  .content-top {
    //padding-top: 20px;
  }
}

.vacature-overzicht {
  .sidebar {
    margin-top: 20px;
  }

  .blog-item {
    .meta {
      a {
        color: $black;
      }
    }

    .lees-meer {
      margin-top: 20px;
    }

    .content-wrap {
      width: 100%;
    }
  }

  .block {
    padding: 40px;
    background: $white;
    margin: 0 0 30px;
  }

}

.vacature-homepage {
  .blog-item {
    .meta {
      a {
        color: $black;
      }
    }

    .lees-meer {
      margin-top: 20px;
    }

    .content-wrap {
      width: 100%;
    }
  }
}

// OVERVIEW PAGINA
.vacature-detail {
  @include breakpoint(large) {
    .content {
      .first,
      .last {
        width: 50% !important;
      }
    }
  }

  @include breakpoint(medium) {
    .content {
      .first,
      .last {
        width: 100% !important;
      }
    }
  }

  .sidebar {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 20px;
      line-height: 20px;
      margin-top: 0;
    }
  }

  .meta-info {
    font-weight: bold;
    color: $black;
  }

  form {
    margin: 0;

  }

  .persoon.info {
    background: #efefef;
    padding: 30px;
    float: left;
    width: 100%;
    margin: 0;

    .view-content {
      margin: 10px 0 0;
      //border-top: 1px solid #D3E2EF;
      padding: 0 0 30px;
      //border-bottom: 1px solid rgb(210, 210, 210);
    }

    .view-header {

      padding: 0;

      p {
        font-size: inherit;
        font-weight: inherit;
      }
    }

    .titel {
      font-weight: bold;
      color: $black;
    }
  }

  .koppeling-persoon {
    padding: 30px 30px 0;
    float: left;
    width: 100%;
    background: #efefef;

  }

  .field-name-field-tekst {
    background: #565656;
    padding: 30px;
    float: left;
    width: 100%;
    margin: 0 0 30px;

    ol,
    ul {
      margin-bottom: 0;
    }

    * {
      color: $white;
    }
  }

  .field-name-field-webformulier-invoegen {
    background: #efefef;
    padding: 0 30px 30px;
    float: left;
    width: 100%;
    margin: 0;
  }

  .container.content {
    background: $white!important;

    h1 {
      margin-top: 0;
    }
  }

  .content-top {
    padding-top: 0;
  }

  .meta {
    background: #efefef;
    padding: 15px;
    color: $white;
    float: left;

    div {
      color: $white;
      float: left;
      width: 100%;
      font-weight: bold;
      margin-bottom: 3px;

      .views-label {
        float: left;
        margin-right: 3px;
      }

      .field-content {
        float: left;
        width: auto;
      }
    }

    .views-field-title {
      span {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }

    .views-field-field-evt-extra-opmerking {
      div {
        font-weight: normal;
      }
    }

  }

  .event-foto {
    float: left;
    width: 100%;
    margin-bottom: 40px;

    img {
      float: left;
      width: 100%;
    }
  }
}*/
.vragen-overzicht .vraag {
  font-weight: bold; }

.vragen-overzicht .views-field-body p {
  color: #444; }

.vragen-overzicht .ui-accordion-content {
  height: auto !important;
  background: #fff;
  border: 0;
  padding: 10px 10px 10px 15px; }

.vragen-overzicht .ui-accordion-header {
  background: none; }

.vragen-overzicht .ui-icon-triangle-1-e {
  background-position: -37px -15px; }

.vragen-overzicht .ui-icon-triangle-1-s {
  background-position: -68px -15px; }

.vragen-overzicht .ui-accordion .ui-accordion-icons {
  padding-left: 15px; }

.vragen-overzicht .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  top: 47% !important;
  left: 0; }

.vragen-overzicht .ui-state-default,
.vragen-overzicht .ui-widget-content .ui-state-default,
.vragen-overzicht .ui-widget-header .ui-state-default {
  border: 0; }

/*OVERVIEW PAGINA*/
.fotoalbum-overview {
  padding: 0 80px;
  padding-top: 30px;
  float: left;
  width: 100%; }
  .fotoalbum-overview ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .fotoalbum-overview ul li {
      padding: 0 15px;
      float: left;
      margin-bottom: 30px;
      width: 25%;
      list-style: none; }
      .fotoalbum-overview ul li a {
        overflow: hidden; }
      .fotoalbum-overview ul li img {
        width: 100%;
        transition: all 0.4s ease; }
      .fotoalbum-overview ul li:hover img {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1); }

@media screen and (max-width: 1023px) {
  .fotoalbum-overview ul li,
  .fotoalbum-sectie ul li {
    width: 50%; } }

@media screen and (max-width: 767px) {
  .fotoalbum-sectie ul li:nth-child(odd) {
    padding-left: 0px !important; }
  .fotoalbum-sectie ul li:nth-child(even) {
    padding-right: 0px !important; }
  .sectie-item .fotoalbum .content {
    width: 100% !important; }
  .sectie-item .formulier .content {
    width: 100% !important; }
  .sectie-item .webform-component-fieldset {
    width: 100% !important; }
  .sectie-item .view-fotoalbum {
    width: 100%; } }

.person {
  text-align: center;
  margin-bottom: 2.5rem; }
  .person.three.columns:nth-child(4n+1) {
    clear: left; }

.person--photo {
  display: block;
  margin-bottom: 1.5rem; }
  .person--photo img {
    display: block;
    margin: 0 auto;
    border-radius: 50%; }

.person--first-name,
.person--last-name {
  font-weight: 700;
  color: #96004f;
  font-size: 1rem; }

.person--function {
  font-size: 1.125rem;
  margin-bottom: 0.375rem; }

.products-overview {
  max-width: 1160px;
  margin: 0 auto; }
  .products-overview > .view-content > .row {
    margin: 0 -20px;
    max-width: none;
    width: auto; }
    @media screen and (min-width: 1480px) {
      .products-overview > .view-content > .row {
        margin: 0 auto; } }
  .products-overview .product {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 1.25rem;
    transition-property: color, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease; }
    .products-overview .product img {
      transition-property: filter, opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      display: block;
      margin: 0 auto;
      mix-blend-mode: multiply; }
    .products-overview .product h4 {
      transition-property: color, background-color;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      color: #231F20;
      margin: 0;
      padding: 1rem 1.25rem 1.25rem;
      background-color: #DED9CC; }
    .products-overview .product a {
      display: block;
      transition-property: color, background-color;
      transition-duration: 0.3s;
      transition-timing-function: ease; }
    @media screen and (max-width: 1023px) {
      .products-overview .product.three.columns {
        width: 50% !important; } }
    @media screen and (max-width: 767px) {
      .products-overview .product.three.columns {
        width: 100% !important; } }
    @media screen and (min-width: 1024px) {
      .products-overview .product:hover {
        background-color: #4692DC; }
        .products-overview .product:hover img {
          filter: grayscale(100%) contrast(200%);
          opacity: .9; }
          .is-ie .products-overview .product:hover img {
            opacity: .5; }
        .products-overview .product:hover h4 {
          background-color: #4692DC;
          color: #fff; } }

@media screen and (min-width: 768px) {
  .producten-detail .field-name-body .field-item {
    columns: 2;
    column-gap: 2.5rem; } }

.paragraphs-item-paragraph-specificatie {
  margin: 0.625rem 0; }
  .paragraphs-item-paragraph-specificatie .six.columns:first-child {
    padding-right: 20px; }
  .paragraphs-item-paragraph-specificatie .six.columns:last-child {
    padding-left: 20px; }
  .paragraphs-item-paragraph-specificatie .spec-title {
    background-color: #A99A8D;
    padding: 1.5rem 3.75rem 1.25rem;
    cursor: pointer; }
    .paragraphs-item-paragraph-specificatie .spec-title .field {
      display: inline-block;
      max-width: calc(100% - 32px); }
    .paragraphs-item-paragraph-specificatie .spec-title h3 {
      color: #fff;
      font-size: 22px;
      margin: 0;
      transition: color .15s ease; }
    .paragraphs-item-paragraph-specificatie .spec-title .arrow {
      float: right;
      transform: rotate(0);
      transition: transform .15s ease; }
      .paragraphs-item-paragraph-specificatie .spec-title .arrow * {
        transition: fill .15s ease; }
    @media screen and (max-width: 767px) {
      .paragraphs-item-paragraph-specificatie .spec-title {
        padding: 1rem 1.875rem 1.125rem; } }
    @media screen and (min-width: 768px) {
      .paragraphs-item-paragraph-specificatie .spec-title:hover h3 {
        color: #DED9CC; }
      .paragraphs-item-paragraph-specificatie .spec-title:hover .arrow * {
        fill: #DED9CC; } }
  .paragraphs-item-paragraph-specificatie .spec-body {
    background-color: #DED9CC;
    padding: 1.75rem 3.75rem 3rem; }
    @media screen and (max-width: 767px) {
      .paragraphs-item-paragraph-specificatie .spec-body {
        padding: 1.25rem 1.875rem 2.125rem; } }
  .paragraphs-item-paragraph-specificatie.open .arrow {
    transform: rotate(90deg); }

@media screen and (max-width: 767px) {
  .pane-node-body,
  .pane-sharethis {
    padding-left: 20px;
    padding-right: 20px; } }

.search-row {
  width: 100%; }
  .search-row .columns {
    padding: 0; }

.no-padding {
  padding: 0; }
  @media screen and (max-width: 1200px) {
    .no-padding {
      padding: 0 20px; } }

.section-zoeken .no-padding {
  padding-top: 100px; }

#search-api-page-search-form label {
  display: none; }

#search-api-page-search-form .form-item,
#search-api-page-search-form .form-submit {
  display: inline-block; }

#search-api-page-search-form .form-item {
  width: 80%; }

#search-api-page-search-form .form-submit {
  width: calc(20% - 30px);
  margin-left: 30px;
  padding: 8px 25px;
  float: right;
  transform: translateY(14px); }

@media screen and (max-width: 900px) {
  #search-api-page-search-form .form-item {
    width: 70%; }
  #search-api-page-search-form .form-submit {
    width: calc(30% - 30px);
    padding: 8px 15px; } }

@media screen and (max-width: 500px) {
  #search-api-page-search-form .form-item {
    width: 60%; }
  #search-api-page-search-form .form-submit {
    width: calc(40% - 15px);
    margin-left: 15px; } }

.search-results {
  margin: 0 !important;
  list-style: none !important; }

.search-info {
  display: none; }

.search-result-title .columns {
  padding: 0 !important; }
  @media screen and (max-width: 1200px) {
    .search-result-title .columns {
      padding: 0 20px !important; } }

.search-result {
  display: block;
  padding: 40px 0 !important;
  background: #DED9CC;
  transition: background .15s ease, color .15s ease; }
  .search-result:hover {
    background: #4692DC; }
    .search-result:hover,
    .search-result:hover * {
      color: #fff !important; }
  .search-result .search-inner {
    width: 960px;
    margin: 0 auto;
    max-width: 100%; }
    @media screen and (max-width: 1200px) {
      .search-result .search-inner {
        padding: 0 20px !important; } }
    @media screen and (max-width: 1100px) {
      .search-result .search-inner {
        padding: 0 !important; } }
    .search-result .search-inner .title a {
      color: #96004f;
      font-size: 22px;
      line-height: 28px;
      font-weight: 700;
      font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
      text-decoration: none; }
    .search-result .search-inner .search-snippet-info .search-snippet {
      color: #444444; }
  @media screen and (max-width: 1200px) {
    .search-result {
      padding: 40px 20px !important; } }

@media screen and (max-width: 1023px) {
  .section-zoeken .no-padding {
    padding: 50px 20px 0; }
  .search-result {
    padding: 40px 20px !important; } }

.search-results .search-snippet-info {
  padding-left: 0; }

.search-api-page-results {
  float: left;
  width: 100%; }

.search-wrapper hr {
  border: 2px solid #f0f0f0; }

.search-wrapper,
.search-no-result {
  width: 960px;
  margin: 0 auto;
  max-width: 100%; }

@media screen and (max-width: 1200px) {
  .search-no-result {
    padding: 0 20px !important; } }

.tweets .tweets-item {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee; }
  .tweets .tweets-item .datum {
    color: #999;
    font-size: 11px;
    display: block; }
  .tweets .tweets-item .content a {
    text-decoration: underline; }

.webform-progressbar {
  margin-left: 17px;
  margin-bottom: 20px;
  margin-top: 30px;
  display: inline-block; }
  .webform-progressbar .webform-progressbar-inner {
    border-color: #2b2b2b;
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-page {
    border: 1px solid #2b2b2b; }
  .webform-progressbar .webform-progressbar-page.current {
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-page.completed {
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-outer {
    border: 1px solid #2b2b2b; }

.webform-client-form input[type=date],
.webform-client-form input[type=datetime],
.webform-client-form input[type=email],
.webform-client-form input[type=number],
.webform-client-form input[type=password],
.webform-client-form input[type=search],
.webform-client-form input[type=tel],
.webform-client-form input[type=text],
.webform-client-form input[type=time],
.webform-client-form input[type=url],
.webform-client-form textarea {
  margin: 0; }

.webform-client-form .form-item {
  margin: 0 0 20px;
  width: 100%;
  position: relative; }
  .webform-client-form .form-item .form-item {
    margin: 0; }
  .webform-client-form .form-item label {
    font-weight: 600;
    margin-bottom: 0; }

.webform-client-form fieldset .form-item {
  padding: 0; }

.webform-client-form.preview .webform-page {
  border-bottom: 1px solid #efefef;
  padding-bottom: 40px;
  text-indent: -9999px;
  display: none; }

.webform-client-form .webform-component-radios.inline .form-type-radio {
  float: left;
  width: auto;
  margin: 0 15px 0 0; }

.webform-client-form .webform-component-radios .form-type-radio label {
  font-weight: normal; }

.webform-client-form .webform-component-checkboxes .form-type-checkbox label {
  font-weight: normal; }

.webform-client-form .webform-component-file .description {
  float: left;
  width: 100%;
  margin: 10px 0 0;
  color: #707a80; }

.webform-client-form .webform-component-file input[type='submit'],
.webform-client-form .webform-component-file .form-submit {
  float: left;
  width: 106px;
  clear: both;
  margin: 10px 0 0; }

.webform-client-form .webform-layout-box {
  margin: 0 -15px;
  clear: both; }
  .webform-client-form .webform-layout-box > .form-item {
    padding: 0 15px; }
    @media screen and (max-width: 767px) {
      .webform-client-form .webform-layout-box > .form-item {
        width: auto;
        float: none; } }

.webform-client-form .webform-container-inline {
  /*
		input, select, .form-textarea-wrapper {
			width:60%;
			float: left;
			display: block;

			@include breakpoint(medium) {
				float: none;
				width:100%;
			}
		}*/ }
  .webform-client-form .webform-container-inline > label {
    margin: 0;
    width: 40%;
    float: left;
    padding-right: 20px; }
  .webform-client-form .webform-container-inline.webform-component-textfield input {
    width: 60%;
    float: right; }
  .webform-client-form .webform-container-inline > .form-radios,
  .webform-client-form .webform-container-inline > .form-textarea-wrapper,
  .webform-client-form .webform-container-inline > input,
  .webform-client-form .webform-container-inline > .form-checkboxes {
    width: 60%;
    float: right; }
  .webform-client-form .webform-container-inline div.description {
    display: block;
    float: left;
    width: 100%; }
  .webform-client-form .webform-container-inline.beschrijving-links div.description {
    width: 40%;
    line-height: 20px; }

.webform-client-form .webform-component-fieldset {
  width: 50%;
  float: left;
  padding: 0 20px;
  border: none;
  margin: 0; }
  .webform-client-form .webform-component-fieldset .fieldset-legend {
    display: none; }
  .webform-client-form .webform-component-fieldset.links, .webform-client-form .webform-component-fieldset.webform-component--kolom-verdeling-1, .webform-client-form .webform-component-fieldset.webform-component--links, .webform-client-form .webform-component-fieldset.webform-component--links1 {
    padding-left: 0px !important; }
  .webform-client-form .webform-component-fieldset.rechts, .webform-client-form .webform-component-fieldset.webform-component--kolom-verdeling, .webform-client-form .webform-component-fieldset.webform-component--rechts, .webform-client-form .webform-component-fieldset.webform-component--rechts1 {
    padding-right: 0px !important; }
  @media screen and (max-width: 767px) {
    .webform-client-form .webform-component-fieldset {
      padding: 0 !important;
      width: auto;
      float: none; } }

.webform-client-form .btn,
.webform-client-form .form-submit {
  margin: 15px 0 0;
  -webkit-appearance: none;
  clear: none;
  background-color: #4692DC;
  min-width: 11.875rem; }
  .webform-client-form .btn:hover,
  .webform-client-form .form-submit:hover {
    background-color: #96004f; }
  .webform-client-form .btn.webform-next,
  .webform-client-form .form-submit.webform-next {
    float: right;
    clear: none; }
  .webform-client-form .btn.webform-previous,
  .webform-client-form .form-submit.webform-previous {
    float: left;
    clear: none;
    background: #e9eaed; }

.webform-client-form .webform-confirmation {
  padding: 0 15px; }

.webform-client-form .ajax-progress {
  margin-left: 15px; }

.webform-client-form .throbber {
  background: url("../images/ajax-loader.gif");
  position: absolute;
  bottom: 0;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10; }

.webform-client-form .error input,
.webform-client-form .error input:focus,
.webform-client-form .error textarea,
.webform-client-form .error textarea:focus,
.webform-client-form .error:focus,
.webform-client-form input.error,
.webform-client-form input.error:focus,
.webform-client-form textarea.error {
  background: #fff !important;
  border-color: #db0f48 !important; }

.block-webform .links,
.webform-client-form .links {
  float: left;
  padding: 0 15px; }

a[href^='mailto:'] {
  color: #4692DC; }
  a[href^='mailto:']:hover {
    color: #96004f; }

.node-type-webform .webform-confirmation {
  padding: 0 !important; }

.webform-client-form-44 {
  margin: 0; }
  .webform-client-form-44 .webform-component-markup h2 {
    margin: 30px 0 20px; }
  @media screen and (max-width: 767px) {
    .webform-client-form-44 .form-item {
      padding: 0; } }

.form-actions {
  display: inline-block;
  width: 100%;
  position: relative; }
  .form-actions .submit-wrapper {
    display: inline-block;
    width: 100%;
    position: relative; }
    .form-actions .submit-wrapper .webform-submit {
      margin: 0;
      position: relative; }
    .form-actions .submit-wrapper .icon-arrow-right {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      pointer-events: none; }
      .form-actions .submit-wrapper .icon-arrow-right:before {
        font-size: 30px;
        position: relative;
        top: 3px; }

.sectie-item {
  float: left;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px; }
  .sectie-item .row {
    width: 1160px;
    max-width: 100%;
    margin: 0 auto; }
    .sectie-item .row .column,
    .sectie-item .row .columns {
      padding: 0 20px !important; }
  .sectie-item img {
    height: auto !important; }
  .sectie-item.maps-maps div {
    line-height: 16px; }
  .sectie-item.achtergrond-afbeelding:first-child {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .sectie-item.achtergrond-afbeelding h1 {
    margin-top: 40px; }
  .sectie-item.geenmarge {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sectie-item.margetop {
    padding-bottom: 0 !important; }
  .sectie-item.margebottom {
    padding-top: 0 !important; }
  .sectie-item.border, .sectie-item.border-bottom, .sectie-item.border-top {
    border-color: #efefef;
    border-style: solid;
    border-width: 0; }
  .sectie-item.border {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important; }
  .sectie-item.border-top {
    border-top-width: 1px !important; }
  .sectie-item.border-bottom {
    border-bottom-width: 1px !important; }

.color-2ba6cb a,
.color-2ba6cb blockquote,
.color-2ba6cb blockquote p:before,
.color-2ba6cb h1,
.color-2ba6cb h2,
.color-2ba6cb h3,
.color-2ba6cb h4,
.color-2ba6cb h5,
.color-2ba6cb h6,
.color-2ba6cb ol,
.color-2ba6cb p,
.color-2ba6cb ul {
  color: #fff; }

.color-2ba6cb .btn {
  background: #fff;
  color: #000; }

.color-2ba6cb .btn.rand {
  background: transparent;
  border: 2px solid #fff;
  color: #fff; }

.color-f2f2f2 {
  background: #f2f2f2; }

.color-e3e3e3 {
  background: #e3e3e3; }

.color-2ba6cb {
  background: #2ba6cb; }

.page-node-82 .sectie-item.formulier .block {
  float: left;
  margin: 20px 0;
  width: 100%;
  padding: 20px 30px;
  background: #2ba6cb; }
  .page-node-82 .sectie-item.formulier .block * {
    color: #fff !important; }
  .page-node-82 .sectie-item.formulier .block .btn {
    background: #fff !important;
    color: #2ba6cb !important; }

.fotoalbum-sectie {
  margin-top: 25px; }
  @media screen and (max-width: 767px) {
    .fotoalbum-sectie {
      width: 100%; } }
  .fotoalbum-sectie ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .fotoalbum-sectie ul li {
      padding: 0 10px;
      float: left;
      margin-bottom: 15px;
      width: 25%;
      list-style-type: none; }
      @media screen and (max-width: 1023px) {
        .fotoalbum-sectie ul li {
          width: 50%; } }
      @media screen and (max-width: 767px) {
        .fotoalbum-sectie ul li:nth-child(odd) {
          padding-left: 0px !important; }
        .fotoalbum-sectie ul li:nth-child(even) {
          padding-right: 0px !important; } }
      .fotoalbum-sectie ul li img {
        width: 100%; }
  .fotoalbum-sectie .foto-titel {
    float: left;
    width: 100%;
    text-align: center; }

.sectie-item .fotoalbum .content {
  width: 50% !important; }

.sectie-item .fotoalbum .content.col.nr1 {
  float: left; }

.sectie-item .view-fotoalbum .col {
  padding: 0; }

@media screen and (max-width: 767px) {
  .sectie-item .fotoalbum .content {
    width: 100% !important; } }

.pane-node-field-afbeelding img {
  display: block;
  width: 100%; }

body:not(.front) .paragraphs-items {
  background: #fff;
  margin: 0 auto;
  width: 1160px;
  max-width: calc(100% - 0px);
  /*@media screen and (min-width: 1024px) {
      margin-top: -200px;
      margin-bottom: -100px;
      animation: slideUpContent 1s forwards;
    }
    @include breakpoint(large) {
      max-width: calc(100% - 20px);
    }
    @include breakpoint(medium) {
      max-width: calc(100%);
    }*/ }

.paragraphs-items .entity-paragraphs-item {
  position: relative; }
  .paragraphs-items .entity-paragraphs-item h1:first-child, .paragraphs-items .entity-paragraphs-item h2:first-child, .paragraphs-items .entity-paragraphs-item h3:first-child, .paragraphs-items .entity-paragraphs-item h4:first-child, .paragraphs-items .entity-paragraphs-item h5:first-child, .paragraphs-items .entity-paragraphs-item h6:first-child {
    margin-top: 0; }
  .paragraphs-items .entity-paragraphs-item .content.bg_color_light .paragraph-title .columns,
  .paragraphs-items .entity-paragraphs-item .content.bg_color_dark .paragraph-title .columns {
    padding: 3.125rem 2.5rem 0 !important; }
  .paragraphs-items .entity-paragraphs-item .content.bg_color_light .paragraphs-item-paragraphs-text,
  .paragraphs-items .entity-paragraphs-item .content.bg_color_dark .paragraphs-item-paragraphs-text {
    padding: 3.125rem 2.5rem 2.5rem !important; }
    @media screen and (max-width: 767px) {
      .paragraphs-items .entity-paragraphs-item .content.bg_color_light .paragraphs-item-paragraphs-text,
      .paragraphs-items .entity-paragraphs-item .content.bg_color_dark .paragraphs-item-paragraphs-text {
        padding: 1.875rem 1.5625rem 1.5625rem !important; } }
  .paragraphs-items .entity-paragraphs-item .content.bg_color_light .row.paragraph-title + .row .paragraphs-item-paragraphs-text,
  .paragraphs-items .entity-paragraphs-item .content.bg_color_dark .row.paragraph-title + .row .paragraphs-item-paragraphs-text {
    padding-top: 0 !important; }
  .paragraphs-items .entity-paragraphs-item .columns {
    padding: 0; }
    @media screen and (max-width: 767px) {
      .paragraphs-items .entity-paragraphs-item .columns {
        padding: 0 20px; } }
  .paragraphs-items .entity-paragraphs-item .row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 auto; }
    @media screen and (min-width: 1480px) {
      .paragraphs-items .entity-paragraphs-item .row {
        padding: 0 20px; }
        .producten-detail .paragraphs-items .entity-paragraphs-item .row {
          padding: 24px 20px 20px 20px; }
        .paragraphs-items .entity-paragraphs-item .row .row {
          padding: 0; } }
    @media screen and (max-width: 767px) {
      .paragraphs-items .entity-paragraphs-item .row {
        display: block; } }
  .paragraphs-items .entity-paragraphs-item .bg-col,
  .paragraphs-items .entity-paragraphs-item .bg-img {
    position: absolute;
    width: calc(100vw - 120px);
    height: 100%; }
    @media screen and (max-width: 1025px) {
      .paragraphs-items .entity-paragraphs-item .bg-col,
      .paragraphs-items .entity-paragraphs-item .bg-img {
        width: calc(100vw - 60px); } }
    @media screen and (max-width: 767px) {
      .paragraphs-items .entity-paragraphs-item .bg-col,
      .paragraphs-items .entity-paragraphs-item .bg-img {
        width: 100vw; } }
  .paragraphs-items .entity-paragraphs-item .bg-col {
    left: 50%;
    transform: translateX(-50%); }
  .paragraphs-items .entity-paragraphs-item .bg-img {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat; }
    @media screen and (max-width: 767px) {
      .paragraphs-items .entity-paragraphs-item .bg-img {
        display: none; } }
  .paragraphs-items .entity-paragraphs-item .content {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .paragraphs-items .entity-paragraphs-item .content .paragraph-title h1 {
      margin-bottom: 40px; }
    .paragraphs-items .entity-paragraphs-item .content.bg-dark h1,
    .paragraphs-items .entity-paragraphs-item .content.bg-dark h2,
    .paragraphs-items .entity-paragraphs-item .content.bg-dark h3,
    .paragraphs-items .entity-paragraphs-item .content.bg-dark h4,
    .paragraphs-items .entity-paragraphs-item .content.bg-dark h5,
    .paragraphs-items .entity-paragraphs-item .content.bg-dark h6,
    .paragraphs-items .entity-paragraphs-item .content.bg-dark p,
    .paragraphs-items .entity-paragraphs-item .content.bg-dark p.intro,
    .paragraphs-items .entity-paragraphs-item .content.bg-dark a:not(.btn),
    .paragraphs-items .entity-paragraphs-item .content.bg-dark div, .paragraphs-items .entity-paragraphs-item .content.bg_color_dark h1,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark h2,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark h3,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark h4,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark h5,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark h6,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark p,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark p.intro,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark a:not(.btn),
    .paragraphs-items .entity-paragraphs-item .content.bg_color_dark div {
      color: #fff !important; }
    .paragraphs-items .entity-paragraphs-item .content.bg-dark .row .columns, .paragraphs-items .entity-paragraphs-item .content.bg_color_dark .row .columns {
      background: #A99A8D; }
    .paragraphs-items .entity-paragraphs-item .content.bg-light h1,
    .paragraphs-items .entity-paragraphs-item .content.bg-light h2,
    .paragraphs-items .entity-paragraphs-item .content.bg-light h3,
    .paragraphs-items .entity-paragraphs-item .content.bg-light h4,
    .paragraphs-items .entity-paragraphs-item .content.bg-light h5,
    .paragraphs-items .entity-paragraphs-item .content.bg-light h6, .paragraphs-items .entity-paragraphs-item .content.bg_color_light h1,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_light h2,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_light h3,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_light h4,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_light h5,
    .paragraphs-items .entity-paragraphs-item .content.bg_color_light h6 {
      color: #96004f; }
    @media screen and (max-width: 1023px) {
      .paragraphs-items .entity-paragraphs-item .content.bg-light, .paragraphs-items .entity-paragraphs-item .content.bg_color_light {
        overflow: hidden; } }
    .paragraphs-items .entity-paragraphs-item .content.bg-light .row .columns, .paragraphs-items .entity-paragraphs-item .content.bg_color_light .row .columns {
      background: #DED9CC; }
    .paragraphs-items .entity-paragraphs-item .content.background-image {
      background-attachment: fixed; }
      .paragraphs-items .entity-paragraphs-item .content.background-image .row {
        background: #fff; }
        .paragraphs-items .entity-paragraphs-item .content.background-image .row.paragraph-title {
          padding-bottom: 0; }
          .paragraphs-items .entity-paragraphs-item .content.background-image .row.paragraph-title + .row {
            padding-top: 0; }
        .paragraphs-items .entity-paragraphs-item .content.background-image .row .row {
          padding-top: 0;
          padding-bottom: 0; }
      @media screen and (max-width: 1200px) {
        .paragraphs-items .entity-paragraphs-item .content.background-image {
          padding: 160px 20px; } }
      @media screen and (max-width: 1023px) {
        .paragraphs-items .entity-paragraphs-item .content.background-image {
          background-attachment: scroll;
          padding: 80px 20px; }
          .paragraphs-items .entity-paragraphs-item .content.background-image .row {
            padding-top: 50px;
            padding-bottom: 50px; }
            .paragraphs-items .entity-paragraphs-item .content.background-image .row .columns {
              padding-top: 0;
              padding-bottom: 0; } }
      @media screen and (max-width: 767px) {
        .paragraphs-items .entity-paragraphs-item .content.background-image {
          background-image: none !important;
          padding: 0 !important; }
          .paragraphs-items .entity-paragraphs-item .content.background-image .row {
            padding-top: 20px;
            padding-bottom: 20px; }
            .paragraphs-items .entity-paragraphs-item .content.background-image .row .columns {
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 0 !important;
              padding-right: 0 !important; } }
  .paragraphs-items .entity-paragraphs-item.margebottom {
    margin-top: 0 !important; }
  .paragraphs-items .entity-paragraphs-item.margetop {
    margin-bottom: 0 !important; }
  .paragraphs-items .entity-paragraphs-item.geenmarge {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .paragraphs-items .entity-paragraphs-item .field-type-image img {
    display: block;
    margin: 0 auto; }

@media screen and (max-width: 767px) {
  .paragraphs-items .field-item > .entity-paragraphs-item > .content {
    padding: 0; }
    .paragraphs-items .field-item > .entity-paragraphs-item > .content.bg_color_light, .paragraphs-items .field-item > .entity-paragraphs-item > .content.bg_color_dark {
      padding-left: 0;
      padding-right: 0; }
    .paragraphs-items .field-item > .entity-paragraphs-item > .content .row .columns {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

.paragraphs-item-afbeeldingen .field-item {
  width: 50%;
  float: left;
  padding: 0 20px 20px; }
  .paragraphs-item-afbeeldingen .field-item img {
    float: left;
    width: 100%; }

.paragraphs-item-paragraphs-hr hr {
  margin: 30px 50px; }
  @media screen and (max-width: 1023px) {
    .paragraphs-item-paragraphs-hr hr {
      margin: 30px 20px; } }

.pane-shortview,
.paragraphs-item-paragraphs-kolomindeling {
  margin: 5.625rem 0; }
  @media screen and (max-width: 767px) {
    .pane-shortview,
    .paragraphs-item-paragraphs-kolomindeling {
      margin: 1.875rem 0; }
      .pane-shortview .paragraph-title,
      .pane-shortview .paragraphs-item-paragraphs-form,
      .pane-shortview .paragraphs-item-paragraphs-text,
      .paragraphs-item-paragraphs-kolomindeling .paragraph-title,
      .paragraphs-item-paragraphs-kolomindeling .paragraphs-item-paragraphs-form,
      .paragraphs-item-paragraphs-kolomindeling .paragraphs-item-paragraphs-text {
        padding: 0 1.25rem; }
      .pane-shortview .paragraphs-item-paragraphs-form,
      .paragraphs-item-paragraphs-kolomindeling .paragraphs-item-paragraphs-form {
        margin-bottom: 2.5rem; } }

.paragraphs-item-paragraphs-img {
  height: 100%; }
  @media screen and (max-width: 767px) {
    .paragraphs-item-paragraphs-img {
      margin: 0; } }

.paragraph-bg-image {
  position: relative;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative; }
  .paragraph-bg-image:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 71.42857%; }
  .paragraph-bg-image > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .paragraph-bg-image .image-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.875rem 2.8125rem 2.125rem;
    background: rgba(150, 0, 79, 0.7);
    text-align: center; }
    .paragraph-bg-image .image-caption h2 {
      color: #fff;
      margin: 0; }
      .section-ons-assortiment .paragraph-bg-image .image-caption h2 {
        font-size: 22px; }
  @media screen and (max-width: 767px) {
    .paragraph-bg-image {
      position: relative; }
      .paragraph-bg-image:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 66.66667%; }
      .paragraph-bg-image > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .paragraph-bg-image .image-caption {
        padding: 1.625rem 1.5625rem 1.125rem;
        text-align: left; } }

.three .paragraph-bg-image .image-caption {
  padding: 1rem 2rem; }
  .three .paragraph-bg-image .image-caption h2 {
    font-size: 1pxrem; }

.field-item > .paragraphs-item-paragraphs-text {
  margin: 6.25rem 0; }
  .field-item > .paragraphs-item-paragraphs-text .field-type-text-long {
    padding: 0 6.25rem; }
  @media screen and (max-width: 767px) {
    .field-item > .paragraphs-item-paragraphs-text {
      margin: 1.875rem 0; }
      .field-item > .paragraphs-item-paragraphs-text .field-type-text-long {
        padding: 0 1.25rem; } }

.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content > .row,
.pane-sharethis,
.node-type-vacatures .pane-vacatures .row,
.node-type-vacatures .pane-node-body,
.node-type-producten .pane-node-body {
  padding: 0 7.5rem; }
  @media screen and (max-width: 767px) {
    .paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content > .row,
    .pane-sharethis,
    .node-type-vacatures .pane-vacatures .row,
    .node-type-vacatures .pane-node-body,
    .node-type-producten .pane-node-body {
      padding: 0 0rem; } }

body:not(.front) .pane-node-body, body:not(.front)
.search-row {
  background: #fff;
  margin: 0 auto;
  width: 1160px;
  max-width: calc(100% - 0px); }

@media screen and (max-width: 767px) {
  .paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content > .row.paragraph-title {
    padding: 0 1.25rem; } }

.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content.bg_color_dark > .row,
.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content.bg_color_light > .row,
.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content.bg_volle_breedte > .row,
.node-type-vacatures .pane-vacatures .row .columns {
  padding: 0; }

@media screen and (max-width: 767px) {
  .paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content.bg_color_dark > .row.paragraph-title h1 {
    padding: 0 1.25rem; } }

.paragraphs-item-productinformatie .field {
  float: left;
  padding: 0 20px;
  width: 50%; }
  .paragraphs-item-productinformatie .field blockquote {
    position: relative;
    padding: 0 55px;
    width: 100%;
    max-width: 550px;
    border-color: #96004f;
    margin: 10px 0 40px 0;
    display: block;
    border-left: 0; }
    .paragraphs-item-productinformatie .field blockquote p {
      color: #96004f; }

@media screen and (min-width: 768px) {
  .entity-paragraphs-item .row > .columns:not(:first-child) > .entity-paragraphs-item:not(.paragraphs-item-paragraphs-img) {
    padding-left: 20px; }
  .entity-paragraphs-item .row > .columns:not(:last-child) > .entity-paragraphs-item:not(.paragraphs-item-paragraphs-img) {
    padding-right: 20px; } }

/*================================================
=== Masonry ======================================
================================================*/
.masonry-item {
  float: left; }
  .masonry-item item-wrapper {
    padding: 10px; }
    .masonry-item item-wrapper item-inner {
      padding: 0;
      position: relative;
      background: white;
      overflow: hidden;
      -webkit-transition: all 200ms ease-in;
      -moz-transition: all 200ms ease-in;
      -o-transition: all 200ms ease-in;
      transition: all 200ms ease-in; }
  .masonry-item .item-link {
    display: block;
    width: 100%;
    height: 100%; }
    .masonry-item .item-link:hover,
    .masonry-item .item-link .item-inner {
      box-shadow: 0 0 1px #00a1e5;
      -webkit-box-shadow: 0 0 1px #d9e7ec;
      -moz-box-shadow: 0 0 1px #00a1e5; }
  .masonry-item .datum {
    display: block;
    color: #999; }
  .masonry-item .titel {
    font-size: 32px;
    line-height: 36px;
    font-weight: 100;
    padding: 10px 0;
    display: block;
    padding: 20px 40px 10px; }
  .masonry-item .content {
    padding: 0 40px 20px;
    display: block; }
  .masonry-item .thumb {
    display: block; }
    .masonry-item .thumb .small img {
      float: left; }
  .masonry-item .tags li {
    list-style: none; }
  .masonry-item .tags ul li {
    float: left;
    padding: 0;
    margin-right: 3px;
    border: 1px solid #80b8f1;
    color: #80b8f1;
    padding: 3px 6px;
    border-radius: 5px; }

.masonry-item.extended {
  width: 66.6%; }
  .masonry-item.extended .item-inner {
    background: #fff; }
  .masonry-item.extended .datum {
    color: #fff; }
  .masonry-item.extended .tags li {
    color: #fff;
    border: 1px solid #fff; }

.masonry-item.quotes .item-inner {
  background: #2ccefd; }

.masonry-item.quotes .content {
  font-size: 22px;
  line-height: 25px;
  font-weight: 100;
  color: #fff;
  padding: 20px 40px; }

.masonry-item.quotes .quote-klant {
  float: right;
  clear: both;
  font-size: 18px;
  font-weight: 100;
  color: #fff;
  padding: 0 40px; }

.masonry-item.quotes .quote-functie {
  float: right;
  clear: both;
  font-weight: 100;
  font-size: 13px;
  padding-top: 3px;
  color: #fff;
  padding: 5px 40px 20px; }

@media screen and (max-width: 1023px) {
  .masonry-item {
    width: 50%; }
  .masonry-item.extended {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .masonry-item {
    width: 100%; }
  .masonry-item.extended {
    width: 100%; } }

/**
 * @file
 * Print styling
 *
 * We provide some sane print styling for Drupal using Zen's layout method.
 */
/**
 * By importing this CSS file as media 'all', we allow this print file to be
 * aggregated with other stylesheets, for improved front-end performance.
 */
@media print {
  a {
    /* Underline all links. */
    /* Don't underline header. */ }
    a:link, a:visited {
      text-decoration: underline !important; }
    a:link.header__site-link, a:visited.header__site-link {
      text-decoration: none !important; }
  /* Add visible URL after links. */
  #content {
    /* Add visible title after abbreviations. */
    /* Un-float the content. */
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    color: #000;
    background-color: transparent !important;
    background-image: none !important; }
    #content a {
      /* Only display useful links. */ }
      #content a[href]:after {
        content: " (" attr(href) ")";
        font-weight: normal;
        font-size: 16px; }
      #content a[href^='#']:after, #content a[href^='javascript:']:after {
        content: ''; }
    #content abbr[title]:after {
      content: " (" attr(title) ")"; }
  /* Turn off any background colors or images. */
  #main,
  #page,
  body {
    color: #000;
    background-color: transparent !important;
    background-image: none !important; }
  /* Hide sidebars and nav elements. */
  #footer,
  #navigation,
  #skip-link,
  #toolbar,
  .action-links,
  .book-navigation,
  .breadcrumb,
  .feed-icons,
  .forum-topic-navigation,
  .links,
  .pager,
  .region-sidebar-first,
  .region-sidebar-second,
  .tabs {
    visibility: hidden;
    display: none; } }

@keyframes slideUpContent {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-100px); } }

@keyframes slideDown {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(0); } }

@keyframes leftToRight {
  from {
    left: 20px; }
  to {
    left: calc(100% - 50px); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes textFadeIn {
  from {
    transform: scale(0.6);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1; } }

.row:after, #edit-submitted-upload-cv-ajax-wrapper:after, .webform-component-file:after, .footerblocks:after, .no-flexbox .bean-footer-payoff:after, .content:after, .home-views:after {
  content: '';
  display: block;
  clear: both; }

.content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) {
  list-style-type: none;
  padding-left: 1.3em; }
  .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li {
    margin: 0; }
    .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before {
      content: '\25AA';
      color: #96004f;
      display: inline-block;
      margin-left: -1.3em;
      width: 1.3em;
      font-family: monospace; }
      .messages .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content .messages ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before,
      .bg_color_dark .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content
      .bg_color_dark ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before,
      .bg_color_light .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content
      .bg_color_light ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before {
        color: #fff; }

html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden; }
  @media screen and (max-width: 1024px) {
    html {
      max-width: 100%;
      overflow-x: hidden; } }

body {
  font-size: 100%;
  margin: 0;
  background: #fff;
  overflow-x: hidden;
  padding: 0 150px; }
  @media screen and (max-width: 1200px) {
    body {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 767px) {
    body {
      padding: 0; } }

body,
caption,
div,
p,
td,
th {
  color: #231F20;
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  -webkit-font-smoothing: antialiased; }

strong {
  font-weight: 700; }

hr {
  border: 0;
  border-bottom: 2px solid #f0f0f0;
  margin: 30px 0; }

p {
  padding: 0 0 15px;
  margin: 0; }
  p img[style*='left'] {
    margin: 0 20px 20px 0; }
  p img[style*='right'] {
    margin: 0 0 20px 20px; }
  p .rtecenter {
    text-align: center; }
  @media screen and (max-width: 767px) {
    p {
      font-size: 14px;
      line-height: 20px; } }
  p:last-child {
    padding-bottom: 0; }

p.intro {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #231F20 !important; }
  @media screen and (max-width: 767px) {
    p.intro {
      font-size: 16px !important;
      line-height: 20px !important; } }

p.intro-big {
  font-weight: 300 !important;
  font-size: 3.125rem !important;
  line-height: 1.1 !important;
  color: #231F20 !important; }
  @media screen and (max-width: 767px) {
    p.intro-big {
      font-size: 1.875rem !important; } }

em {
  font-style: italic; }

a {
  transition: color .1s ease;
  color: #96004f;
  text-decoration: none; }
  a:hover {
    color: #4692DC; }

main.content {
  padding: 0; }

.content img {
  height: auto !important; }

.content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) {
  margin-bottom: 1em; }
  .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) p {
    display: inline; }

.content .item-list > ul li {
  list-style: none;
  margin-bottom: 20px;
  padding: 0 10px; }

.content ol:not(.flex-control-nav) {
  list-style-position: outside;
  margin: 0 0 10px 18px;
  list-style: decimal; }
  .content ol:not(.flex-control-nav) p {
    display: inline; }

.content table {
  width: 100% !important; }
  .content table th {
    text-align: left;
    padding: 0 0 5px 10px;
    font-weight: bold; }
  .content table tbody tr td {
    padding: 5px 7px;
    border: 1px solid #231F20; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 300;
  margin: 15px 0;
  line-height: 1.4; }
  h1,
  h1 a,
  h2,
  h2 a,
  h3,
  h3 a,
  h4,
  h4 a,
  h5,
  h5 a,
  h6,
  h6 a {
    color: #96004f; }

h1 {
  font-size: 32px; }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 26px;
      word-break: break-word; } }

h2 {
  font-size: 32px; }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 26px;
      word-break: break-word; } }

h3 {
  font-size: 24px; }
  @media screen and (max-width: 767px) {
    h3 {
      font-size: 20px;
      word-break: break-word; } }

h4 {
  font-size: 22px; }
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 19px;
      word-break: break-word; } }

h5 {
  font-size: 22px; }
  @media screen and (max-width: 767px) {
    h5 {
      font-size: 18px;
      word-break: break-word; } }

h6 {
  font-size: 18px; }
  @media screen and (max-width: 767px) {
    h6 {
      font-size: 18px;
      word-break: break-word; } }

blockquote {
  position: relative;
  padding: 0 55px;
  width: 100%;
  max-width: 550px;
  border-color: #96004f;
  margin: 40px 0;
  display: block; }
  blockquote p {
    font-weight: 700;
    font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-style: normal;
    margin: 0;
    padding: 0;
    color: #f06400; }
    blockquote p:after, blockquote p:before {
      font-size: 70px;
      line-height: 70px;
      width: 60px;
      font-weight: 700;
      position: absolute;
      font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
      font-style: italic; }
    blockquote p:before {
      content: ' ';
      border-left: 2px solid #96004f;
      float: left;
      left: 20px;
      top: 0;
      height: 100%; }
    blockquote p:after {
      float: right;
      right: 0;
      bottom: -32px; }
  @media screen and (max-width: 767px) {
    blockquote p {
      font-size: 16px; } }

#user-login .form-submit,
.btn,
.form-submit {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  background: #96004f;
  font-size: 20px;
  clear: both;
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
  text-decoration: none;
  padding: 8px 20px;
  margin: 10px 0;
  display: inline-block;
  border-radius: 20px;
  border: 0;
  text-align: center;
  cursor: pointer; }
  .rteright #user-login .form-submit, .rteright
  .btn, .rteright
  .form-submit {
    float: right; }
  .rteleft #user-login .form-submit, .rteleft
  .btn, .rteleft
  .form-submit {
    float: left; }
  .rtecenter #user-login .form-submit, .rtecenter
  .btn, .rtecenter
  .form-submit {
    float: none;
    display: inline-block; }
  #user-login .form-submit:hover,
  .btn:hover,
  .form-submit:hover {
    color: #fff;
    text-decoration: none;
    background: #c9006a; }
  .bg_color_dark #user-login .form-submit,
  .bg_color_light #user-login .form-submit, .bg_color_dark
  .btn,
  .bg_color_light
  .btn, .bg_color_dark
  .form-submit,
  .bg_color_light
  .form-submit {
    background: #fff;
    color: #96004f; }
    .bg_color_dark #user-login .form-submit:hover,
    .bg_color_light #user-login .form-submit:hover, .bg_color_dark
    .btn:hover,
    .bg_color_light
    .btn:hover, .bg_color_dark
    .form-submit:hover,
    .bg_color_light
    .form-submit:hover {
      color: #96004f;
      background: white; }
  .bg_color_dark #user-login .form-submit:hover, .bg_color_dark
  .btn:hover, .bg_color_dark
  .form-submit:hover {
    background: #DED9CC; }
  .bg_color_light #user-login .form-submit:hover, .bg_color_light
  .btn:hover, .bg_color_light
  .form-submit:hover {
    background: #A99A8D; }

.form-submit {
  padding: 8px 16px; }

.fotoalbum-overview .thumb.col a,
.fotoalbum-sectie .thumb.col a,
a.popup {
  position: relative;
  display: block;
  line-height: 0; }
  .fotoalbum-overview .thumb.col a:hover,
  .fotoalbum-sectie .thumb.col a:hover,
  a.popup:hover {
    opacity: .8; }
  .fotoalbum-overview .thumb.col a img,
  .fotoalbum-sectie .thumb.col a img,
  a.popup img {
    position: relative;
    z-index: 0; }
  .fotoalbum-overview .thumb.col a span.icon,
  .fotoalbum-sectie .thumb.col a span.icon,
  a.popup span.icon {
    background: url("../images/enlarge_icon_crop.png") no-repeat;
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    z-index: 1;
    height: 12px;
    width: 13px; }

.green {
  color: #96004f; }

.orange {
  color: #f06400; }
  .intro .orange {
    color: #231F20; }

p.popup {
  padding: 0;
  line-height: 0; }

.cke_editable,
.cke_editable_themed {
  padding: 10px !important; }

.bg-dark p,
.bg-dark p.intro,
.bg-dark h5,
.bg-dark h6,
.bg-dark li,
.bg-dark blockquote {
  color: #fff; }

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4 {
  color: #DED9CC; }

@keyframes slideUpContent {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-100px); } }

@keyframes slideDown {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(0); } }

@keyframes leftToRight {
  from {
    left: 20px; }
  to {
    left: calc(100% - 50px); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes textFadeIn {
  from {
    transform: scale(0.6);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1; } }

@keyframes slideUpContent {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-100px); } }

@keyframes slideDown {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(0); } }

@keyframes leftToRight {
  from {
    left: 20px; }
  to {
    left: calc(100% - 50px); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes textFadeIn {
  from {
    transform: scale(0.6);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1; } }

.row:after, #edit-submitted-upload-cv-ajax-wrapper:after, .webform-component-file:after, .footerblocks:after, .no-flexbox .bean-footer-payoff:after, .content:after, .home-views:after {
  content: '';
  display: block;
  clear: both; }

.content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) {
  list-style-type: none;
  padding-left: 1.3em; }
  .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li {
    margin: 0; }
    .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before {
      content: '\25AA';
      color: #96004f;
      display: inline-block;
      margin-left: -1.3em;
      width: 1.3em;
      font-family: monospace; }
      .messages .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content .messages ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before,
      .bg_color_dark .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content
      .bg_color_dark ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before,
      .bg_color_light .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before, .content
      .bg_color_light ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) li:before {
        color: #fff; }

html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden; }
  @media screen and (max-width: 1024px) {
    html {
      max-width: 100%;
      overflow-x: hidden; } }

body {
  font-size: 100%;
  margin: 0;
  background: #fff;
  overflow-x: hidden;
  padding: 0 150px; }
  @media screen and (max-width: 1200px) {
    body {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 767px) {
    body {
      padding: 0; } }

body,
caption,
div,
p,
td,
th {
  color: #231F20;
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  -webkit-font-smoothing: antialiased; }

strong {
  font-weight: 700; }

hr {
  border: 0;
  border-bottom: 2px solid #f0f0f0;
  margin: 30px 0; }

p {
  padding: 0 0 15px;
  margin: 0; }
  p img[style*='left'] {
    margin: 0 20px 20px 0; }
  p img[style*='right'] {
    margin: 0 0 20px 20px; }
  p .rtecenter {
    text-align: center; }
  @media screen and (max-width: 767px) {
    p {
      font-size: 14px;
      line-height: 20px; } }
  p:last-child {
    padding-bottom: 0; }

p.intro {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #231F20 !important; }
  @media screen and (max-width: 767px) {
    p.intro {
      font-size: 16px !important;
      line-height: 20px !important; } }

p.intro-big {
  font-weight: 300 !important;
  font-size: 3.125rem !important;
  line-height: 1.1 !important;
  color: #231F20 !important; }
  @media screen and (max-width: 767px) {
    p.intro-big {
      font-size: 1.875rem !important; } }

em {
  font-style: italic; }

a {
  transition: color .1s ease;
  color: #96004f;
  text-decoration: none; }
  a:hover {
    color: #4692DC; }

main.content {
  padding: 0; }

.content img {
  height: auto !important; }

.content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) {
  margin-bottom: 1em; }
  .content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) p {
    display: inline; }

.content .item-list > ul li {
  list-style: none;
  margin-bottom: 20px;
  padding: 0 10px; }

.content ol:not(.flex-control-nav) {
  list-style-position: outside;
  margin: 0 0 10px 18px;
  list-style: decimal; }
  .content ol:not(.flex-control-nav) p {
    display: inline; }

.content table {
  width: 100% !important; }
  .content table th {
    text-align: left;
    padding: 0 0 5px 10px;
    font-weight: bold; }
  .content table tbody tr td {
    padding: 5px 7px;
    border: 1px solid #231F20; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 300;
  margin: 15px 0;
  line-height: 1.4; }
  h1,
  h1 a,
  h2,
  h2 a,
  h3,
  h3 a,
  h4,
  h4 a,
  h5,
  h5 a,
  h6,
  h6 a {
    color: #96004f; }

h1 {
  font-size: 32px; }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 26px;
      word-break: break-word; } }

h2 {
  font-size: 32px; }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 26px;
      word-break: break-word; } }

h3 {
  font-size: 24px; }
  @media screen and (max-width: 767px) {
    h3 {
      font-size: 20px;
      word-break: break-word; } }

h4 {
  font-size: 22px; }
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 19px;
      word-break: break-word; } }

h5 {
  font-size: 22px; }
  @media screen and (max-width: 767px) {
    h5 {
      font-size: 18px;
      word-break: break-word; } }

h6 {
  font-size: 18px; }
  @media screen and (max-width: 767px) {
    h6 {
      font-size: 18px;
      word-break: break-word; } }

blockquote {
  position: relative;
  padding: 0 55px;
  width: 100%;
  max-width: 550px;
  border-color: #96004f;
  margin: 40px 0;
  display: block; }
  blockquote p {
    font-weight: 700;
    font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-style: normal;
    margin: 0;
    padding: 0;
    color: #f06400; }
    blockquote p:after, blockquote p:before {
      font-size: 70px;
      line-height: 70px;
      width: 60px;
      font-weight: 700;
      position: absolute;
      font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
      font-style: italic; }
    blockquote p:before {
      content: ' ';
      border-left: 2px solid #96004f;
      float: left;
      left: 20px;
      top: 0;
      height: 100%; }
    blockquote p:after {
      float: right;
      right: 0;
      bottom: -32px; }
  @media screen and (max-width: 767px) {
    blockquote p {
      font-size: 16px; } }

#user-login .form-submit,
.btn,
.form-submit {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  background: #96004f;
  font-size: 20px;
  clear: both;
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
  text-decoration: none;
  padding: 8px 20px;
  margin: 10px 0;
  display: inline-block;
  border-radius: 20px;
  border: 0;
  text-align: center;
  cursor: pointer; }
  .rteright #user-login .form-submit, .rteright
  .btn, .rteright
  .form-submit {
    float: right; }
  .rteleft #user-login .form-submit, .rteleft
  .btn, .rteleft
  .form-submit {
    float: left; }
  .rtecenter #user-login .form-submit, .rtecenter
  .btn, .rtecenter
  .form-submit {
    float: none;
    display: inline-block; }
  #user-login .form-submit:hover,
  .btn:hover,
  .form-submit:hover {
    color: #fff;
    text-decoration: none;
    background: #c9006a; }
  .bg_color_dark #user-login .form-submit,
  .bg_color_light #user-login .form-submit, .bg_color_dark
  .btn,
  .bg_color_light
  .btn, .bg_color_dark
  .form-submit,
  .bg_color_light
  .form-submit {
    background: #fff;
    color: #96004f; }
    .bg_color_dark #user-login .form-submit:hover,
    .bg_color_light #user-login .form-submit:hover, .bg_color_dark
    .btn:hover,
    .bg_color_light
    .btn:hover, .bg_color_dark
    .form-submit:hover,
    .bg_color_light
    .form-submit:hover {
      color: #96004f;
      background: white; }
  .bg_color_dark #user-login .form-submit:hover, .bg_color_dark
  .btn:hover, .bg_color_dark
  .form-submit:hover {
    background: #DED9CC; }
  .bg_color_light #user-login .form-submit:hover, .bg_color_light
  .btn:hover, .bg_color_light
  .form-submit:hover {
    background: #A99A8D; }

.form-submit {
  padding: 8px 16px; }

.fotoalbum-overview .thumb.col a,
.fotoalbum-sectie .thumb.col a,
a.popup {
  position: relative;
  display: block;
  line-height: 0; }
  .fotoalbum-overview .thumb.col a:hover,
  .fotoalbum-sectie .thumb.col a:hover,
  a.popup:hover {
    opacity: .8; }
  .fotoalbum-overview .thumb.col a img,
  .fotoalbum-sectie .thumb.col a img,
  a.popup img {
    position: relative;
    z-index: 0; }
  .fotoalbum-overview .thumb.col a span.icon,
  .fotoalbum-sectie .thumb.col a span.icon,
  a.popup span.icon {
    background: url("../images/enlarge_icon_crop.png") no-repeat;
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    z-index: 1;
    height: 12px;
    width: 13px; }

.green {
  color: #96004f; }

.orange {
  color: #f06400; }
  .intro .orange {
    color: #231F20; }

p.popup {
  padding: 0;
  line-height: 0; }

.cke_editable,
.cke_editable_themed {
  padding: 10px !important; }

.bg-dark p,
.bg-dark p.intro,
.bg-dark h5,
.bg-dark h6,
.bg-dark li,
.bg-dark blockquote {
  color: #fff; }

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4 {
  color: #DED9CC; }
