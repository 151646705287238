/*================================================
=== BLOG --=======================================
================================================*/
.news-overview {
  margin-top: $column-gutter * 2;
  .view-content {
    margin: 0 (-$column-gutter / 2);
  }

  @media screen and (max-width: 1200px) {
    padding: 0 ($column-gutter / 2);
  }
  @include breakpoint(medium) {
    padding: 0;
  }
}

@include breakpoint(medium) {
  .front .news-shortview .news-item {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

/* Blog overview */
.news-item {
  &:before {
    @include transition(opacity);
    @include extend-icon('arrow');
    color: $primary-color;
    position: absolute;
    z-index: 1;
    top: $column-gutter / 2;
    right: $column-gutter / 2;
    font-size: 30px;
    opacity: 0
  }
  .thumb {
    img {
      //mix-blend-mode: screen;
      mix-blend-mode: multiply;
      @include transition(filter);
    }
  }
  .body {
    color: $text-color;
  }

  @include breakpoint(large) {
    &.three.columns {
      width: 50% !important;
    }
  }

  @include breakpoint(medium) {
    &.three.columns {
      width: 100% !important;
    }
  }

  @include breakpoint(large, 'up') {
    &:hover {
      background: $block-hover-bg-color;
      &:before {
        opacity: 1;
      }
      a {
        text-decoration: none;
      }
      .type,
      .date,
      .title,
      .body,
      .body p,
      .body span {
        color: $block-hover-text-color !important;
      }

      .thumb {
        //background-color: $block-hover-bg-color;

        img {
          filter: grayscale(100%) contrast(200%);

          .is-ie & {
            opacity: .5;
          }
        }
      }
    }
  }
}

/* Blog Detail */
.blog-detail-content {
  //> .row:first-child {
  //  background: $white;
  //  @media screen and (min-width: 1024px) {
  //    animation: slideUpContent 1s forwards;
  //    margin-top: -200px;
  //  }
  //}
  //.detail {
  //  .item {
  //    .title {
  //      margin-top: 0;
  //    }
  //  }
  //}
  .news-shortview {
    margin: 0 (-$column-gutter);
    @media screen and (max-width: 1200px) {
      margin: 0 (-$column-gutter / 2);
    }
  }
}

@include breakpoint(large) {
  .news-overview {
    .view-content {
      margin: 0;
    }
  }

  .news-item {
    width: 33.3333% !important;
    .front & {
      width: 50% !important;
    }
  }

  .blog-detail-content {
    .news-shortview {
      margin: 0 (-$column-gutter / 2);
    }
  }
}

@include breakpoint(medium) {
  .news-item {
    width: 100% !important;
    .front & {
      width: 100% !important;
      &:last-child {
        display: none;
      }

      .thumb {
        img {
          width: 100%;
        }
      }
    }
  }
}
