.vacature-overzicht > .row {
  margin-top: rem(90);
  margin-bottom: rem(90);

  > .columns:first-child {
    padding-left: 0;
  }

  > .columns:last-child {
    padding-right: 0;
  }

  @include breakpoint(medium) {
    margin-top: rem(30);
    margin-bottom: rem(30);

    > .columns {
      padding: 0 ($column-gutter / 2) !important;
    }
  }

  .titel {
    color: $primary-color;
    font-weight: $fw-bold;
    font-size: rem(20);
    margin-bottom: rem(10);

    .alt {
      color: $text-color;
    }
  }

  .vanRijsingengreen .titel {
    color: $vr-green-primary;
  }
  .vanRijsingensource .titel {
    color: $vr-source-primary;
  }
  .vanRijsingeningredients .titel {
    color: $vr-ingredients-primary;
  }
  .vanRijsingenfreshservice .titel {
    color: $vr-freshservice-primary;
  }
  .vanRijsingencarrotconcepts .titel {
    color: $vr-carrotconcepts-primary;
  }

  .ondertitel {
    margin-bottom: rem(10);
    color: $background-dark;
    font-size: rem(14);
  }

  .content-wrap {
    padding: $column-gutter ($column-gutter / 2);
    //transition: background-color .15s ease;
    position: relative;
    border-bottom: 1px solid $gray;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: -1px;
      right: $column-gutter / -2;
      left: $column-gutter / -2;
      transition: background-color .15s ease;
    }

    &::after {
      content: '';
      position: absolute;
      top: $column-gutter;
      right: $column-gutter / 2;
      background: url('/sites/all/themes/front/images/svg/arrow.svg') center/cover no-repeat;
      width: rem(30);
      height: rem(30);

      @include breakpoint(medium) {
        top: rem(10);
        right: rem(-10);
      }
    }

    .titel,
    .ondertitel,
    p,
    span {
      transition: color .15s ease;
    }

    .columns {
      padding: 0;
    }

    &:hover {
      &::before {
        background-color: $link-color-hover;
      }

      * {
        color: $white;
      }

      .company-logo {
        opacity: 0;
      }
    }
  }
}

.node-type-vacatures {
  .pane-node-body,
  .pane-sharethis {
    width: $row-width;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 ($column-gutter / 2);
  }
  .pane-node-body {
    /*columns: 2;
    column-gap: $column-gutter;*/
    margin-top: rem(90);

    @include breakpoint(medium) {
      columns: 1;
      margin-top: rem(30);
    }

    h2:first-child {
      margin-top: 0;
    }
  }

  .pane-sharethis {
    margin: rem(35) 0;
  }
}

.company-logo,
.company-logo-large {
  @include aspect-ratio(160, 160);
  transition: opacity .15s ease;
  max-width: rem(160);
  margin: 0 auto;

  mix-blend-mode: multiply;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .vanRijsingengreen & {
    background-image: url('/sites/all/themes/front/images/logos/icon-vr-green@3x.png');
  }

  .vanRijsingensource & {
    background-image: url('/sites/all/themes/front/images/logos/icon-vr-source@3x.png');
  }

  .vanRijsingeningredients & {
    background-image: url('/sites/all/themes/front/images/logos/icon-vr-ingredients@3x.png');
  }

  .vanRijsingenfreshservice & {
    background-image: url('/sites/all/themes/front/images/logos/icon-vr-freshservice@3x.png');
  }

  .vanRijsingencarrotconcepts & {
    background-image: url('/sites/all/themes/front/images/logos/icon-vr-carrotconcepts@3x.png');
  }
}

.layout--full-half .sidebar .pane-block {
  background-color: $background-dark;
  padding: rem(50) rem(60) rem(60);
  margin-bottom: rem(60);

  @include breakpoint(medium) {
    padding: rem(25) rem(30) rem(30);
  }

  h1, h2, h3, h4, h5, h6 {
    color: $white;
    margin-top: 0;
  }

  p,
  a:not(.btn),
  a:not(.btn):hover {
    color: $white;
  }

  p {
    font-size: rem(16);
    line-height: $base-line-height;
  }

  .bef-select-as-links > .form-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    > .form-item {
      font-size: rem(20);
      white-space: nowrap;

      &::before {
        content: '';
        background: url('/sites/all/themes/front/images/svg/arrow.svg') center/cover no-repeat;
        width: rem(30);
        height: rem(30);
        display: inline-block;
        vertical-align: top;
      }

      a {
        display: inline-block;
        white-space: normal;
        transition: transform .15s ease;

        &.active {
          font-weight: $fw-bold;
        }
      }

      &:hover a {
        transform: translateX(rem(10));
      }
    }

    [id$='-all'] {
      order: 100;
      opacity: .5;

      a.active {
        font-weight: $body-font-weight;
      }
    }
  }

  form {
    margin: 0;
    padding: 0;
  }

  .views-exposed-widgets {
    margin: 0;
  }

  .views-exposed-widget {
    float: none;
    padding: 0;
  }

  .infowrap,
  .infoblok {
    float: none;
  }

  .infoblok p {
    margin-bottom: rem(15);

    &:last-child {
      margin-bottom: 0;
    }
  }
  .infoblok h2 {
    line-height: $header-line-height;
    margin-bottom: rem(15);
  }
}

// Override for vacancy block.

.layout--full-half .sidebar .pane-views-exp-vacatures-block {
  background-color: $vr-green-primary;

  padding:0;
  // Reverse padding on these to make the whole block clickable
  .infoblok{
        padding: 3.125rem 3.75rem 3.75rem;
  }
  a.infowrap .infoblok:hover{
    background-color:#4692DC!important;
  }

}

.vacature-overzicht .layout--full-half .sidebar .pane-block{
  background: $primary-color;

  padding:0;
  .entity.infoblok{
        padding: 3.125rem 3.75rem 3.75rem;

  }
  a.infowrap .infoblok:hover{
    background-color:#4692DC!important;
  }


}


.node-type-vacatures{
  main.content{
    padding:0 20px;
  }
  .panel-pane.pane-sharethis{
    margin: 2.2rem 8.2rem;
    width:auto;
    .pane-title{text-transform: none;}
  @include breakpoint(medium){
      margin: 2.2rem 1rem;
  }
  }
  .field-name-field-webformulier-invoegen{

  }

}
.paragraphs-item-paragraphs-form{
    margin: 2.2rem 7.2rem;
  @include breakpoint(medium){
      margin: 2.2rem 1rem;
  }
  padding:2rem;
  &.color-inverted label{
      color: $white;
  }
  &.color-inverted .form-submit,
  &.color-inverted .webform-component-file .btn{
    background-color: $white;
    border:solid 1px transparent;
  }
  &.color-inverted{
    h2,h3,h4,h5,h6{
      color:$white;
    }
  }

  .field-name-field-kleur{
    display: none;
  }
  &.color-inverted .form-required{
    color: #fff;
  }
  .form-submit:hover{
    background-color: #ffffff!important;
    opacity: 0.8;
  }

}
/*
//================================================
//=== VACATURES ==================================
//================================================

body.section-plaats,
body.section-vacatures,
body.section-werkveld {
  .container.content {
    //background:#e9f7ff;
  }

  .content-top {
    //padding-top: 20px;
  }
}

.vacature-overzicht {
  .sidebar {
    margin-top: 20px;
  }

  .blog-item {
    .meta {
      a {
        color: $black;
      }
    }

    .lees-meer {
      margin-top: 20px;
    }

    .content-wrap {
      width: 100%;
    }
  }

  .block {
    padding: 40px;
    background: $white;
    margin: 0 0 30px;
  }

}

.vacature-homepage {
  .blog-item {
    .meta {
      a {
        color: $black;
      }
    }

    .lees-meer {
      margin-top: 20px;
    }

    .content-wrap {
      width: 100%;
    }
  }
}

// OVERVIEW PAGINA
.vacature-detail {
  @include breakpoint(large) {
    .content {
      .first,
      .last {
        width: 50% !important;
      }
    }
  }

  @include breakpoint(medium) {
    .content {
      .first,
      .last {
        width: 100% !important;
      }
    }
  }

  .sidebar {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 20px;
      line-height: 20px;
      margin-top: 0;
    }
  }

  .meta-info {
    font-weight: bold;
    color: $black;
  }

  form {
    margin: 0;

  }

  .persoon.info {
    background: #efefef;
    padding: 30px;
    float: left;
    width: 100%;
    margin: 0;

    .view-content {
      margin: 10px 0 0;
      //border-top: 1px solid #D3E2EF;
      padding: 0 0 30px;
      //border-bottom: 1px solid rgb(210, 210, 210);
    }

    .view-header {

      padding: 0;

      p {
        font-size: inherit;
        font-weight: inherit;
      }
    }

    .titel {
      font-weight: bold;
      color: $black;
    }
  }

  .koppeling-persoon {
    padding: 30px 30px 0;
    float: left;
    width: 100%;
    background: #efefef;

  }

  .field-name-field-tekst {
    background: #565656;
    padding: 30px;
    float: left;
    width: 100%;
    margin: 0 0 30px;

    ol,
    ul {
      margin-bottom: 0;
    }

    * {
      color: $white;
    }
  }

  .field-name-field-webformulier-invoegen {
    background: #efefef;
    padding: 0 30px 30px;
    float: left;
    width: 100%;
    margin: 0;
  }

  .container.content {
    background: $white!important;

    h1 {
      margin-top: 0;
    }
  }

  .content-top {
    padding-top: 0;
  }

  .meta {
    background: #efefef;
    padding: 15px;
    color: $white;
    float: left;

    div {
      color: $white;
      float: left;
      width: 100%;
      font-weight: bold;
      margin-bottom: 3px;

      .views-label {
        float: left;
        margin-right: 3px;
      }

      .field-content {
        float: left;
        width: auto;
      }
    }

    .views-field-title {
      span {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }

    .views-field-field-evt-extra-opmerking {
      div {
        font-weight: normal;
      }
    }

  }

  .event-foto {
    float: left;
    width: 100%;
    margin-bottom: 40px;

    img {
      float: left;
      width: 100%;
    }
  }
}*/
