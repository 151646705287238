@import "global-settings";

// Base colors
$react-green: #55a514 !default;
$white: #fff !default;
$black: #000 !default;

$green: #43B649 !default;
$orange: #f06400 !default;

$gray: #9b9b9b !default;
$gray-light: #DED9CC !default;
$gray-darker: #A99A8D !default;
$border-color: #f0f0f0;

$red: #c60f13 !default;
$blue: #4692DC !default;

$primary-color: $vr-green-primary !default;
$background-light: $gray-light !default;
$background-dark: $gray-darker !default;
$shadow-color: #F0F0F0 !default;
$caption-bg-color: rgba($primary-color, .7);
$caption-bg-global-color: rgba($vr-green-primary, .7);
$slider-caption-bg-color: rgba($primary-color, .7);
$caption-text-color: $white;
$caption-text-shadow: 0 1px 2px rgba($blue, .3);

// Assign colors
// - Typography
$text-color: #231F20 !default;
$text-intro-color: $text-color !default;
$header-font-color: $primary-color !default;
$body-font-color: $text-color !default;

$footer-header-color: $white !default;
$footer-text-color: $text-color !default;
$footer-link-color: $footer-text-color !default;

// - Admin
$admin-menu-background: $react-green !default;
$admin-menu-background-active: lighten($admin-menu-background, 5%) !default;
$admin-menu-background-hover: #333 !default;
$admin-menu-color: $white !default;
$admin-submenu-background: #333 !default;
$admin-submenu-color: $white !default;
$user-login-background: $gray-light !default;
$user-login-border: 0 !default;

// - Links
$link-color: $primary-color !default;
$link-color-hover: $blue !default;
$link-color-active: $blue !default;
$block-hover-bg-color: $link-color-hover !default;
$block-hover-text-color: $white !default;

// - Menu-items
$menu-link-color: $primary-color !default;
$top-menu-link-color: #444444 !default;
$menu-link-color-hover: $primary-color !default;
$menu-link-color-active: $primary-color !default;

// - Search
$search-color: #666666 !default;

// - Buttons
$button-color: $primary-color !default;
$button-color-hover: lighten($button-color, 10%) !default;
$button-color-active: darken($button-color, 10%) !default;
$button-alternative-color: $white !default;
$button-alternative-color-hover: lighten($button-alternative-color, 10%) !default;
$button-alternative-color-active: darken($button-alternative-color, 10%) !default;

// - Messages
$alert-color: $red !default;
$warning-color: $orange !default;
$success-color: $green !default;

// - Social
$facebook-color: #3b5998 !default;
$twitter-color: #55acee !default;
$linkedin-color: #0077b5 !default;
$googleplus-color: #dd4b39 !default;
$pinterest-color: #cc2127 !default;
$youtube-color: #cd201f !default;
$email-color: #9b9b9b !default;
$phone-color: #A99A8D !default;

$socials: (
  facebook: $facebook-color,
  twitter: $twitter-color,
  linkedin: $linkedin-color,
  googleplus: $googleplus-color,
  pinterest: $pinterest-color,
  youtube: $youtube-color,
  email: $email-color,
  phone: $phone-color,
);
