.products-overview {
  max-width: $row-width;
  margin: 0 auto;

  > .view-content > .row {
    margin: 0 -20px;
    max-width: none;
    width: auto;

    @media screen and (min-width: 1480px) {
      margin: 0 auto;
    }
  }

  .product{
    padding-top: $column-gutter / 2;
    text-align: center;
    margin-bottom: rem(20);
    @include transition(color, background-color);

    img {
      @include transition(filter, opacity);
      display: block;
      margin: 0 auto;
      //mix-blend-mode: screen;
      mix-blend-mode: multiply;
    }

    h4 {
      @include transition(color, background-color);
      color: $text-color;
      margin: 0;
      padding: rem(16) rem(20) rem(20);
      background-color: $background-light;
    }

    a {
      display: block;
      @include transition(color, background-color);
    }

    @include breakpoint(large) {
      &.three.columns {
        width: 50% !important;
      }
    }

    @include breakpoint(medium) {
      &.three.columns {
        width: 100% !important;
      }
    }

    @include breakpoint(large, 'up') {
      &:hover {
        background-color: $link-color-hover;

        img {
          filter: grayscale(100%) contrast(200%);
          opacity: .9;

          .is-ie & {
            opacity: .5;
          }
        }

        h4 {
          background-color: $link-color-hover;
          color: $white;
        }
      }
    }
  }
}

.producten-detail {
  .field-name-body .field-item {
    @include breakpoint(medium, 'up') {
      columns: 2;
      column-gap: rem(40);
    }
  }
}

.paragraphs-item-paragraph-specificatie {
  margin: rem(10) 0;

  .six.columns:first-child {
    padding-right: $column-gutter / 2;
  }
  .six.columns:last-child {
    padding-left: $column-gutter / 2;
  }

  .spec-title {
    background-color: $background-dark;
    padding: rem(24) rem(60) rem(20);
    cursor: pointer;

    .field {
      display: inline-block;
      max-width: calc(100% - 32px);
    }

    h3 {
      color: $white;
      font-size: 22px;
      margin: 0;
      transition: color .15s ease;
    }

    .arrow {
      float: right;
      transform: rotate(0);
      transition: transform .15s ease;

      * {
        transition: fill .15s ease;
      }
    }

    @include breakpoint(medium) {
      padding: rem(16) rem(30) rem(18);
    }

    @include breakpoint(medium, 'up') {
      &:hover {
        h3 {
          color: $background-light;
        }

        .arrow * {
          fill: $background-light;
        }
      }
    }
  }

  .spec-body {
    background-color: $background-light;
    padding: rem(28) rem(60) rem(48);

    //li::before {
    //  color: $white !important;
    //}

    @include breakpoint(medium) {
      padding: rem(20) rem(30) rem(34);
    }
  }

  &.open {
    .arrow {
      transform: rotate(90deg);
    }
  }
}

@include breakpoint(medium) {
  .pane-node-body,
  .pane-sharethis {
    padding-left: $column-gutter / 2;
    padding-right: $column-gutter / 2;
  }
}
