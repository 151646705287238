//================================================
//=== BREADCRUMB =================================
//================================================


.block.breadcrumbs {
  width: $row-width;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 1480px) {
    padding-left: $column-gutter / 2;
    padding-right: $column-gutter / 2;
  }
}

.breadcrumbs a,
.breadcrumbs span {
  font-size: rem(12);
  color: $gray;
  text-decoration: none;
  font-weight: $fw-normal;
  opacity: .6;
}

.breadcrumbs a.easy-breadcrumb_segment-title {
  color: $gray;
  opacity: 1;
}

.easy-breadcrumb_segment-separator {
  padding: 0 5px;
}

// Hide op homepage
.front .breadcrumbs {
  display: none;
}

.breadcrumbs {
  min-width: 0;
  padding: 7px 0;
  max-width: 100%;
  // opacity: 0.3;
  position: relative;
  z-index: 10;
  font-size: 12px;
  line-height: 17px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .easy-breadcrumb {
    // width: $row-width;
    max-width: 100%;
    // margin: ($column-gutter / 2) auto $column-gutter;
    margin: 0 auto;
    padding: 0;
    // padding: 0 ($column-gutter / 2);
  }
  @media screen and (max-width: 1200px) {
    // padding: 10px ($column-gutter / 2);
  }
  // @include breakpoint(large){
  //   padding: 10px ($column-gutter / 2);
  // }
  @include breakpoint(large) {
    .easy-breadcrumb {
      padding: 0 20px;
    }
  }
  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}
