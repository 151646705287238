//@mixin lees-meer {
//  color: $white;
//  font-size: 18px;
//  font-family: 'basis';
//  text-align: center;
//  position: absolute;
//  right: 30px;
//  bottom: 30px;
//}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function rem($pixels: 16px, $base: 16px) {
  @return #{$pixels / strip-unit($base)}rem;
}

@mixin breakpoint($value, $direction: 'down') {
  $point: map-get($breakpoints, $value);

  @if not $point {
    @error "Unknown breakpoint '#{$value}'";
  } @else {
    // Otherwise, wrap the content in a media query
    @if $direction == 'up' {
      @media screen and (min-width: #{$point} + 1) {
        @content;
      }
    } @else {
      @media screen and (max-width: #{$point}) {
        @content;
      }
    }
  }
}

@function convert-number-to-word($num) {
  $count: 1;
  @each $word in one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty, twentyone, twentytwo, twentythree, twentyfour {
    @if ($count == $num) {
      @return $word;
    }
    $count: $count + 1;
  }
  @return 'invalid';
}

@mixin column($columns: $columns, $behavior: false) {
  @if $behavior == center {
    @extend %fl-n;
    @extend %c-base;

    margin-left: auto;
    margin-right: auto;
    width: grid-calc($columns, $total-columns);

    @include breakpoint(small) {
      float: none;
      width: auto;
    }
  } @else if $behavior == collapse {
    @extend %fl-l;
    @extend %c-base;

    padding: 0;
    width: grid-calc($columns, $total-columns);

    @include breakpoint(small) {
      float: none;
      width: auto;
    }
  } @else {
    @extend %fl-l;
    @extend %c-base;

    width: grid-calc($columns, $total-columns);

    @include breakpoint(small) {
      float: none;
      width: auto;
    }
  }
}

@mixin respond-to($media) {
  @if $media == smallScreen {
    @media only screen and (max-width: $screen-small - 1) {
      @content;
    }
  } @else if $media == mediumScreen {
    @media only screen and (max-width: $screen-medium) and (min-width: $screen-small) {
      @content;
    }
  } @else if $media == largeScreen {
    @media only screen and (min-width: $screenXlarge) {
      @content;
    }
  }
}

// @mixin clearfix() {
//   zoom: 1;
//
//   &:after,
//   &:before {
//     content: ' ';
//     display: table;
//   }
//
//   &:after {
//     clear: both;
//   }
// }

// Grid Calculation for Percentages
@function grid-calc($colNumber, $total-columns) {
  @return percentage($colNumber / $total-columns);
}

// Create CSS Triangles in a Breeze
@mixin cssTriangle($triangleSize, $triangleColor, $triangleDirection) {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: solid $triangleSize;
  @if ($triangleDirection == top) {
    border-color: $triangleColor transparent transparent transparent;
  }
  @if ($triangleDirection == bottom) {
    border-color: transparent transparent $triangleColor transparent;
  }
  @if ($triangleDirection == left) {
    border-color: transparent transparent transparent $triangleColor;
  }
  @if ($triangleDirection == right) {
    border-color: transparent $triangleColor transparent transparent;
  }
  // .example { @include cssTriangle(5px, $white, bottom); }
}

@mixin transition($properties...) {
  transition-property: $properties;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-easing;
}

@mixin extend-icon($icon-name) {
  @extend [class^="icon-"]:before !optional;
  @extend [class^='icon-']:before !optional;
  @extend .icon-#{$icon-name}:before !optional;
}

@mixin aspect-ratio($width, $height, $content-class: 'content') {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .#{$content-class} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
