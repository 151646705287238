//================================================
//=== BREADCRUMB =================================
//================================================

.banner-wrapper {
  .banner-image {
    @include aspect-ratio(1280, 800, 'banner-wrapper');
    @include transition(background);
    // background-position-y: center;
    background-position-y: 151px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    @media screen and (max-width: 1024px) {
      background-position: top;
    }
    @include breakpoint(large) {
      background-attachment: scroll;
    }
    .scrolled & {
      background-position-y: 81px;
    }
  }
  .banner-empty {
    height: 300px;
  }
  @include breakpoint(large) {
    .banner-image-wrapper {
      transform: translateY(0) !important;
    }
    .banner-empty {
      height: 0px;
    }
  }
}

.section-nieuws:not(.blog-detail),
.section-expert-aan-het-woord:not(.case-detail) {
  .banner-empty {
    height: 0px;
  }
}

.view-maps .field-content {
  @include aspect-ratio(1280, 800, 'geolocation-map');

  .geolocation-map {
    height: 100% !important;
  }
}
