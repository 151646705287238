.content {
  @extend %clearfix;
}

//========================================
// Overzichten
//========================================
.overzicht {
  &:hover {
    a {
      opacity: .8;
    }
  }

  a {
    display: block;
    margin-bottom: 30px;

    img {
      transition: all 0.4s ease;
    }

    .views-field-field-afbeelding .field-content,
    .views-field-field-afbeeldingen .field-content {
      overflow: hidden;
      display: block;
      line-height: 0;
    }

    .views-field-title .field-content {
      color: $black;
      font-size: 18px;
      padding: 10px 0;
      display: block;
    }

    &:hover {
      text-decoration: none;

      opacity: 1 !important;

      img {
        transform: scale(1.1);
      }
    }
  }
}

//========================================
// Infoblok
//======================================//
.infowrap {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  // margin-bottom: 20px;

  &:hover {
    // opacity: .8;
    // text-decoration: none !important;
  }

  .icoon {
    text-align: center;
    padding: 15px;

    .icon {
      &:before {
        display: inline-block;
        float: none;
        font-size: 34px;
        vertical-align: middle;

        .large & {
          margin: 0 0 10px;
          clear: both;
          line-height: initial;
          font-size: 86px;

        }
      }
    }

    p {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 19px;
      line-height: 20px;
      vertical-align: middle;

      .front & {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }

  &.border {
    border: 1px solid $text-color;
  }

  &.imageleft {
    .image {
      width: 30%;
      float: left;
      min-height: 150px;
      background-size: cover !important;
      background-position: center !important;
    }

    .infoblok {
      width: 70%;
      float: left;
    }
  }

  &.imagetop {
    .image {
      width: 100%;
      float: left;
      min-height: 170px;
      background-size: cover !important;
      background-position: center !important;
    }

    .infoblok {
      width: 100%;
      float: left;
    }
  }
}

div.infoblok {
  float: left;
  width: 100%;
  // padding: 30px;
  background-size: cover !important;
  background-position: center !important;

  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 25px;
  }

  p {
    padding-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }

  a.btn {
    margin-bottom: 0;
  }

  // Eeew
  &.kleur-2ba6cb,
  &.kleur-e3e3e3,
  &.kleur-f2f2f2 {
    a,
    blockquote,
    blockquote p:before,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    ul {
      color: $white;
    }

    .btn {
      background: $white;
      color: $red;
    }

    .btn.rand {
      background: transparent;
      border: 2px solid $white;
      color: $white;
    }
  }

  .color-e3e3e3 {
    background: #e3e3e3;
  }

  .color-f2f2f2 {
    background: #f2f2f2;
  }

  .color-2ba6cb {
    background: #2ba6cb;
  }
}

.front .kleur-9FDCE7 {
  text-align: center;

  p {
    font-size: 38px;
    line-height: initial;
  }
}

//========================================
// Iconen
//========================================
.icon {
  &:before {
    color: $white;
    float: left;
    margin: 0 15px 0 0;
    line-height: 20px;
  }
}

//========================================
// Isotope
//========================================
.isotope-container,
.isotope-container .isotope-element {
  transition-duration: .8s;
}

.isotope-container {
  transition-property: height, width;
}

.isotope-container .isotope-element {
  transition-property: transform, opacity;
}

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  transition-duration: 0s;
}

.isotope-options {
  margin: 0 !important;
}

.isotope {
  margin: 20px 0 0;
  display: inline-block;
  width: 100%;

  > .view-content {
    margin: 0 -15px;
  }
}

.isotope-container {
  margin: 0 !important;
  padding: 0 !important;

  .isotope-element {
    width: 25%;
    padding: 0 15px;

    .drie & {
      width: 33.333333333333333%;
    }
    @include breakpoint(medium) {
      float: left !important;
      width: 100% !important;
    }
  }

}

//========================================
// Taxonomy
//========================================
.taxonomy-widget {
  padding: 30px;
  background: $text-color;
  margin-bottom: 30px;

  h2 {
    margin-top: 0;
    color: $header-font-color;
    font-size: 20px;
  }

  ul li {
    list-style-type: square;

    a {
      color: $black;
      text-decoration: none;

      &.active {
        font-weight: 600;
      }

      &:hover {
        opacity: .5;
      }
    }
  }
}

//========================================
// Anchor: correctie fixed header
//========================================
a.anchor {
  padding-top: 180px;
  margin-top: -180px;
  float: left;
  width: 100%;
  display: block !important;
  visibility: hidden !important;
}

//========================================
// Website berichten
//========================================
.messages {
  float: left;
  width: 100%;
  clear: both;
}

//========================================
// Snelmailer
//========================================
.snelmailer-widget {
  float: left;
  width: 100%;
  margin: 15px 0;

  .btn {
    font-size: 12px;
  }

  .container-error {
    display: none !important;
  }
}

//========================================
// Exposed form
//========================================
.views-exposed-form {
  label {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }

  .form-radios {
    text-align: center;
  }

  .views-exposed-widget {
    width: 100%;

    input[type='radio'] {
      display: none;
    }

    input[type=radio]:checked + label {
      background: $button-color-active;
      color: $white;
      border: 1px solid $button-color-active;
    }

    .form-item {
      float: none;
      display: inline-block;
    }

    .option {
      float: left;
      padding: 8px 20px;
      min-width: 180px;
      background: $white;
      border-radius: 5px;
      text-align: center;
      margin: 10px 10px 0;
      color: $button-color;
      font-size: 15px;
      font-weight: 400;
      display: inline-block;
      border: 1px solid $button-color;

      &:hover {
        background: $button-color;
        color: $white;
      }
    }
  }

}

//========================================
// Paginering
//========================================
.pager {
  background: $text-color;
  margin: 50px 0 0 !important;
  padding: 20px 0;

  li {
    background: none !important;
    padding-left: 0 !important;
    margin-left: 7px !important;
  }

  .pager-current,
  .pager-item a {
    background: $white !important;
    padding: 0;
    text-align: center;
    border-radius: 2px;
    height: 18px;
    width: 18px;
    display: inline-block;
    line-height: 17px;
    color: $text-color;
    font-size: 13px;
  }

  .pager-item a {
    color: $white;
    background: $text-color !important;
  }

  .pager-first a,
  .pager-last a,
  .pager-next a,
  .pager-previous a {
    color: $white;
    font-weight: bold;
  }
}

//========================================
// Homeblokken
//========================================
.homeblok {
  background: $text-color;
  float: left;
  width: 100%;
  padding: 15px;
}

//========================================
// Diversen
//========================================
.ajax-progress {
  position: absolute;
  left: 50%;
  bottom: 0;
}

.ajax-progress .throbber {
  background: url('../images/ajax-loader.gif');
  position: absolute;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10;
}

// Blauwe rand om menu weghalen
:focus {
  outline: none;
}

.anchor {
  display: block !important;
  content: ' ' !important;
  margin-top: -200px !important;
  height: 200px !important;
  visibility: hidden !important;
}

a {
  transition: all .4s ease;
}

//========================================
// Media embed
//========================================
.media_embed {
  position: relative;
  // padding-bottom: 56.25%;
  padding-bottom: 9 / 16 * 100%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
}

.media_embed.widescreen {
  padding-bottom: 56.34%;
}

.media_embed.vimeo {
  padding-top: 0;
}

.media_embed embed,
.media_embed iframe,
.media_embed object,
.media_embed video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img {
  height: auto;
}

embed,
img,
object {
  max-width: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
.map_canvas img {
  max-width: none !important;
}

.home-views {
  @extend %clearfix;
  margin: (-$column-gutter / 2) (-$column-gutter / 2) 0;
  padding: 0;

  @include breakpoint(large) {
    //margin: 30px (-$column-gutter / 2);
    //padding: 0 ($column-gutter / 2);

    > .six {
      width: 100% !important;
    }
  }
  @include breakpoint(medium) {
    margin-top: rem(-5);
    padding: 0 ($column-gutter / 2) 0;
  }
}

//.pane-sharethis {
//  padding-top: 80px;
//  .pane-title {
//    font-family: $default-font;
//    font-size: 12px;
//    line-height: 25px;
//    color: $primary-color;
//    font-weight: $fw-normal;
//    margin-top: 0;
//  }
//  .sharethis-wrapper {
//    > span {
//      @include transition(transform);
//      display: block;
//      width: 30px;
//      padding-bottom: 10px;
//      cursor: pointer;
//
//      &:before {
//        font-size: 30px;
//        cursor: pointer;
//      }
//
//      //&:hover {
//      //  transform: translate(10px, 0);
//      //}
//
//      @include breakpoint(medium) {
//        display: inline-block;
//        &:hover {
//          transform: translate(0, 10px);
//        }
//      }
//
//      &.st_linkedin_custom {
//        &:before {
//          @include extend-icon('linkedin');
//          color: $linkedin-color;
//        }
//      }
//      &.st_facebook_custom {
//        &:before {
//          @include extend-icon('facebook');
//          color: $facebook-color;
//        }
//      }
//      &.st_twitter_custom {
//        &:before {
//          @include extend-icon('twitter');
//          color: $twitter-color;
//        }
//      }
//      &.st_email_custom {
//        &:before {
//          @include extend-icon('mail');
//          color: $email-color;
//        }
//      }
//    }
//  }
//  @include breakpoint(large) {
//    padding-top: 20px;
//  }
//}

.pane-sharethis {
  margin: 60px 0;
  text-align: right;

  @include breakpoint(medium, 'up') {
    .sidebar & {
      text-align: left;
    }
  }

  svg {
    display: block;

    path {
      transition: fill .15s ease;
    }
  }

  .pane-title,
  .sharethis-wrapper,
  .sharethis-wrapper span {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;

    @include breakpoint(medium, 'up') {
      .sidebar & {
        display: block;
      }
    }
  }

  .pane-title {
    text-transform: lowercase;
    font-size: rem(14);
    color: $background-dark;
    font-weight: $fw-normal;

    @include breakpoint(medium, 'up') {
      .sidebar & {
        margin-bottom: rem(16);
      }
    }
  }

  .sharethis-wrapper span {
    margin-left: rem(20);
    opacity: .5;
    transition: opacity .15s ease;
    cursor: pointer;

    @include breakpoint(medium) {
      margin-left: rem(16);
    }

    &:hover {
      opacity: 1;
    }

    @include breakpoint(medium, 'up') {
      .sidebar & {
        margin-left: 0;
        margin-bottom: rem(10);
      }
    }
  }
}

.geolocation-map {
  margin: 0;

  .gm-style {
    a {
      display: none !important;
    }
  }
  .gmnoprint {
    display: none;
  }
  @include breakpoint(large) {
    height: 500px !important;
  }
  @include breakpoint(medium) {
    height: 300px !important;
  }
}

.svg-wrapper {
  height: 60px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 320px;
}

.shape {
  fill: transparent;
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  stroke: #19f6e8;
}

.text {
  color: #fff;
  font-family: 'Roboto Condensed';
  font-size: 22px;
  letter-spacing: 8px;
  line-height: 32px;
  position: relative;
  top: -48px;
}

@keyframes draw {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 8px;
  }
  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
    stroke-width: 2px;
  }
}

.svg-wrapper:hover .shape {
  -webkit-animation: 0.5s draw linear forwards;
  animation: 0.5s draw linear forwards;
}

@each $social-name, $social-color in $socials {
  .#{$social-name},
  .#{$social-name}-alt {
    transition: color .15s ease;

    svg path {
      transition: fill .15s ease;
    }
  }
  .#{$social-name} {
    color: $social-color;

    svg path {
      fill: $social-color;
    }

    &:hover {
      color: $primary-color;

      svg path {
        fill: $primary-color;
      }
    }
  }

  .#{$social-name}-alt {
    color: $primary-color;

    svg path {
      fill: $primary-color;
    }

    &:hover {
      color: $social-color;

      svg path {
        fill: $social-color;
      }
    }
  }

  .sharethis-wrapper .st_#{$social-name}_custom {
    color: $primary-color;

    svg path {
      fill: $primary-color;
    }

    &:hover {
      color: $social-color;

      svg path {
        fill: $social-color;
      }
    }
  }
}
