.sidemenu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);

  .no-transform & {
    left: rem(-$menu-width);
  }

  @include transition(opacity, visibility, transform);

  .main-menu,
  .site-menu,
  .language-menu {
    padding-bottom: rem(10);
    margin-bottom: rem(20);
  }
  .language-menu {
    min-height: 43px;
  }

  .site-menu {
    margin-bottom: rem(28);
  }
}

svg.colored-icon path {
  fill: $primary-color;
}

.sidemenu,
.sidemenu--float {
  background: $white;
  z-index: 110;

  svg,
  .icon-text {
    display: inline-block;
    vertical-align: middle;
  }
}

.sidemenu--float {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: rem(61);
  padding: rem(26) rem(30);
  opacity: 1;
  visibility: visible;
  transform: translateX(0);

  @include transition(opacity, visibility, transform);

  @include breakpoint(xlarge) {
    position: absolute;
    top: 153px;
    margin: 0;
    padding: 0 0 0 rem(20);

    .open-menu-text {
      display: none;
    }

    .open-menu-trigger,
    .open-search-trigger,
    .searchbox-wrapper {
      display: inline-block;
      margin: 0;
    }
  }

  @include breakpoint(large) {
    padding: 0 0 0 rem(36);
  }

  @include breakpoint(medium) {
    top: 85px;
    padding: 0 0 0 rem(25);
  }
}

.sidemenu--menu {
  width: rem($menu-width);
  max-width: 100vw;
  padding: rem(26) rem(30);
}

.open-menu-trigger,
.close-menu-trigger,
.open-search-trigger {
  color: $primary-color;
  cursor: pointer;
  padding-left: rem(30);

  svg {
    display: inline-block;
    margin-left: rem(-30);
    width: rem(30);

    @include breakpoint(medium) {
      width: rem(16);
    }
  }
}

.open-search-trigger {
  margin-top: rem(26);
}

.close-menu-trigger {
  margin-bottom: rem(11);
}

.searchbox-wrapper {
  width: 0;
  opacity: 0;
  visibility: hidden;
  position: relative;
  margin: rem(26) 0;

  @include transition(opacity, width, visibility);

  .form-item {
    margin: 0;
    padding-right: rem(45);
  }

  form {
    margin: 0;
  }

  [type='text'] {
    height: rem(35);
    margin: 0;
  }
  [type='submit'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: rem(35);
    height: rem(35);

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url('/sites/all/themes/front/images/svg/arrow.svg') center no-repeat;
    }
  }
}

#page {
  @include transition(transform);
}

.search-open {
  @include breakpoint(medium, 'up') {
    #page {
      transform: translateX(rem(200));

      .no-transform & {
        margin-right: rem(-200);
      }
    }
  }

  .searchbox-wrapper {
    width: rem(200);
    opacity: 1;
    visibility: visible;
  }
}

.menu-open {
  #page {
    transform: translateX(rem($menu-width));

    .no-transform & {
      margin-right: rem(-$menu-width);
    }
  }

  .sidemenu--float {
    opacity: 0;
    visibility: hidden;

    @include breakpoint(xlarge) {
      transform: translateX(rem($menu-width));
      opacity: 1;
      visibility: visible;

      .no-transform & {
        margin-right: rem(-$menu-width);
      }
    }
  }

  .sidemenu {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);

    .no-transform & {
      left: 0;
    }
  }
}

.site-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  //padding: rem(30) 0;

  .site-wrapper {
    flex: 1 0 25%;
    width: 25%;
    transition: transform .15s ease;

    &:hover {
      transform: translateY(rem(-10));
    }
  }

  .site-wrapper.vr-main {
    flex: 0 0 100%;
    margin-bottom: 6px;
    width: 100%;

    .site {
      width: 50%;
      margin: 0 auto;
    }
  }

  .site-wrapper.active {
    transform: translateY(rem(-20)) !important;

    .site::after {
      content: '';
      display: block;
      margin: 0 auto;
      height: 2px;
      width: 100 / 3 * 2%;
      background-color: $primary-color;
      transform: translateY(rem(10));
    }
  }

  .site {
    &,
    svg,
    img {
      display: block;
    }
  }
}

.language-switcher {
  .language {
    display: inline-block;
    margin-right: rem(22);
  }

  .switch-language {
    color: $text-color;
    display: block;
    font-size: rem(20);
    line-height: 1.55;
    text-decoration: none;

    &:hover {
      //text-decoration: underline;
      color: $link-color-hover;
    }

    &.active {
      color: $primary-color;
    }
  }
}

.main-menu .expanded {
  svg {
    display: inline-block;
    margin-left: rem(-30);
    width: rem(30);

    transition: transform .15s ease;
  }

  &.submenu-open svg {
    transform: rotate(90deg);
  }

  .menu {
    display: none;
  }
}

.main-menu,
.social-menu {
  .menu {
    padding-left: rem(30);
  }

  .menu__item {
    list-style: none;
  }

  .menu__link {
    display: inline-block;
    font-size: rem(20);
    line-height: 1.55;
    text-decoration: none;
    margin-bottom: rem(15);
    color: $text-color;

    &:hover {
      color: $link-color-hover;
    }
  }
}

.social-menu {
  margin: rem(20) 0 rem(10) rem(30);

  .social-menu__item {
    display: inline-block;
  }

  .social-menu__link {
    width: rem(30);
    height: rem(30);
    display: inline-block;
    margin-right: 10px;
    background-color: $primary-color;
    font-size: 0;

    &:hover {
      text-decoration: none;
      background-color: $link-color-hover;
    }

    &[data-icon='facebook'] {
      mask: url('/sites/all/themes/front/images/svg/social-facebook.svg');
      mask-repeat: no-repeat;
      mask-size: contain;
    }

    &[data-icon='linkedin'] {
      mask: url('/sites/all/themes/front/images/svg/social-linkedin.svg');
      mask-repeat: no-repeat;
      mask-size: contain;
    }
  }
}
