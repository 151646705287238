//================================================
////=== FOOTER =====================================
//================================================

footer {
  margin: 0 ($column-gutter / -2);

  @include breakpoint(medium) {
    margin: 0;
  }
}

.footerwrap {
  overflow: hidden;

  .footerblock {
    padding: 40px 20px 65px;
    padding-top: $column-gutter / 2;
    position: relative;
    overflow: hidden;

    .image {
      @include transition(transform);
      transition-duration: 5s;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat !important;
      background-size: cover !important;
    }

    p {
      color: $white;
      font-size: rem(22);
      line-height: $header-line-height;
      font-weight: $fw-semi-bold;
      font-family: $alternative-font;
      z-index: 2;
      position: relative;

      @include breakpoint(medium) {
        font-size: rem(14);
      }
    }
    .overlay {
      @include transition(opacity);
      opacity: 0;
      background: rgba(70, 146, 220, .8);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
    }
    .more-link {
      @include transition(opacity, right);
      position: absolute;
      bottom: 25px;
      right: 90%;
      white-space: nowrap;
      z-index: 2;
      color: $white;
      font-family: $alternative-font;
      font-size: 16px;
      line-height: 19px;
      font-weight: $fw-bold;

      .icon-text {
        @include transition(opacity);
        opacity: 0;
      }

      svg {
        @include transition(transform);
        transform: translateX(rem(16));
      }

      svg,
      .icon-text {
        display: inline-block;
        vertical-align: middle;
      }

      @include breakpoint(medium) {
        right: 84%;
      }
    }

    &:hover {
      .image {
        transform: scale(1.1);
      }
      .overlay {
        opacity: 1;
      }
      .more-link {
        .icon-text {
          opacity: 1;
        }
        svg {
          transform: translateX(0);
        }
        right: 5%;
      }

    }
  }
  &:hover {
    text-decoration: none;
  }
}

//Footer bottom
.footer-bottom {
  position: relative;
  margin-top: rem(25);
  background: $white;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 142px;
  padding: 83px 0 30px 0;
  text-align: center;
  z-index: 101;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    border-top: 1px solid #f0f0f0;
  }

  .block {
    display: inline-block;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style-position: inside;

    li {
      list-style-type: none;
      margin-left: 15px;
      padding-left: 15px;
      display: block;
      float: left;
      font-size: 14px;
      color: $text-color;

      &:first-child {
        border: 0;
        margin: 0;
        padding: 0;
      }

      strong {
        font-weight: $body-font-weight;
      }

      a,
      a.active {
        color: $text-color;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .region-bottom-logo ul li {
    margin-left: 15px;
    padding-left: 15px;
  }

  @media screen and (max-width: 1200px) {
    padding: 5px ($column-gutter / 2) 10px;
  }
}

@include breakpoint(medium) {
  .footer-bottom {
    text-align: left;
    padding: rem(30) rem(25) rem(40);

    .columns {
      padding: 0;
      float: none !important;
    }

    .columns.left {
      float: left;
    }

    .columns.right {
      float: right;
    }

    .region-bottom-logo ul li,
    ul li {
      float: none;
      display: inline-block;
      padding: 0;
      border: 0;

      a {
        line-height: 24px;
      }
    }

    .region-bottom-logo {
      margin-top: rem(20);
    }
  }
}

/*Standaard styling Drupal overwrite*/
.footer-bottom .menu__item.is-leaf,
.footer-bottom ul li a {
  list-style-image: none;
  list-style-type: none;
}

.footerblocks {
  @extend %clearfix;
  margin: rem(90) 0;

  @include breakpoint(large) {
    margin: 0 19px 0 20px;

    .three.columns {
      width: 50% !important;
      padding: 0 1px 1px 0;
    }
  }
  @include breakpoint(medium) {
    margin: 0 -1px 0 0;
  }
}

.bean-footer-payoff {
  margin: rem(25) 0;
  background: $background-light;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include breakpoint(medium) {
    display: block;
  }

  .no-flexbox & {
    @extend %clearfix;
  }

  .columns {
    padding: 0;
  }

  .footer-payoff-image-wrapper {
    @include aspect-ratio(360, 200, 'footer-payoff-image');
    flex: 0 0 25%;
    width: 25%;

    @include breakpoint(medium) {
      flex-basis: 100%;
      width: 100%;
    }

    .no-flexbox & {
      float: left;
    }

    .footer-payoff-image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .field-name-field-inhoud {
    font-size: rem(18);
    padding: rem(46) rem(50) rem(36);
    vertical-align: middle;
    flex: 1 1 (100 / 3 * 2%);
    width: 100 / 3 * 2%;

    @include breakpoint(medium) {
      flex-basis: 100%;
      width: 100%;
      padding: rem(30) rem(25) rem(25);
      font-size: rem(16);
    }

    .no-flexbox & {
      float: right;
    }

    h2, h3, h4, h5, h6 {
      margin-top: 0;
    }
  }
  &.color-inverted{
    .field-name-field-inhoud * {
      color: $white!important;
    }
    h2, h3, h4, h5, h6 {
      color: $white;
    }
  }
}

.footer-bottom .social-menu {
  margin: 0;

  .social-menu__item {
    margin: 0;
    padding: 0;
    font-size: 0;
  }

  .social-menu__link {
    margin: 0 0 0 15px;
  }
}

.footer-bottom {
  .columns,
  .region-bottom-menu,
  .region-bottom-logo {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    min-height: rem(30);
  }
}

@include breakpoint(large) {
  .footer-bottom {
    .columns,
    .region-bottom-menu,
    .region-bottom-logo {
      flex-direction: column;
    }
  }

  .footer-bottom .social-menu {
    margin: 0 0 rem(30);

    .social-menu__link {
      margin: 0 5px 0 5px;
    }
  }
}
