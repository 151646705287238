.person {
  text-align: center;
  margin-bottom: rem(40);

  &.three.columns:nth-child(4n+1) {
    clear: left;
  }
}

.person--photo {
  display: block;
  margin-bottom: rem(24);

  img {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
  }
}

.person--first-name,
.person--last-name {
  font-weight: $fw-bold;
  color: $primary-color;
  font-size: rem(16);
}

.person--function {
  font-size: rem(18);
  margin-bottom: rem(6);
}
