
$flex-video-size:                 300px;
$flex-video-bg-color:             rgba(255, 255, 255, .5);
$flex-video-button-size:          $flex-video-size / 4;
$flex-video-size-small:           150px;
$flex-video-button-size-small:    $flex-video-size-small / 4;


.flexslider {
  margin: 0px;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  overflow: hidden;
  width: 100%;

  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    padding-left: 0;
    -webkit-backface-visibility: visible !important;
    position: relative;
  }

  .slides .slide {
    @include aspect-ratio(1160, 540, 'slide-inner');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include breakpoint(medium) {
      @include aspect-ratio(375, 250, 'slide-inner');
      .slide-inner {
        position: static;
      }
    }
  }

  // Caption
  .flex-caption {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: rem(30) rem(45) rem(34);
    background: $slider-caption-bg-color;
    text-align: left;

    .caption-text,
    .caption-text p {
      line-height: $header-line-height;
      color: $caption-text-color;
      margin: 0;
      font-weight: $fw-light;
      text-shadow: $caption-text-shadow;
      font-size: #{map_get($header-size, 'h1')}px;

      @include breakpoint(medium) {
        font-size: #{map_get($small-header-size, 'h1')}px;
      }
    }

    @include breakpoint(medium) {
      position: static;
      padding: rem(20) rem(25) rem(26);
      background: $primary-color;

      .caption-text {
        font-size: rem(#{map_get($small-header-size, 'h1')});
      }
    }
  }

  //Bolletjes navigatie
  .flex-control-nav {
    display: none;
    z-index: 99;
    bottom: 0;

    li {
      margin: 0 8px 0 0;
      display: block;
      float: left;

      a {
        width: 9px;
        height: 9px;
        background: #ccc;

        &.flex-active {
          background: #999;
        }
      }
    }

  }

  // Pijltjes navigatie
  .flex-direction-nav {
    a {
      color: $white;
      top: 0;
      height: 100%;
      margin: 0;
      width: 60px;

      &:before {
        top: 50%;
        position: absolute;
        margin-top: -32px;
      }
    }

    .flex-next {
      right: 0;

      &:before {
        right: 0;
      }
    }

    .flex-prev {
      left: 0;

      &:before {
        left: 0;
      }
    }
  }

  .bg-caption,
  .color-caption {
    display: none;
  }
}

.flexslider__video {
  width: $flex-video-size;
  height: $flex-video-size;
  display: block;
  position: absolute;
  left: calc(50% - (#{$flex-video-size} / 2));
  top: calc(50% - (#{$flex-video-size} / 2));
  background-color: $flex-video-bg-color;
  border-radius: $flex-video-size;
}


.flexslider__video-button {
  width:  $flex-video-button-size;
  height: $flex-video-button-size;
  border-top-right-radius: 30%;
  position: absolute;
  left: calc(50% - (#{$flex-video-button-size} / 4));
  top: calc(50% - (#{$flex-video-button-size} / 2));
  background-color: $primary-color;
  transform: rotate(30deg) skewX(-30deg) scale(1,.866);

  &::after,
  &::before {
    content: '';
    width: $flex-video-button-size;
    height: $flex-video-button-size;
    border-top-right-radius: 30%;
    position: absolute;
    background-color: inherit;
  }

  &::before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
  }

  &::after {
    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
  }
}

@include breakpoint(medium) {
  .flexslider__video {
    width: $flex-video-size-small;
    height: $flex-video-size-small;
    left: calc(50% - (#{$flex-video-size-small} / 2));
    top: 25%;
  }

  .flexslider__video-button {
    width: $flex-video-button-size-small;
    height: $flex-video-button-size-small;
    left: calc(50% - (#{$flex-video-button-size-small} / 4));
    top: calc(50% - (#{$flex-video-button-size-small} / 2));

    &::after,
    &::before {
      width: $flex-video-button-size-small;
      height: $flex-video-button-size-small;
    }
  }
}
