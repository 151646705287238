@import 'settings';
@import 'utils/mixins';
@import 'utils/placeholders';
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700');

html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    overflow-x: hidden;
  }
}

body {
  font-size: 100%;
  margin: 0;
  background: $white;
  overflow-x: hidden;

  padding: 0 $body-padding;
  //@media screen and (min-width: 1025px) {
  //  padding: 0 150px;
  //}
  //@media screen and (min-width: 1400px) {
  //  padding: 0 150px;
  //}
  //@media screen and (max-width: 1025px) {
  //  padding: 0 30px;
  //}
  @include breakpoint(xlarge) {
    padding: 0 rem(20);
  }

  @include breakpoint(medium) {
    padding: 0;
  }
}

//#page {
//  border: 1px solid red;
//}


body,
caption,
div,
p,
td,
th {
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $body-font-weight;
  -webkit-font-smoothing: antialiased;
}

strong {
  font-weight: $fw-bold;
}

hr {
  border: 0;
  border-bottom: 2px solid $border-color;
  margin: 30px 0;
}

p {
  padding: 0 0 15px;
  margin: 0;

  img[style*='left'] {
    margin: 0 20px 20px 0;
  }

  img[style*='right'] {
    margin: 0 0 20px 20px;
  }

  .rtecenter {
    text-align: center;
  }
  @include breakpoint(medium) {
    font-size: 14px;
    line-height: 20px;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

// Intro paragraaf
p.intro {
  font-weight: $text-intro-weight !important;
  font-size: $text-intro-size !important;
  line-height: $text-intro-line-height !important;
  color: $text-intro-color !important;

  @include breakpoint(medium) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

p.intro-big {
  font-weight: $fw-light !important;
  font-size: rem(50) !important;
  line-height: 1.1 !important;
  color: $text-intro-color !important;

  @include breakpoint(medium) {
    font-size: rem(30) !important;
  }
}

em {
  font-style: italic;
}

a {
  transition: color .1s ease;
  color: $link-color;
  text-decoration: $link-text-decoration;

  &:hover {
    color: $link-color-hover;
  }
}

// CONTENT STIJLEN
main.content {
  padding: 0;
}

.content {
  img {
    height: auto !important;
  }

  ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container) {
    @extend %ul-caret-list;

    margin-bottom: 1em;

    p {
      display: inline;
    }
  }

  .item-list > ul li {
    list-style: none;
    margin-bottom: $column-gutter / 2;
    padding: 0 $column-gutter / 4;
  }

  ol:not(.flex-control-nav) {
    list-style-position: outside;
    margin: 0 0 10px 18px;
    list-style: decimal;

    p {
      display: inline;
    }
  }

  table {
    width: 100% !important;

    th {
      text-align: left;
      padding: 0 0 5px 10px;
      font-weight: bold;
    }

    tbody tr td {
      padding: 5px 7px;
      border: 1px solid $text-color;
    }
  }
}

// Headers
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  margin: 15px 0;
  line-height: 1.4;

  &,
  & a {
    color: $header-font-color;
  }
}
//h1 {
//  line-height: 45px;
//  @include breakpoint(medium) {
//    line-height: 34px;
//  }
//}
//h2 {
//  line-height: 36px;
//}
//h3 {
//  line-height: 30px;
//}

//h5, h5 a {
//  color: $primary-color;
//  @include breakpoint(medium) {
//    color: $header-font-color;
//  }
//}
//h6, h6 a {
//  color: $primary-color;
//}

// Set headers size (h1-h6)
// See _settings.scss for sizes
@for $i from 1 through length($header-size) {
  $_el: h#{$i};
  $_size: map-get($header-size, $_el);
  $_size-small: map-get($small-header-size, $_el);

  #{$_el} {
    font-size: #{$_size}px;
    @include breakpoint(medium) {
      font-size: #{$_size-small}px;
      word-break: break-word;
    }
  }
}

// Blockquote
blockquote {
  position: relative;
  padding: 0 55px;
  width: 100%;
  max-width: 550px;
  // float: left;
  border-color: $primary-color;
  margin: 40px 0;
  display: block;

  p {
    font-weight: $fw-bold;
    font-family: $alternative-font;
    font-size: 18px;
    line-height: 24px;
    font-style: normal;
    margin: 0;
    padding: 0;
    color: $orange;

    &:after,
    &:before {
      font-size: 70px;
      line-height: 70px;
      width: 60px;
      font-weight: $fw-bold;
      position: absolute;
      font-family: $alternative-font;
      font-style: italic;
    }

    &:before {
      content: ' ';
      border-left: 2px solid $primary-color;
      float: left;
      left: 20px;
      top: 0;
      height: 100%;
    }

    &:after {
      // content: '\201D';
      float: right;
      right: 0;
      bottom: -32px;
    }

  }

  @include breakpoint(medium) {
    p {
      font-size: 16px;
    }
  }
}

// ul.paddingFooter {
//   margin: 43px 0 15px;
//   @include breakpoint(medium) {
//     margin-top: 0;
//   }
// }

// Buttonsront
#user-login .form-submit,
.btn,
.form-submit {
  @include transition(background);
  background: $button-color;
  font-size: 20px;
  clear: both;
  font-family: $alternative-font;
  font-weight: $fw-bold;
  line-height: 24px;
  color: $white;
  text-decoration: none;
  padding: 8px 20px;
  margin: 10px 0;
  display: inline-block;
  border-radius: 20px;
  border: 0;
  text-align: center;
  cursor: pointer;

  .rteright & {
    float: right;
  }

  .rteleft & {
    float: left;
  }

  .rtecenter & {
    float: none;
    display: inline-block;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    background: $button-color-hover;
  }

  .bg_color_dark &,
  .bg_color_light & {
    background: $button-alternative-color;
    color: $primary-color;

    &:hover {
      color: $primary-color;
      background: $button-alternative-color-hover;
    }
  }

  .bg_color_dark &:hover {
    background: $background-light;
  }

  .bg_color_light &:hover {
    background: $background-dark;
  }
}

.form-submit {
  padding: 8px 16px;
}

.fotoalbum-overview .thumb.col a,
.fotoalbum-sectie .thumb.col a,
a.popup {
  position: relative;
  display: block;
  line-height: 0;

  &:hover {
    opacity: .8;
  }

  img {
    position: relative;
    z-index: 0;
  }

  span.icon {
    background: url('../images/enlarge_icon_crop.png') no-repeat;
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    z-index: 1;
    height: 12px;
    width: 13px;
  }

}

.green {
  color: $primary-color;
}
.orange {
  color: $orange;
  .intro & {
    color: $text-color;
  }
}

p.popup {
  padding: 0;
  line-height: 0;
}

// Diversen
.cke_editable,
.cke_editable_themed {
  padding: 10px !important;
}

.bg-dark {
  p,
  p.intro,
  h5,
  h6,
  li,
  blockquote {
    color: $white;
  }
  h1,
  h2,
  h3,
  h4 {
    color: $background-light;
  }
}
