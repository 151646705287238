.pane-node-field-afbeelding {
  //margin-bottom: 65px;

  img {
    display: block;
    width: 100%;
  }
}

.paragraphs-items {
  body:not(.front) & {
    background: $white;
    margin: 0 auto;
    width: $row-width;
    max-width: calc(100% - 0px);
    /*@media screen and (min-width: 1024px) {
      margin-top: -200px;
      margin-bottom: -100px;
      animation: slideUpContent 1s forwards;
    }
    @include breakpoint(large) {
      max-width: calc(100% - 20px);
    }
    @include breakpoint(medium) {
      max-width: calc(100%);
    }*/
  }

  .entity-paragraphs-item {
    position: relative;

    h1, h2, h3, h4, h5, h6 {
      &:first-child {
        margin-top: 0;
      }
    }

    .content.bg_color_light,
    .content.bg_color_dark {
      .paragraph-title .columns {
        padding: rem(50) rem(40) 0 !important;

        //h1, h2, h3 {
        //  margin-bottom: 0;
        //}
      }

      .paragraphs-item-paragraphs-text {
        padding: rem(50) rem(40) rem(40) !important;

        @include breakpoint(medium) {
          padding: rem(30) rem(25) rem(25) !important;
        }
      }

      .row.paragraph-title + .row .paragraphs-item-paragraphs-text {
        padding-top: 0 !important;
      }
    }

    .columns {
      padding: 0;

      @include breakpoint(medium) {
        padding: 0 ($column-gutter / 2);
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      margin: 0 auto;

      @media screen and (min-width: 1480px) {
        padding: 0 20px;
        .producten-detail & {
          padding: 24px 20px 20px 20px;
        }

        .row {
          padding: 0;
        }
      }

      @include breakpoint(medium) {
        display: block;
      }
    }

    .bg-col,
    .bg-img {
      position: absolute;
      width: calc(100vw - 120px);
      @media screen and (max-width: 1025px) {
        width: calc(100vw - 60px);
      }
      @include breakpoint(medium) {
        width: 100vw;
      }
      height: 100%;
    }
    .bg-col {
      left: 50%;
      transform: translateX(-50%);
    }
    .bg-img {
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      // background-size: cover;
      @include breakpoint(medium) {
        display: none;
      }
    }

    .content {
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .paragraph-title {
        h1 {
          margin-bottom: 40px;
        }
      }

      &.bg-dark,
      &.bg_color_dark {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        p.intro,
        a:not(.btn),
        div {
          color: $white !important;
        }

        .row .columns {
          background: $background-dark;
        }
      }

      &.bg-light,
      &.bg_color_light {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $primary-color;
        }

        @include breakpoint(large) {
          overflow: hidden;
        }

        .row .columns {
          background: $background-light;
        }
      }

      &.background-image {
        //padding: 160px 0;
        background-attachment: fixed;
        .row {
          background: $white;
          &.paragraph-title {
            padding-bottom: 0;

            + .row {
              padding-top: 0;
            }
          }
          .row {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
        @media screen and (max-width: 1200px) {
          padding: 160px ($column-gutter / 2);
        }
        @include breakpoint(large) {
          background-attachment: scroll;
          padding: 80px ($column-gutter / 2);
          .row {
            padding-top: 50px;
            padding-bottom: 50px;
            .columns {
              padding-top: 0;
              padding-bottom: 0;
            }
          }
        }
        @include breakpoint(medium) {
          background-image: none !important;
          padding: 0 !important;
          .row {
            padding-top: 20px;
            padding-bottom: 20px;
            .columns {
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
          }
        }
      }
    }

    //&.margebottom .content {
    //  padding-top: 0 !important;
    //}
    //&.margetop .content {
    //  padding-bottom: 0 !important;
    //}
    //&.geenmarge .content {
    //  padding-top: 0 !important;
    //  padding-bottom: 0 !important;
    //}

    &.margebottom {
      margin-top: 0 !important;
    }
    &.margetop {
      margin-bottom: 0 !important;
    }
    &.geenmarge {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .field-type-image img {
      display: block;
      margin: 0 auto;
    }

    //.paragraphs-item-usp-blokken {
    //  padding: 0 $column-gutter / 2;
    //}
  }

  //@include breakpoint(large) {
  //  body:not(.front) & {
  //    animation: none;
  //    margin-top: 0px;
  //  }
  //
  //  .field-item > .entity-paragraphs-item > .content {
  //    padding: 50px 20px;
  //
  //    &.bg_color_light,
  //    &.bg_color_dark {
  //      padding-left: 0;
  //      padding-right: 0;
  //    }
  //
  //    .row {
  //      .columns {
  //        &.twelve {
  //          padding-left: ($column-gutter / 2);
  //          padding-right: ($column-gutter / 2);
  //        }
  //        &.six, &.four, &.three {
  //          &:first-child {
  //            padding-left: ($column-gutter / 2);
  //          }
  //          &:last-child {
  //            padding-right: ($column-gutter / 2);
  //          }
  //        }
  //      }
  //    }
  //  }
  //}

  @include breakpoint(medium) {
    .field-item > .entity-paragraphs-item > .content {
      //padding: 0 rem(20);
      padding: 0;

      &.bg_color_light,
      &.bg_color_dark {
        padding-left: 0;
        padding-right: 0;
      }

      .row {
        .columns {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

// Afbeeldingen
.paragraphs-item-afbeeldingen {
  .field-item {
    width: 50%;
    float: left;
    padding: 0 20px 20px;

    img {
      float: left;
      width: 100%;
    }
  }
}

.paragraphs-item-paragraphs-hr {
  hr {
    margin: 30px 50px;
    @include breakpoint(large) {
      margin: 30px 20px;
    }
  }
}

.pane-shortview,
.paragraphs-item-paragraphs-kolomindeling {
  margin: rem(90) 0;

  @include breakpoint(medium) {
    margin: rem(30) 0;

    .paragraph-title,
    .paragraphs-item-paragraphs-form,
    .paragraphs-item-paragraphs-text {
      padding: 0 rem(20);
    }

    .paragraphs-item-paragraphs-form {
      margin-bottom: rem(40);
    }
  }
}

.paragraphs-item-paragraphs-img {
  height: 100%;

  @include breakpoint(medium) {
    margin: 0;
  }
}

.paragraph-bg-image {
  position: relative;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include aspect-ratio(560, 400);

  .image-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: rem(30) rem(45) rem(34);
    background: $caption-bg-color;
    text-align: center;

    h2 {
      color: $caption-text-color;
      margin: 0;
      .section-ons-assortiment & {
        font-size: #{map_get($header-size, 'h4')}px;
      }
    }
  }

  @include breakpoint(medium) {
    @include aspect-ratio(375, 250);

    .image-caption {
      padding: rem(26) rem(25) rem(18);
      text-align: left;
    }
  }
}

.three .paragraph-bg-image {
  .image-caption {
    padding: rem(16) rem(32);
    h2 {
      font-size: rem($base-font-size);
    }
  }
}

.field-item > .paragraphs-item-paragraphs-text {
  margin: rem(100) 0;

  .field-type-text-long {
    padding: 0 rem(100);
  }

  @include breakpoint(medium) {
    margin: rem(30) 0;

    .field-type-text-long {
      padding: 0 rem(20);
    }
  }
}

.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content > .row,
.pane-sharethis,
.node-type-vacatures .pane-vacatures .row,
.node-type-vacatures .pane-node-body,
.node-type-producten .pane-node-body {
  padding: 0 rem(120);
  @include breakpoint(medium) {
    padding: 0 rem(0);
  }
}
.pane-node-body,
.search-row {
  body:not(.front) & {
    background: $white;
    margin: 0 auto;
    width: $row-width;
    max-width: calc(100% - 0px);
  }
}
.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content > .row.paragraph-title {
  @include breakpoint(medium) {
    padding: 0 rem(20);
  }
}

.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content.bg_color_dark > .row,
.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content.bg_color_light > .row,
.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content.bg_volle_breedte > .row,
.node-type-vacatures .pane-vacatures .row .columns {
  padding: 0;
}
.paragraphs-items .entity-paragraphs-item.paragraphs-item-paragraphs-kolomindeling > .content.bg_color_dark > .row.paragraph-title h1 {
  @include breakpoint(medium) {
    padding: 0 rem(20);
  }
}

.paragraphs-item-productinformatie .field {
  float: left;
  padding: 0 $column-gutter / 2;
  width: 50%;
  blockquote {
    position: relative;
    padding: 0 55px;
    width: 100%;
    max-width: 550px;
    border-color: $primary-color;
    margin: 10px 0 40px 0;
    display: block;
    border-left: 0;
    p {
      color: $primary-color;
    }
  }
}


@include breakpoint(medium, 'up') {
  .entity-paragraphs-item .row > .columns:not(:first-child) > .entity-paragraphs-item:not(.paragraphs-item-paragraphs-img) {
    padding-left: $column-gutter / 2;
  }

  .entity-paragraphs-item .row > .columns:not(:last-child) > .entity-paragraphs-item:not(.paragraphs-item-paragraphs-img) {
    padding-right: $column-gutter / 2;
  }
}
